import { Container, Hidden } from "@mui/material";
import React, { useState, useEffect, Component } from "react";
import Sidebar from "./SideBar";
import {
  Button,
  Navbar,
  Nav,
  NavDropdown,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  HddStack,
  List,
  Grid3x3Gap,
  HddStackFill,
} from "react-bootstrap-icons";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Menu from "./Menu.js";
import logo from "./main_icon.png";
//import AADService from "./funcionesAuth.js";
import { adalApiFetch } from "./adalConfig.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correo: "",
      login: false,
      data: [],
    };
    this.onKeyUp = this.onKeyUp.bind(this);
    //this.AzureADService = new AADService();
    //this.token = this.AzureADService.getToken();
  }
  handleClick() {
    this.setState({ login: true });
  }
  onKeyUp(event) {
    if (event.charCode === 13) {
      this.setState({ login: true });
    }
  }
  /*
  salir = () => {
    this.AzureADService.logout();
  };
*/
  componentDidMount() {
    adalApiFetch(fetch, process.env.REACT_APP_API_URL + "usuarios/", {
      method: "GET",
      headers: {
        Accept: "application/json" /*Authorization: 'Bearer '+ this.token*/,
      },
    })
      .then((response) => {
        response.json().then((responseJson) => {
          this.setState({ apiResponse: JSON.stringify(responseJson, null, 2) });
        });
      })
      .catch((error) => {});
  }

  render() {
    // console.log(this.state.login);
    if (this.state.login === false) {
      return (
        <div>
          <Router>
            <Row>
              <Col xs={12}>
                <Navbar variant="dark">
                  <Button variant="#787878" size="lg"></Button>
                  <Navbar.Brand></Navbar.Brand>
                  <Navbar.Brand>
                    GRC Riesgos tecnológicos y cibernéticos
                  </Navbar.Brand>
                  <Nav className="mr-auto">
                    <Nav.Link href="#home"></Nav.Link>
                  </Nav>
                </Navbar>
              </Col>
            </Row>
            <Row id="container_login">
              <Col sm={6} xs={12}>
                <Form>
                  <Form.Control
                    onKeyPress={this.onKeyUp}
                    type="text"
                    placeholder="Ingrese el correo"
                    onChange={(e) => this.setState({ correo: e.target.value })}
                  />
                </Form>
              </Col>
              <Col sm={2} xs={6}>
                <Link to="menu">
                  <Button variant="warning" onClick={() => this.handleClick()}>
                    {" "}
                    Entrar{" "}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Router>
        </div>
      );
    } else {
      return <Menu login={this.state.correo}></Menu>;
    }
  }
}

export default App;
