import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Dropdown, DropdownButton, Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";

//Librerias de la tabla
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AADService from "../funcionesAuth.js";
//Finaliza las librerias de la tabla

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger">La fecha Final no puede ser menor a la fecha inicial</Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

//Riesgos y Evaluaciones header

const headCellsRiesgos = [
  {
    id: "idEvaluacion",
    numeric: false,
    disablePadding: false,
    label: "Id Evaluación",
  },
  { id: "idActivo", numeric: false, disablePadding: true, label: "Id Activo" },
  {
    id: "activoevaluado",
    numeric: false,
    disablePadding: false,
    label: "Activo Evaluado",
  },
  { id: "idRiesgo", numeric: false, disablePadding: true, label: "Id Riesgo" },
  { id: "evento", numeric: false, disablePadding: false, label: "Evento" },
  {
    id: "descripcionriesgo",
    numeric: false,
    disablePadding: false,
    label: "Descripcion Riesgo",
  },
];

//Vulnerabilidad Tecnica header
const headCellsVulnT = [
  {
    id: "idEvaluacion",
    numeric: false,
    disablePadding: true,
    label: "Id Evaluacion",
  },
  {
    id: "activoevaluado",
    numeric: false,
    disablePadding: false,
    label: "Activo Evaluado",
  },
  {
    id: "idVulnerabilidad",
    numeric: false,
    disablePadding: false,
    label: "Id Vulnerabilidad",
  },
  {
    id: "vulnerabilidadtipo",
    numeric: false,
    disablePadding: false,
    label: "Tipo de Vulnerabilidad",
  },
  {
    id: "detallevulnerabilidad",
    numeric: false,
    disablePadding: false,
    label: "Detalle Vulnerabilidad",
  },
];

//Vulnerabilidad No Tecnica header
const headCellsVulnNT = [
  {
    id: "idEvaluacion",
    numeric: false,
    disablePadding: true,
    label: "Id Evaluacion",
  },
  {
    id: "activoevaluado",
    numeric: false,
    disablePadding: false,
    label: "Activo Evaluado",
  },
  {
    id: "idVulnerabilidadNT",
    numeric: false,
    disablePadding: false,
    label: "Id Vulnerabilidad No Tecnica",
  },
  {
    id: "vulnerabilidadtipo",
    numeric: false,
    disablePadding: false,
    label: "Tipo de Vulnerabilidad",
  },
  {
    id: "situacionobservada",
    numeric: false,
    disablePadding: false,
    label: "Situación Observada",
  },
  {
    id: "observaciones",
    numeric: false,
    disablePadding: false,
    label: "Observaciones",
  },
];

//Empieza tabla de riesgos
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

//Modal
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeadRiesgos(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCellsRiesgos.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHeadRiesgos.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHeadVulnT(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCellsVulnT.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHeadVulnT.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHeadVulnNT(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCellsVulnNT.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadVulnNT.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
//////////////////////Fin Tabla dentro del Modal
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Evaluaciones
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "40vh",
    minHeight: "40vh",
  },
}));
//////////////////////Fin Tabla dentro del Modal

const stylesSelect = {
  control: (base) => ({
    ...base,
    fontFamily: "Roboto",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "CIBFont Sans Regular",
  }),

  option: (provided, state) => ({
    ...provided,
    //color: state.isFocused ? 'white' : '#495057',
  }),
};

const NuevosPlanAccion = (props) => {
  const classes = useStyles();

  const [companiaDat, setCompaniaDat] = React.useState([]);
  const [usuariosDat, setUsuariosDat] = React.useState([]);
  const [usuariosDatNeg, setUsuariosDatNeg] = React.useState([]);
  const [riesgos, setRiesgos] = React.useState([]);
  const [vulT, setVulT] = React.useState([]);
  const [vulNT, setVulNT] = React.useState([]);
  const [riesgosTodo, setRiesgosTodo] = React.useState([]);
  const [vulTTodo, setVulTTodo] = React.useState([]);
  const [vulNTTodo, setVulNTTodo] = React.useState([]);
  const [seguimiento, setSeguimiento] = React.useState([]);

  const [tipoComp2, setTipoComp2] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  let history = useHistory();
  const [validated, setValidated] = useState(false);
  //////////////////////////////Selects
  const [responsablePA, setresponsablePA] = useState(null);
  const [analistaRiesgos, setAnalistaRiesgos] = useState(null);
  //////////////////////////////datos tablas
  const [rowsc, setRowsC] = React.useState([]);
  const [rowsP, setRowsP] = React.useState([]);
  const [riesgosSelected, setRiesgosSelected] = React.useState([]);
  const [vul_tSelected, setVul_tSelected] = React.useState([]);
  const [vul_ntSelected, setVul_ntSelected] = React.useState([]);
  const [seguimientoSelected, setseguimientoSelected] = React.useState([]);

  const [modalShowRiesgos, setModalShowRiesgos] = React.useState(false);
  const [modalShowVulT, setModalShowVulT] = React.useState(false);
  const [modalShowVulNT, setModalShowVulNT] = React.useState(false);
  const [modalShowSeguimiento, setModalShowSeguimiento] = React.useState(false);

  const [direccion, setDireccion] = React.useState(false);
  const [datContenidos, setDatContenidos] = React.useState([]);

  //const AzureADService = new AADService();
  //const token = AzureADService.getToken();

  useEffect(() => {
    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      setCompaniaDat(datCompania);
    };
    const obtenerUsuariosNeg = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosroti/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      setUsuariosDatNeg(datUsuarios);
    };
    const obtenerUsuarios = async () => {
      const result1 = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/4/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuariosRO = await result1.json();
      const result2 = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/5/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuariosTI = await result2.json();
      let datUsuarios = datUsuariosRO.concat(datUsuariosTI);
      setUsuariosDat(datUsuarios);
    };
    const obtenerTipoComp = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/tipoactivo/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setTipoComp2(data);
    };
    const obtenerRiesgos = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "riesgo/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setRiesgosTodo(data);
    };
    const obtenerVulT = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "vulnerabilidad/Tecnica/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setVulTTodo(data);
    };
    const obtenerVulNT = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "vulnerabilidad/NoTecnica/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setVulNTTodo(data);
    };

    obtenerTipoComp();
    obtenerUsuarios();
    obtenerUsuariosNeg();
    obtenerCompañias();
    obtenerRiesgos();
    obtenerVulT();
    obtenerVulNT();
  }, []);

  /////// Añadir tags para los componentes asociados
  const removeTag = (i) => {
    const newTags = [...datContenidos];
    newTags.splice(i, 1);
    setDatContenidos(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      e.preventDefault();
      setValidated(false);
      if (
        datContenidos.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      setDatContenidos([...datContenidos, val]);
      let inputTag = document.getElementById("datosSeparados");
      inputTag.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(datContenidos.length - 1);
    }
  };

  function llenarTipoComp(data) {
    let tipo = [];
    for (const clave in data) {
      if (
        data[clave]["codigogeneral"] == "COM" ||
        data[clave]["codigogeneral"] == "NUB"
      ) {
        tipo.push(data[clave]);
      }
    }
    return tipo;
  }

  const tipoComp = llenarTipoComp(tipoComp2);
  const [state, setState] = useState("Activo");
  const [idState, setIdState] = useState(true);
  const [vRequired, setVRequired] = useState(true);
  const [ast, setAst] = useState("*");

  const changeRequired = () => {
    let tipoComp = parseInt(document.getElementById("componenteT").value);
    let compP = document.getElementById("ComponentePpal").checked;
    if (tipoComp == 11 || compP) {
      setVRequired(false);
      setAst("");
    } else {
      setVRequired(true);
      setAst("*");
    }
  };

  const handleChangeState = (event) => {
    if (state == "Activo") {
      setState("Inactivo");
      setIdState(false);
    } else {
      setState("Activo");
      setIdState(true);
    }
  };

  const onChangeResponsablePA = (value) => {
    console.log("ESTE ES EL RESPONSABLE: " + value.idposicion);
    setresponsablePA(value.idposicion);
  };
  const onChangeAnalistaRiesgos = (value) => {
    console.log("ESTE ES EL ANALISTA: " + value.idusuario);
    setAnalistaRiesgos(value.idusuario);
  };

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let riesgosPATemp = [];
      riesgos.map((dat) => {
        riesgosPATemp.push({
          idplanaccionporriesgo: 0,
          idplanaccion: 0,
          idriesgo: dat.idriesgo,
          fechacreacion: today.toISOString(),
          idusuariocreacion: localStorage.getItem("idusuario"),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
          estadoasociacion: true,
          disp_numerico1: 0.0,
          disp_numerico2: 0.0,
          disp_varchar1: null,
          disp_varchar2: null,
        });
      });

      let vulTPATemp = [];
      vulT.map((dat) => {
        vulTPATemp.push({
          idpaporvulnerabilidadtecnica: 0,
          idvulnerabilidadtecnicaevalu: dat.idvulnerabilidadtecnicaevalu,
          idplanaccion: 0,
          fechacreacion: today.toISOString(),
          idusuariocreacion: localStorage.getItem("idusuario"),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
          estadoasociacion: true,
          disp_numerico1: 0.0,
          disp_numerico2: 0.0,
          disp_varchar1: null,
          disp_varchar2: null,
        });
      });

      let vulNTPATemp = [];
      vulNT.map((dat) => {
        vulNTPATemp.push({
          idpaporvulnerabilidadnotecnica: 0,
          idvulnerabilidad_notecnica: dat.idvulnerabilidad_notecnica,
          idplanaccion: 0,
          fechacreacion: today.toISOString(),
          idusuariocreacion: localStorage.getItem("idusuario"),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
          estadoasociacion: true,
          disp_numerico1: 0.0,
          disp_numerico2: 0.0,
          disp_varchar1: null,
          disp_varchar2: null,
        });
      });

      var data = JSON.stringify({
        idplanaccion: 0,
        nombre: document.getElementById("nombrePA").value,
        descripcion: document.getElementById("descripcionPA").value,
        fechainicio: document.getElementById("fechainicio").value,
        fechacompromisoinicial: document.getElementById(
          "fechacompromisoinicial"
        ).value,
        fechacompromisoactual: document.getElementById("fechacompromisoinicial")
          .value,
        //Hay que revisar en la base de datos que este campo pueda ser null
        fechafinalizacion: document.getElementById("fechacompromisoinicial")
          .value,
        estadopa: "Creado",
        porcentajeavance: 0.0,
        idposicionresponsablepa: parseInt(responsablePA),
        idanalistariesgos: parseInt(analistaRiesgos),
        fechacreacion: today.toISOString(),
        idusuariocreacion: localStorage.getItem("idusuario"),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        disp_numerico1: 0.0,
        disp_numerico2: 0.0,
        disp_varchar1: null,
        disp_varchar2: null,
        Planaccionporriesgo: riesgosPATemp,
        Planaccionporvulnenotecnica: vulNTPATemp,
        Planaccionporvulnetecnica: vulTPATemp,
      });

      let iniciofecha = document.getElementById("fechainicio").value;
      let finfecha = document.getElementById("fechacompromisoinicial").value;

      if (iniciofecha < finfecha) {
        fetch(process.env.REACT_APP_API_URL + "plandeAccion/0/", {
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        })
          .then((data) =>
            data.json().then((response) => {
              console.log(response);
              if (data.status >= 200 && data.status < 300) {
                setEstadoPost(2);
                localStorage.setItem("idplanaccion", response.idplanaccion);
                history.push("/EditarPlanAccion");
              } else if (data.status >= 500) {
                setEstadoPost(5);
                if (
                  data.non_field_errors[0] ===
                  "The fields idplanaccion must make a unique set."
                ) {
                  setEstadoPost(6);
                }
              } else if (data.status >= 400 && data.status < 500) {
                setEstadoPost(4);
              }
            })
          )
          .catch(function (err) { });
      } else {
        let fecha_inicial = document.getElementById('fechainicio');
        let fecha_fin = document.getElementById('fechacompromisoinicial');
        fecha_fin.value = '';
        fecha_inicial.value = '';
        setEstadoPost(3)
      }
    }
    setValidated(true);
  };
  ////////////////////////////////////////////
  //Riesgos Asociados al Plan de Accion
  function MyVerticallyCenteredModalRiesgos(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [riesgosTemp, setRiesgosTemp] = React.useState(props.riesgos);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = riesgosTodo.map((n) => n.idriesgo);
        setRiesgosTemp(newSelecteds);
        return;
      }
      setRiesgosTemp([]);
    };
    const handleClick = (event, idriesgo) => {
      const selectedIndex = riesgosTemp.indexOf(idriesgo);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(riesgosTemp, idriesgo);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(riesgosTemp.slice(1));
      } else if (selectedIndex === riesgosTemp.length - 1) {
        newSelected = newSelected.concat(riesgosTemp.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          riesgosTemp.slice(0, selectedIndex),
          riesgosTemp.slice(selectedIndex + 1)
        );
      }
      setRiesgosTemp(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (idriesgo) => riesgosTemp.indexOf(idriesgo) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, riesgosTodo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir Riesgos Asociados al Plan de Acción
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Row>
              <Col sm={4} xs={2}>
                <Form className="buscar">
                  <Form.Control
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => setqueryText(e.target.value)}
                    style={{ align: "center" }}
                  />
                </Form>
              </Col>
              <Col sm={4} xs={6}></Col>
              <Col sm={4} xs={6}>
                <Button
                  className="botonPositivo"
                  style={{ marginTop: "1%", width: "100%" }}
                  onClick={() => completarTabla(riesgosTemp, "riesgos")}
                >
                  {" "}
                  Guardar{" "}
                </Button>
              </Col>
            </Row>
            <Paper className={classes.paper}>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHeadRiesgos
                    classes={classes}
                    numSelected={riesgosTemp.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={riesgosTodo.length}
                  />
                  <TableBody>
                    {stableSort(riesgosTodo, getComparator(order, orderBy))
                      .filter((row) =>
                        row.idevaluacion.toString().includes(queryText)
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idriesgo);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(event, row.idriesgo)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idriesgo}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.idevaluacion}
                            </TableCell>
                            <TableCell align="left">{row.idactivo}</TableCell>
                            <TableCell align="left">
                              {row.activoevaluado}
                            </TableCell>
                            <TableCell align="center">{row.idriesgo}</TableCell>
                            <TableCell align="left">{row.evento}</TableCell>
                            <TableCell align="left">
                              {row.descripcionriesgo}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={riesgosTodo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Filas por página"}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  //Vulnerabilidades Tecnicas Asociadas al Plan de Accion
  function MyVerticallyCenteredModalVulnT(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [vultTemp, setVultTemp] = React.useState(props.vulT);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = vulTTodo.map(
          (n) => n.idvulnerabilidadtecnicaevalu
        );
        setVultTemp(newSelecteds);
        return;
      }
      setVultTemp([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = vultTemp.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(vultTemp, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(vultTemp.slice(1));
      } else if (selectedIndex === vultTemp.length - 1) {
        newSelected = newSelected.concat(vultTemp.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          vultTemp.slice(0, selectedIndex),
          vultTemp.slice(selectedIndex + 1)
        );
      }
      setVultTemp(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => vultTemp.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, vulTTodo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir Vulnerabilidades Tecnicas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Row>
              <Col sm={4} xs={2}>
                <Form className="buscar">
                  <Form.Control
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => setqueryText(e.target.value)}
                    style={{ align: "center" }}
                  />
                </Form>
              </Col>
              <Col sm={4} xs={6}></Col>
              <Col sm={4} xs={6}>
                <Button
                  className="botonPositivo"
                  style={{ marginTop: "1%", width: "100%" }}
                  onClick={() => completarTabla(vultTemp, "vul_t")}
                >
                  {" "}
                  Guardar{" "}
                </Button>
              </Col>
            </Row>
            <Paper className={classes.paper}>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHeadVulnT
                    classes={classes}
                    numSelected={vultTemp.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={vulTTodo.length}
                  />
                  <TableBody>
                    {stableSort(vulTTodo, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .filter((row) =>
                        row.vulnerabilidadtipo
                          .toLowerCase()
                          .includes(queryText.toLowerCase())
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(
                          row.idvulnerabilidadtecnicaevalu
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.idvulnerabilidadtecnicaevalu,
                                row.idactivo,
                                row
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idvulnerabilidadtecnicaevalu}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.idevaluacion}
                            </TableCell>
                            <TableCell align="right">
                              {row.activoevaluado}
                            </TableCell>
                            <TableCell align="right">
                              {row.idvulnerabilidad_tecnica}
                            </TableCell>
                            <TableCell align="right">
                              {row.vulnerabilidadtipo}
                            </TableCell>
                            <TableCell align="right">
                              {row.detallevulnerabilidad}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={vulTTodo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Filas por página"}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  //Vulnerabilidades NO Tecnicas Asociadas al Plan de Accion
  function MyVerticallyCenteredModalVulnNT(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [vulNTTemp, setVulNTTemp] = React.useState(props.vulNT);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = vulNTTodo.map((n) => n.idevaluacion);
        setVulNTTemp(newSelecteds);
        return;
      }
      setVulNTTemp([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = vulNTTemp.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(vulNTTemp, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(vulNTTemp.slice(1));
      } else if (selectedIndex === vulNTTemp.length - 1) {
        newSelected = newSelected.concat(vulNTTemp.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          vulNTTemp.slice(0, selectedIndex),
          vulNTTemp.slice(selectedIndex + 1)
        );
      }
      setVulNTTemp(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => vulNTTemp.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, vulNTTodo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir Vulnerabilidades No Técnicas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Row>
              <Col sm={4} xs={2}>
                <Form className="buscar">
                  <Form.Control
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => setqueryText(e.target.value)}
                    style={{ align: "center" }}
                  />
                </Form>
              </Col>
              <Col sm={4} xs={6}></Col>
              <Col sm={4} xs={6}>
                <Button
                  className="botonPositivo"
                  style={{ marginTop: "1%", width: "100%" }}
                  onClick={() => completarTabla(vulNTTemp, "vul_nt")}
                >
                  {" "}
                  Guardar{" "}
                </Button>
              </Col>
            </Row>
            <Paper className={classes.paper}>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHeadVulnT
                    classes={classes}
                    numSelected={vulNTTemp.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={vulNTTodo.length}
                  />
                  <TableBody>
                    {stableSort(vulNTTodo, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .filter((row) =>
                        row.vulnerabilidadtipo
                          .toLowerCase()
                          .includes(queryText.toLowerCase())
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(
                          row.idvulnerabilidad_notecnica
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.idvulnerabilidad_notecnica,
                                row.idactivo,
                                row
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idvulnerabilidad_notecnica}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.idevaluacion}
                            </TableCell>
                            <TableCell align="left">
                              {row.activoevaluado}
                            </TableCell>
                            <TableCell align="left">
                              {row.idvulnerabilidad_notecnica}
                            </TableCell>
                            <TableCell align="left">
                              {row.vulnerabilidadtipo}
                            </TableCell>
                            <TableCell align="left">
                              {row.observaciones}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={vulNTTodo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Filas por página"}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const completarTabla = (obj, id) => {
    let temp = [];

    switch (id) {
      case "riesgos":
        setRiesgosSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(riesgosTodo.filter((dato) => dato.idriesgo === obj[i])[0]);
        }
        setRiesgos(temp);
        setModalShowRiesgos(false);
        break;
      case "vul_t":
        setVul_tSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(
            vulTTodo.filter(
              (dato) => dato.idvulnerabilidadtecnicaevalu === obj[i]
            )[0]
          );
        }
        setVulT(temp);
        setModalShowVulT(false);
        break;
      case "vul_nt":
        setVul_ntSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(
            vulNTTodo.filter(
              (dato) => dato.idvulnerabilidad_notecnica === obj[i]
            )[0]
          );
        }
        setVulNT(temp);
        setModalShowVulNT(false);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
      <MyVerticallyCenteredModalRiesgos
        riesgos={riesgosSelected}
        show={modalShowRiesgos}
        onHide={() => setModalShowRiesgos(false)}
      />
      <MyVerticallyCenteredModalVulnT
        vulT={vul_tSelected}
        show={modalShowVulT}
        onHide={() => {
          setDireccion(false);
          setModalShowVulT(false);
        }}
      />
      <MyVerticallyCenteredModalVulnNT
        vulNT={vul_ntSelected}
        show={modalShowVulNT}
        onHide={() => {
          setDireccion(false);
          setModalShowVulNT(false);
        }}
      />
      <Row>
        <Col>
          {" "}
          <h2 className="subtitulo">Nuevo Plan de Acción</h2>
        </Col>
      </Row>
      <hr />
      <Form
        id="formData"
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Id Plan de Acción</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              disabled
              className="form-control text-center font-weight-bold"
              placeholder="ID Automático"
              id="idplanaccion"
            ></input>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Nombre Plan de Acción*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nombre del Plan de Acción"
              required
              id="nombrePA"
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Descripción*</label>
          </Col>
          <Col sm={8} xs={10}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Descripción del Plan de Acción"
              required
              rows="4"
              id="descripcionPA"
            ></input>
             <Form.Control.Feedback type="invalid">
              Por favor introduzca una Descripción.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Estado</label>
          </Col>
          <Col sm={3} xs={12}>
            <input
              type="text"
              disabled
              className="form-control text-center"
              placeholder="Estado del Plan de Acción"
              id="estadoPA"
            ></input>
          </Col>
          <Col sm={2} xs={12}>
            <label className="label forn-label">Porcentaje Avance</label>
          </Col>
          <Col sm={3} xs={12}>
            <input
              type="text"
              disabled
              className="form-control text-center"
              placeholder="Procentaje Plan de Acción"
              id="procentajePA"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">
              Responsable del Plan de Acción*
            </label>
          </Col>
          <Col sm={3} xs={10}>
            <Select
              placeholder={"Seleccione el responsable..."}
              getOptionLabel={(option) => option.nombre}
              getOptionValue={(option) => option.idposicion}
              options={usuariosDat}
              onChange={onChangeResponsablePA}
              styles={stylesSelect}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un responsable.
            </Form.Control.Feedback>
          </Col>
          <Col sm={2} xs={12}>
            <label className="label forn-label">Analista de Riesgo*</label>
          </Col>
          <Col sm={3} xs={10}>
            <Select
              placeholder={"Seleccione el analista..."}
              getOptionLabel={(option) => option.nombre}
              getOptionValue={(option) => option.idusuario}
              options={usuariosDatNeg}
              onChange={onChangeAnalistaRiesgos}
              styles={stylesSelect}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un responsable.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Fecha Inicio*</label>
          </Col>
          <Col sm={3} xs={12}>
            <input
              type="date"
              className="form-control text-center"
              id="fechainicio"
              required={vRequired}
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca la Fecha de inicio.
            </Form.Control.Feedback>
          </Col>
          <Col sm={2} xs={12}>
            <label className="label form-label">Compromiso Inicial*</label>
          </Col>
          <Col sm={3} xs={12}>
            <input
              type="date"
              className="form-control text-center"
              id="fechacompromisoinicial"
              required={vRequired}
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca la Fecha de Compromiso inicial.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={1}></Col>
          <Col sm={3} xs={3}>
            {" "}
            <Button
              type="submit"
              className="botonPositivo"
              id="send"
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            >
              Guardar
            </Button>
          </Col>
          <Col sm={3} xs={3}>
            {" "}
            <Link to="planes-de-accion">
              <Button className="botonNegativo">Descartar</Button>
            </Link>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <br />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={10}>
            <h3 className="subtitulo">Riesgos asociados al Plan de Acción ​</h3>
          </Col>
          <Col md={2}>
            <Button
              className="botonIngreso"
              onClick={() => setModalShowRiesgos(true)}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id Evaluación</StyledTableCell>
                  <StyledTableCell align="left">Id Activo</StyledTableCell>
                  <StyledTableCell align="left">
                    Activo Evaluado
                  </StyledTableCell>
                  <StyledTableCell align="left">Id Riesgo</StyledTableCell>
                  <StyledTableCell align="left">Evento</StyledTableCell>
                  <StyledTableCell align="left">Descripción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {riesgos.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.idevaluacion}</StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="left">
                      {row.idactivo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.activoevaluado}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.idriesgo}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.evento}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.descripcionriesgo}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        <Row className="mb-3">
          <Col md={10}>
            <h3 className="subtitulo">
              Vulnerabilidades Técnicas asociadas al Plan de Acción
            </h3>
          </Col>
          <Col md={2}>
            <Button
              className="botonIngreso"
              onClick={() => {
                setModalShowVulT(true);
                setDireccion(true);
              }}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id Evaluación</StyledTableCell>
                  <StyledTableCell align="left">
                    Activo Evaluado
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Id Vulnerabilidad
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Tipo de Vulnerabilidad
                  </StyledTableCell>
                  <StyledTableCell align="left">Detalle</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vulT.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.idevaluacion}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.activoevaluado}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.idvulnerabilidad_tecnica}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.vulnerabilidadtipo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.detallevulnerabilidad}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        <Row className="mb-3">
          <Col md={10}>
            <h3 className="subtitulo">
              Vulnerabilidades <strong>No</strong> Técnicas asociadas al Plan de
              Acción
            </h3>
          </Col>
          <Col md={2}>
            <Button
              className="botonIngreso"
              onClick={() => {
                setModalShowVulNT(true);
                setDireccion(true);
              }}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id Evaluación</StyledTableCell>
                  <StyledTableCell align="left">
                    Activo Evaluado
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Id Vulnerabilidad No Tecnica
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Tipo de Vulnerabilidad
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Situación Observada
                  </StyledTableCell>
                  <StyledTableCell align="left">Observaciones</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vulNT.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.idevaluacion}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.activoevaluado}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.idvulnerabilidad_notecnica}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.vulnerabilidadtipo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.situacionobservada}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.observaciones}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
      </Form>
    </>
  );
};

export default NuevosPlanAccion;
