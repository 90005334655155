import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col, Form } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole";
import AADService from "../funcionesAuth.js";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function App() {
  //Establece estilos de componentes
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  //Modifica temas predeterminados por Material-Table
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  var columns = [
    {
      field: "iddecision",
      title: "Id Riesgo",
      align: "justify",
    },
    {
      field: "descripcionriesgo",
      title: "Riesgo",
    },
    {
      field: "exposicionresidual",
      title: "Riesgo Residual (MM)",
      type: "numeric",
    },
    {
      field: "nivelriesgo",
      title: "Nivel Riesgo",
    },
    {
      field: "decision",
      title: "Decision",
    },
    {
      field: "fechacreacion",
      title: "Fecha Decision",
    },
    {
      field: "nombreresponsable",
      title: "Responsable de la decision",
    },
    {
      field: "observaciones",
      title: "Observaciones",
    },
  ];

  const classes = useStyles();

  const [decision, setDecision] = useState([]); //table data
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [filter, setFilter] = useState(false);
  const [nameCompa, setNameCompa] = React.useState("");
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  //const AzureADService = new AADService();
  //const token = AzureADService.getToken();
  const check = new CheckRole("/decisiones");

  useEffect(() => {
    //Obtiene la infomacion de las decisiones
    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "decisiones/0/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            // Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      data.map((dato) => {
        dato.exposicionresidual = numberFormatter(dato.exposicionresidual);
      });
      // console.log("data12211", data);
      setDecision(data);
    };
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "usuarios/menu/" + localStorage.getItem("usuario") + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            /*Authorization: "Bearer " + token,*/
          },
        }
      );
      let usuario = await result.json();
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      // console.log("Entramos en el Check: ", opciones);
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        fetchdata();
      }
    };
    fetchUsuario();
  }, []);

  function numberFormatter(data) {
    let formatData = parseInt(data).toFixed(2);
    formatData = formatData
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1,")
      .slice(0, -1);
    formatData =
      "$" + formatData.split("").reverse().join("").replace(/^[\.]/, "");
    return formatData;
  }

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };

  const editar = () => {
    localStorage.setItem("iddecision", selected);
  };

  //   function add_eval() {
  //     localStorage.setItem("idactivo", 0);
  //   }

  //Retorna el nombre de la compañía segun id
  function bCompania(id) {
    for (const clave in nameCompa) {
      if (id === nameCompa[clave]["idcompania"]) {
        return nameCompa[clave]["compania"];
      }
    }
    return "No Existe";
  }

  //Habilita y desabilita los filtros por columnas
  const handleChange = () => {
    setFilter(!filter);
  };

  return (
    <div className={classes.root}>
      <Row>
        <Col>
          <h2 className="titulo">Decisiones</h2>
        </Col>
      </Row>
      <Paper variant="outlined" className={classes.paper}>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            columns={columns}
            data={decision}
            icons={tableIcons}
            options={{
              rowStyle: (rowData) => ({
                backgroundColor:
                  ButtonEdit === true && selectedRow === rowData.tableData.id
                    ? "#ffdb94"
                    : "#FFF",
              }),
              showTitle: false,
              filtering: filter,
              searchFieldAlignment: "left",
              searchFieldStyle: { height: 50 }, //Llenar estilo de barra de busqueda
              headerStyle: {
                backgroundColor: "#2c2a29",
                color: "#ffffff",
                position: "sticky",
                top: 0,
                fontFamily: "CIBFont Sans Regular",
                fontWeight: 400,
                fontSize: "1.2rem",
                lineHeight: 1.6,
                letterSpacing: "0.0075em",
                flex: "1 1 100%",
              },
              maxBodyHeight: "650px",
              selection: false,
              disableMultipleSelection: true,
              pageSizeOptions: [10, 20, 30],
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 10,
            }}
            components={{
              Pagination: (props) => (
                <div className={classes.pagination}>
                  <FormControlLabel
                    className={classes.switch}
                    control={
                      <Checkbox
                        checked={filter}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Mostrar filtros por columnas
                      </Typography>
                    }
                  />
                  <TablePagination {...props} className={classes.pagination} />
                </div>
              ),
            }}
            onRowClick={(evt, selectedRow) => {
              handleClick(selectedRow.iddecision);
              return setSelectedRow(selectedRow.tableData.id);
            }}
            actions={[
              //   {
              //     icon: () => (
              //       <Link to="NuevoActivo">
              //         <Button className="botonPositivo">Nuevo</Button>
              //       </Link>
              //     ),
              //     onClick: add_eval(),
              //     position: "toolbar",
              //     isFreeAction: true,
              //   },
              {
                icon: () => (
                  <Link to="EditarDecisiones">
                    {check.checkPermits(opcionesGenerales, "PUT") ? (
                      <Button className="botonPositivo">Editar</Button>
                    ) : (
                      ""
                    )}
                  </Link>
                ),
                onClick: editar(),
                position: "toolbar",
                isFreeAction: true,
                hidden: ButtonEdit === false,
              },
            ]}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No se encontraron registros",
              },
              pagination: {
                labelRowsSelect: "Filas",
                firstTooltip: "Primera página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Última página",
              },
            }}
          />
        </MuiThemeProvider>
      </Paper>
    </div>
  );
}

export default App;
