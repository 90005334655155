import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import { without, filter } from "lodash";
import Select from "react-select";

//Librerias de la tabla
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { param } from "jquery";
import AADService from "../funcionesAuth.js";

//Finaliza las librerias de la tabla

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}
//procesos header
const headCellsProcesos = [
  {
    id: "idsubproceso",
    numeric: false,
    disablePadding: true,
    label: "Id Subproceso",
  },
  {
    id: "macroproceso",
    numeric: false,
    disablePadding: false,
    label: "Macroproceso",
  },
  { id: "proceso", numeric: false, disablePadding: false, label: "Proceso" },
  {
    id: "subproceso",
    numeric: false,
    disablePadding: false,
    label: "Subproceso",
  },
  { id: "estado", numeric: false, disablePadding: false, label: "Estado" },
];
//activos header
const headCells = [
  { id: "ID", numeric: false, disablePadding: true, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },
  { id: "ip", numeric: false, disablePadding: false, label: "Dirección IP" },
  {
    id: "descripcion",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
  },
  {
    id: "ubicacion_logica",
    numeric: false,
    disablePadding: false,
    label: "Ubicación lógica",
  },
  {
    id: "relevancia",
    numeric: false,
    disablePadding: false,
    label: "Relevancia",
  },
  {
    id: "tipo_ambiente",
    numeric: false,
    disablePadding: false,
    label: "Tipo de ambiente",
  },
];
//////////////////////Tabla dentro del Modal
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeadProcesos(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCellsProcesos.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHeadProcesos.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
//////////////////////Fin Tabla dentro del Modal
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Evaluaciones
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "40vh",
    minHeight: "40vh",
  },
}));
//////////////////////Fin Tabla dentro del Modal

//Empieza tabla de activos
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const stylesSelect = {
  control: (base) => ({
    ...base,
    fontFamily: "Roboto",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "CIBFont Sans Regular",
  }),

  option: (provided, state) => ({
    ...provided,
    //color: state.isFocused ? 'white' : '#495057',
  }),
};

//Finaliza tabla de activos

const EditarComponente = (props) => {
  const classes = useStyles();
  const [modalShow, setModalShow] = React.useState(false);
  const [activo, setActivo] = React.useState([]);
  const [evc, setEvc] = React.useState([]);
  const [companiaDat, setCompaniaDat] = React.useState([]);
  const [usuariosDatNeg, setUsuariosDatNeg] = React.useState([]);
  const [usuariosDatTI, setUsuariosDatTI] = React.useState([]);
  const [tipoComp2, setTipoComp2] = React.useState([]);
  const [modalInfo, setModalInfo] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  let history = useHistory();
  const [validated, setValidated] = useState(false);
  /////////////////////////////////////
  const tipoComp = llenarTipoComp(tipoComp2);

  const [state, setState] = useState("Activo");
  const [idState, setIdState] = useState(true);
  const [vRequired, setVRequired] = useState(true);
  const [ast, setAst] = useState("*");
  //////////////////////Constantes datos selects
  const [selectedValueCompania, setSelectedValueCompania] = useState(null);
  const [selectedValueResponsableTI, setSelectedValueResponsableTI] =
    useState(null);
  const [selectedValueResponsableNeg, setSelectedValueResponsableNeg] =
    useState(null);
  ///////////////////////////////
  const [rowsc, setRowsC] = React.useState([]);
  const [rowsCompInit, setRowsCompInit] = React.useState([]);
  const [rowsP, setRowsP] = React.useState([]);
  const [rowsProcInit, setRowsProcInit] = React.useState([]);
  const [procesosSelected, setProcesosSelected] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  //////////////////////Constante Datos Tabla Modal
  const [modalCompo, setModalCompo] = useState([]);
  const [modalShowProcesos, setModalShowProcesos] = React.useState(false);
  const [datContenidos, setDatContenidos] = React.useState([]);
  //const AzureADService = new AADService();
  //const token = AzureADService.getToken();

  useEffect(() => {
    const obtenerComponentes = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "activosTipo/10/" + localStorage.getItem("idcompania") + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datComponentes = await result.json();
      datComponentes = datComponentes.filter(
        (dato) => dato.componente_principal === false
      );

      setModalCompo(datComponentes);
    };
    const obtenerProceso = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/procesosxCompania/" + localStorage.getItem("idcompania") + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datProcesos = await result.json();
      setModalInfo(datProcesos);
    };
    const obtenerActivo = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "activoInformacion/" +
        localStorage.getItem("idactivo") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datActivo = await result.json();
      let temp = [];
      let temp2 = [];
      let tempRowC = [];
      let tempRowP = [];
      if (datActivo.ComponentesInfraestructura !== null) {
        for (let i = 0; i < datActivo.ComponentesInfraestructura.length; i++) {
          const element = datActivo.ComponentesInfraestructura[i];
          if (element.estadoasociacion == true) {
            tempRowC.push(element);
            temp.push(element.idactivo);
          }
        }
      }
      if (datActivo.ProcesosAsociados) {
        for (let i = 0; i < datActivo.ProcesosAsociados.length; i++) {
          const element = datActivo.ProcesosAsociados[i];
          if (element.estadoasociacion == true) {
            temp2.push(element.idsubproceso);
            tempRowP.push(element);
          }
        }
      }

      if (datActivo.datos_en_el_activo !== null) {
        setDatContenidos(datActivo.datos_en_el_activo.split(","));
      }

      if (datActivo != null && datActivo.UsuarioResponsableNegocio !== null) {
        setSelectedValueResponsableNeg({
          value: datActivo.idposicionresponsablenegocio,
          label: datActivo.UsuarioResponsableNegocio,
        });
      }

      if (datActivo != null && datActivo.UsuarioResponsableTI !== null) {
        setSelectedValueResponsableTI({
          value: datActivo.idposicionresponsableti,
          label: datActivo.UsuarioResponsableTI,
        });
      }

      const data = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );

      let datCompania = await data.json();
      let nombrecompania = datCompania
        .filter(({ idcompania }) => idcompania == datActivo.idcompania)
        .map(({ compania }) => compania)
        .pop();

      if (datActivo != null && datActivo.idcompania !== null) {
        setSelectedValueCompania({
          value: datActivo.idcompania,
          label: nombrecompania,
        });
      }

      setComponentesSelected(temp);
      setProcesosSelected(temp2);
      if (datActivo.componente_principal == true) {
        setVRequired(false);
      } else {
        setVRequired(true);
      }
      try {
        document.getElementById("Nube").checked = datActivo.nube;
        document.getElementById("BIA").checked = datActivo.bia;
        document.getElementById("SOX").checked = datActivo.sox;
      } catch { }
      setRowsC(tempRowC);
      setRowsCompInit(datActivo.ComponentesInfraestructura);
      setRowsP(tempRowP);
      setRowsProcInit(datActivo.ProcesosAsociados);
      setActivo(datActivo);
      if (datActivo.idtipo_activo == 11 || datActivo.componente_principal) {
        setVRequired(false);
        setAst("");
      } else {
        setVRequired(true);
        setAst("*");
      }
    };

    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      let companiaSelect = [];
      companiaSelect = datCompania.map(
        ({ idcompania: value, compania: label }) => ({
          value,
          label,
        })
      );

      setCompaniaDat(companiaSelect);
    };
    const obtenerEVC = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "general/evc/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datEVC = await result.json();
      setEvc(datEVC);
    };
    const obtenerUsuariosNeg = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/3/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();

      let usuariosNeg = datUsuarios.map(
        ({ idposicion: value, nombre: label }) => ({
          value,
          label,
        })
      );

      setUsuariosDatNeg(usuariosNeg);
    };

    const obtenerUsuariosTI = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/5/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      let usuariosTI = datUsuarios.map(
        ({ idposicion: value, nombre: label }) => ({
          value,
          label,
        })
      );

      setUsuariosDatTI(usuariosTI);
    };
    const obtenerTipoComp = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/tipoactivo/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setTipoComp2(data);
    };
    obtenerComponentes();
    obtenerProceso();
    obtenerActivo();
    obtenerTipoComp();
    obtenerUsuariosTI();
    obtenerUsuariosNeg();
    obtenerCompañias();
    obtenerEVC();
  }, []);

  /////// Añadir tags para los componentes asociados
  const removeTag = (i) => {
    const newTags = [...datContenidos];
    newTags.splice(i, 1);
    setDatContenidos(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      e.preventDefault();
      setValidated(false);
      if (
        datContenidos.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      setDatContenidos([...datContenidos, val]);
      let inputTag = document.getElementById("datosSeparados");
      inputTag.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(datContenidos.length - 1);
    }
  };

  const ubicacion_logica = [
    { id: "1", nombre: "Expuesto a internet" },
    { id: "2", nombre: "Red Confianza" },
    { id: "3", nombre: "Red Interna" },
    { id: "4", nombre: "Segmento controlado" },
  ];
  const relevancia = [
    { id: "1", nombre: "Componente Soporte" },
    {
      id: "2",
      nombre: "Infraestructura crítica transversal/Punto único de falla",
    },
    { id: "3", nombre: "Infraestructura crítica no transversal" },
  ];
  const tipo_ambiente = [
    { id: "1", nombre: "Producción" },
    { id: "2", nombre: "Certificación" },
  ];

  function llenarTipoComp(data) {
    let tipo = [];
    for (const clave in data) {
      if (
        data[clave]["codigogeneral"] == "COM" ||
        data[clave]["codigogeneral"] == "NUB"
      ) {
        tipo.push(data[clave]);
      }
    }
    return tipo;
  }

  const [codigoActivo, setCodigoActivo] = React.useState("");

  const changeRequired = () => {
    let tipoComp = parseInt(document.getElementById("componenteT").value);
    setCodigoActivo(tipoComp);
    let compP = vRequired;
    if (tipoComp == 11 || compP) {
      setVRequired(false);
      setAst("");
    } else {
      setVRequired(true);
      setAst("*");
    }
  };

  const handleChangeState = (event) => {
    if (state == "Activo") {
      setState("Inactivo");
      setIdState(false);
    } else {
      setState("Activo");
      setIdState(true);
    }
  };

  ////////////////////////Crea el componente Select
  const MySelect = (props) => (
    <Select
      {...props}
      className="texto"
      options={props.options}
      placeholder={props.placeholder}
    />
  );

  const handleChange = (e) => {
    setSelectedValueCompania({ value: e.value, label: e.label });
  };
  const handleChangeTI = (e) => {
    setSelectedValueResponsableTI({ value: e.value, label: e.label });
  };
  const handleChangeNeg = (e) => {
    setSelectedValueResponsableNeg({ value: e.value, label: e.label });
  };

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let rowscT;
      let rowspT;
      let actualizarT = [];
      let actualizarF = [];
      let nuevos = rowsc;
      if (rowsProcInit != null) {
        rowspT = rowsProcInit.filter((dato) => dato.estadoasociacion == true);
      }
      if (rowsCompInit != null) {
        rowscT = rowsCompInit.filter((dato) => dato.estadoasociacion == true);
      }

      //-//-// Obtener los nuevos seleccionados
      if (rowsCompInit !== null && nuevos !== null) {
        rowsCompInit.map((row) => {
          nuevos = filter(nuevos, (o) => o.idactivo != row.idactivo);
        });
      }
      //-//-// Obtener los que se deben Actualizar a False
      if (rowscT != null && rowsc != null) {
        actualizarF = rowscT;
        rowsc.map((row) => {
          actualizarF = filter(actualizarF, (o) => o.idactivo != row.idactivo);
        });
      }
      //-//-// Obtener los que se deben Actualizar a True
      if (rowsCompInit != null) {
        rowsCompInit.map((row) => {
          rowsc.map((act) => {
            if (row.estadoasociacion == false && row.idactivo == act.idactivo) {
              actualizarT.push(row);
            }
          });
        });
      }

      let componentesInfraestructuraTemp = [];
      if (actualizarT !== null) {
        actualizarT.map((obj) => {
          componentesInfraestructuraTemp.push({
            idcomponentedelcomponente: obj.idcomponentedelcomponente,
            idactivo: parseInt(document.getElementById("IDcomponente").value),
            idactivocomponente: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: true,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
            disp_numerico1: null,
            disp_numerico2: null,
            disp_varchar1: null,
            disp_varchar2: null,
          });
        });
      }
      if (actualizarF !== null) {
        actualizarF.map((obj) => {
          componentesInfraestructuraTemp.push({
            idcomponentedelcomponente: obj.idcomponentedelcomponente,
            idactivo: parseInt(document.getElementById("IDcomponente").value),
            idactivocomponente: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: false,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
            disp_numerico1: null,
            disp_numerico2: null,
            disp_varchar1: null,
            disp_varchar2: null,
          });
        });
      }
      if (nuevos !== null) {
        nuevos.map((obj) => {
          componentesInfraestructuraTemp.push({
            idcomponentedelcomponente: 0,
            idactivo: parseInt(document.getElementById("IDcomponente").value),
            idactivocomponente: obj.idactivo,
            fechacreacion: today.toISOString(),
            estadoasociacion: true,
            idusuariocreacion: localStorage.getItem("idusuario"),
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
            disp_numerico1: null,
            disp_numerico2: null,
            disp_varchar1: null,
            disp_varchar2: null,
          });
        });
      }

      //-//-// Creación para los procesos asociados
      let procesosAsociadosTemp = [];
      nuevos = rowsP;
      actualizarT = [];
      actualizarF = [];
      //-//-// Nuevos procesos
      if (rowsProcInit !== null && nuevos !== null) {
        rowsProcInit.map((row) => {
          nuevos = filter(nuevos, (o) => o.idsubproceso != row.idsubproceso);
        });
      }

      //-//-// Obtener los que se deben Actualizar a False
      if (rowspT !== null) {
        actualizarF = rowspT;
        rowsP.map((row) => {
          actualizarF = filter(
            actualizarF,
            (o) => o.idsubproceso != row.idsubproceso
          );
        });
      }

      //-//-// Obtener los que se deben Actualizar a True
      if (rowsProcInit !== null) {
        rowsProcInit.map((row) => {
          rowsP.map((act) => {
            if (
              (row.estadoasociacion =
                false && row.idsubproceso == act.idsubproceso)
            ) {
              actualizarT.push(row);
            }
          });
        });
      }

      if (actualizarT !== null && actualizarT !== undefined) {
        actualizarT.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: obj.idprocesoasociado,
            idsubproceso: obj.idsubproceso,
            idactivo: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: true,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (actualizarF !== null && actualizarF !== undefined) {
        actualizarF.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: obj.idprocesoasociado,
            idsubproceso: obj.idsubproceso,
            idactivo: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: false,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (nuevos !== null) {
        nuevos.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: 0,
            idsubproceso: obj.idsubproceso,
            idactivo: parseInt(document.getElementById("IDcomponente").value),
            fechacreacion: today.toISOString(),
            estadoasociacion: true,
            idusuariocreacion: localStorage.getItem("idusuario"),
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }

      var data = JSON.stringify({
        idactivo: parseInt(document.getElementById("IDcomponente").value),
        idcompania: parseInt(Object.values(selectedValueCompania)[0]),
        idtipo_activo: parseInt(document.getElementById("componenteT").value),
        nombre: document.getElementById("NombreComponente").value,
        idposicionresponsableti: parseInt(
          Object.values(selectedValueResponsableTI)[0]
        ),
        idposicionresponsablenegocio: parseInt(
          Object.values(selectedValueResponsableNeg)[0]
        ),
        descripcion: document.getElementById("Descripcion").value,
        datos_en_el_activo: document.getElementById("datosSeparados").value,
        estado: idState,
        componente_principal: vRequired ? false : true,
        bia:
          document.getElementById("BIA") == null
            ? false
            : document.getElementById("BIA").checked,
        sox:
          document.getElementById("SOX") == null
            ? false
            : document.getElementById("SOX").checked,
        idevc:
          document.getElementById("EVC") == null ||
            document.getElementById("EVC") == undefined
            ? null
            : document.getElementById("EVC").value,
        ubicacion_logica:
          document.getElementById("Ubicacion_logica") !== null
            ? document.getElementById("Ubicacion_logica").value
            : null,
        relevancia:
          document.getElementById("relevancia") !== null
            ? document.getElementById("relevancia").value
            : null,
        tipo_ambiente:
          document.getElementById("Tipo_Ambiente") !== null
            ? document.getElementById("Tipo_Ambiente").value
            : null,
        ip:
          document.getElementById("IP") !== null
            ? document.getElementById("IP").value
            : "",
        dns:
          document.getElementById("DNS") !== null
            ? document.getElementById("DNS").value
            : "",
        nube:
          document.getElementById("Nube") == null
            ? 0
            : document.getElementById("Nube").checked ? 1 : 0,
        elemento_configuracion:
          document.getElementById("codigo_unico_aplicacion") !== null
            ? document.getElementById("codigo_unico_aplicacion").value
            : "",
        fechacreacion: activo.fechacreacion,
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        ComponentesAsociados: "",
        ProveedoresAsociados: "",
        ProductoServiciosAsociados: "",
        ProcesosAsociados: procesosAsociadosTemp,
        ComponentesInfraestructura: componentesInfraestructuraTemp,
      });
      fetch(
        process.env.REACT_APP_API_URL +
        "activoInformacion/" +
        document.getElementById("IDcomponente").value +
        "/",
        {
          method: "PUT",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        }
      )
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
              if (props.location.state === undefined) {
                localStorage.setItem("idactivo", response.idactivo);
                history.push("/EditarComponente");
              } else {
                if (props.location.state === "DET") {
                  localStorage.setItem(
                    "idevaluacion",
                    props.location.id_evaluacion
                  );
                  history.push("/Descripción de componentes");
                } else {
                  localStorage.setItem("idactivo", response.idactivo);
                  history.push("/EditarComponente");
                }
              }
            } else if (data.status >= 500) {
              setEstadoPost(5);
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                setEstadoPost(6);
              }
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
          })
        )
        .catch(function (err) { });
    }
    setValidated(true);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Procesos
  function MyVerticallyCenteredModalProcesos(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [procesosTemp, setProcesosTemp] = React.useState(props.procesos);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalInfo.map((n) => n.idsubproceso);
        setProcesosTemp(newSelecteds);
        return;
      }
      setProcesosTemp([]);
    };
    const handleClick = (event, idsubproceso) => {
      const selectedIndex = procesosTemp.indexOf(idsubproceso);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(procesosTemp, idsubproceso);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(procesosTemp.slice(1));
      } else if (selectedIndex === procesosTemp.length - 1) {
        newSelected = newSelected.concat(procesosTemp.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          procesosTemp.slice(0, selectedIndex),
          procesosTemp.slice(selectedIndex + 1)
        );
      }
      setProcesosTemp(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (idsubproceso) =>
      procesosTemp.indexOf(idsubproceso) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalInfo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir Procesos asociados al componente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  {
                    <Link to="NuevoComponente">
                      <Button
                        className="botonPositivo"
                        style={{ marginTop: "1%", width: "100%" }}
                      >
                        {" "}
                        Nuevo{" "}
                      </Button>
                    </Link>
                  }
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(procesosTemp, "procesos")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHeadProcesos
                    classes={classes}
                    numSelected={procesosTemp.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalInfo.length}
                  />
                  <TableBody>
                    {stableSort(modalInfo, getComparator(order, orderBy))

                      .filter((row) =>
                        row.idsubproceso
                          .toLowerCase()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idsubproceso);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(event, row.idsubproceso)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idsubproceso}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.idsubproceso}
                            </TableCell>
                            <TableCell align="left">
                              {row.macroproceso}
                            </TableCell>
                            <TableCell align="left">{row.proceso}</TableCell>
                            <TableCell align="left">{row.subproceso}</TableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                inputProps={{ "aria-label": "Checkbox A" }}
                                checked={row.estado}
                              />
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={modalInfo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  //Componentes
  function MyVerticallyCenteredModal(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);
    const [activo, setActivo] = React.useState([]);
    const [ButtonEdit, SetButtonEdit] = React.useState(false);
    const [selected, setSelected] = React.useState([]);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);
        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = contTecno.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contTecno, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contTecno.slice(1));
      } else if (selectedIndex === contTecno.length - 1) {
        newSelected = newSelected.concat(contTecno.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contTecno.slice(0, selectedIndex),
          contTecno.slice(selectedIndex + 1)
        );
      }
      setContTecno(newSelected);
    };

    function add_eval() {
      localStorage.setItem("idactivo", 0);
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalCompo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir componentes de infraestructura
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  <Link to="NuevoComponente">
                    <Button
                      className="botonPositivo"
                      style={{ marginTop: "1%", width: "100%" }}
                      onClick={() => add_eval()}
                    >
                      {" "}
                      Nuevo{" "}
                    </Button>
                  </Link>
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(contTecno, "detalles")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalCompo.length}
                  />
                  <TableBody>
                    {stableSort(modalCompo, getComparator(order, orderBy))
                      .filter((row) =>
                        row.idactivo
                          .toString()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idactivo);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.idactivo,
                                row.idtipo_activo,
                                row
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idactivo}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.idactivo}
                            </TableCell>
                            <TableCell align="left">{row.nombre}</TableCell>
                            <TableCell align="left">{row.ip}</TableCell>
                            <TableCell align="left">
                              {row.descripcion}
                            </TableCell>
                            <TableCell align="left">
                              {row.ubicacion_logica}
                            </TableCell>
                            <TableCell align="left" style={{ whiteSpace: "nowrap" }}>{row.relevancia}</TableCell>
                            <TableCell align="left">
                              {row.tipo_ambiente}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>

                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={modalCompo.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  //TODO: completar función para encontrar con ID obeto y llenar tabla
  const completarTabla = (obj, id) => {
    let temp = [];

    switch (id) {
      case "procesos":
        setProcesosSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalInfo.filter((dato) => dato.idsubproceso == obj[i])[0]);
        }
        setRowsP(temp);
        setModalShowProcesos(false);
        break;
      case "detalles":
        setComponentesSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalCompo.filter((dato) => dato.idactivo === obj[i])[0]);
        }
        setRowsC(temp);
        setModalShow(false);
        break;

      default:
        break;
    }
  };
  //Fin del Modal
  let nombreEVC = " ";
  try {
    for (let i = 0; i < evc.length; i++) {
      if (evc[i].idevc == activo.idevc) {
        nombreEVC = evc[i].nombre;
        break;
      }
    }
  } catch { }

  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />

      <MyVerticallyCenteredModal
        componentes={componentesSelected}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <MyVerticallyCenteredModalProcesos
        procesos={procesosSelected}
        show={modalShowProcesos}
        onHide={() => setModalShowProcesos(false)}
      />

      <Row>
        <Col>
          {" "}
          <h2 className="subtitulo">Editar Componente</h2>
        </Col>
      </Row>
      <hr />
      <Form
        id="formData"
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Id Componente</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              disabled
              className="form-control text-center font-weight-bold"
              defaultValue={activo.idactivo}
              id="IDcomponente"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Tipo Componente*</label>
          </Col>
          <Col sm={8} xs={12}>
            <select
              className="form-control"
              onChange={() => changeRequired()}
              required
              id="componenteT"
            >
              <option value={activo.idtipo_activo}>{activo.tipo_activo}</option>
              {tipoComp.map((tip) =>
                activo.tipo_activo !== tip.tipo_activo ? (
                  <option key={tip.idtipo_activo} value={tip.idtipo_activo}>
                    {tip.tipo_activo}
                  </option>
                ) : null
              )}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Nombre Componente*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nombre del componente"
              defaultValue={activo.nombre}
              required
              id="NombreComponente"
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Descripción</label>
          </Col>
          <Col sm={8} xs={10}>
            <textarea
              className="form-control text-center"
              placeholder="Descripción del componente"
              defaultValue={activo.descripcion}
              rows="3"
              id="Descripcion"
            ></textarea>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">
              Datos contenidos en el dominio
            </label>
          </Col>
          <Col sm={8} xs={10}>
            <div className="input-tag" placeholder="Datos separados...">
              <ul className="input-tag__tags">
                {datContenidos.map((tag, i) => (
                  <li key={tag}>
                    {tag}
                    <button
                      type="button"
                      onClick={() => {
                        removeTag(i);
                      }}
                    >
                      +
                    </button>
                  </li>
                ))}
                <li className="input-tag__tags__input">
                  <input
                    id="datosSeparados"
                    type="text"
                    onKeyDown={(e) => {
                      inputKeyDown(e);
                    }}
                  />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Compañía*</label>
          </Col>
          <Col sm={8} xs={10}>
            <MySelect
              className="texto"
              onChange={handleChange}
              options={companiaDat}
              defaultValue={selectedValueCompania}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione una compañía.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">
              Responsable en tecnología*
            </label>
          </Col>
          <Col sm={3} xs={10}>
            <MySelect
              className="texto"
              onChange={handleChangeTI}
              options={usuariosDatTI}
              defaultValue={selectedValueResponsableTI}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un responsable.
            </Form.Control.Feedback>
          </Col>
          <Col sm={2} xs={12}>
            <label className="label forn-label">Responsable del negocio*</label>
          </Col>
          <Col sm={3} xs={10}>
            <MySelect
              className="texto"
              onChange={handleChangeNeg}
              options={usuariosDatNeg}
              defaultValue={selectedValueResponsableNeg}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un responsable.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={5}>
            <label className="label forn-label">Componente principal:</label>
          </Col>
          <Col sm={1} xs={5}>
            <FormControlLabel
              id="ComponentePpal"
              control={<Switch checked={!vRequired} />}
              onChange={changeRequired}
              name="Componente Principal"
              label={vRequired ? "Infraestructura" : "Principal"}
            />
          </Col>
          <Col sm={1} />
          {vRequired ? (
            <>
              <Col sm={1} xs={5} className="text-right">
                <label className="label forn-label text-center">BIA:</label>
              </Col>
              <Col sm={1} xs={5}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="BIA"
                ></input>
              </Col>
              <Col sm={1} xs={5} className="text-right">
                <label className="label forn-label text-center">SOX:</label>
              </Col>
              <Col sm={1} xs={5}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="SOX"
                ></input>
              </Col>
              <Col sm={1} xs={5} className="text-right">
                <label className="label forn-label text-center">Nube:</label>
              </Col>
              <Col sm={1} xs={5}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="Nube"
                  checked={activo.nube == 1 ? true : false}
                ></input>
              </Col>
            </>
          ) : (
            <>
              <Col sm={1}></Col>
              <Col sm={2}>
                <label className="label forn-label text-center">EVC*</label>
              </Col>
              <Col sm={3}>
                <select className="form-control" required id="EVC">
                  <option value={activo.idevc}>{nombreEVC}</option>
                  {evc.map((evcAso) =>
                    evcAso.idevc != activo.idevc ? (
                      <>
                        <option key={evcAso.idevc} value={evcAso.idevc}>
                          {evcAso.nombre}
                        </option>
                      </>
                    ) : (
                      " "
                    )
                  )}
                </select>
              </Col>
            </>
          )}
        </Row>
        {vRequired ? (
          <>
            <Row className="mb-3">
              <Col sm={4} xs={12}>
                <label className="label form-label">IP{ast}</label>
              </Col>
              <Col sm={8} xs={12}>
                <input
                  type="text"
                  className="form-control text-center"
                  defaultValue={activo.ip}
                  placeholder="Introduzca la IP"
                  id="IP"
                  required={vRequired}
                ></input>
                <Form.Control.Feedback type="invalid">
                  Por favor introduzca la IP.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={4} xs={12}>
                <label className="label form-label">DNS{ast}</label>
              </Col>
              <Col sm={8} xs={12}>
                <input
                  type="text"
                  className="form-control text-center"
                  defaultValue={activo.dns}
                  placeholder="Introduzca el DNS"
                  id="DNS"
                  required={vRequired}
                ></input>
                <Form.Control.Feedback type="invalid">
                  Por favor introduzca el DNS.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={4} xs={12}>
                <label className="label form-label">
                  Código único de aplicación
                </label>
              </Col>
              <Col sm={8} xs={12}>
                <input
                  type="text"
                  className="form-control text-center"
                  defaultValue={activo.elemento_configuracion}
                  placeholder="Introduzca Código único de aplicación"
                  id="codigo_unico_aplicacion"
                ></input>
                <Form.Control.Feedback type="invalid">
                  Por favor introduzca el Código único de aplicación
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={4} xs={5}>
                <label className="label forn-label">
                  Ubicación lógica{ast}
                </label>
              </Col>
              <Col sm={3} xs={5}>
                <select
                  className="form-control"
                  id="Ubicacion_logica"
                  required={vRequired}
                >
                  <option value={activo.ubicacion_logica}>
                    {activo.ubicacion_logica}
                  </option>
                  {ubicacion_logica.map((campo) =>
                    activo.ubicacion_logica !== campo.nombre ? (
                      <option key={campo.id} value={campo.nombre}>
                        {campo.nombre}
                      </option>
                    ) : null
                  )}
                </select>
                <Form.Control.Feedback type="invalid">
                  Por favor seleccione la ubicación lógica.
                </Form.Control.Feedback>
              </Col>
              <Col sm={2} xs={5}>
                <label className="label forn-label">Tipo Ambiente{ast}</label>
              </Col>
              <Col sm={3} xs={5}>
                <select
                  className="form-control"
                  id="Tipo_Ambiente"
                  required={vRequired}
                >
                  <option value={activo.tipo_ambiente}>
                    {activo.tipo_ambiente}
                  </option>
                  {tipo_ambiente.map((campo) =>
                    activo.tipo_ambiente !== campo.nombre ? (
                      <option key={campo.id} value={campo.nombre}>
                        {campo.nombre}
                      </option>
                    ) : null
                  )}
                </select>
                <Form.Control.Feedback type="invalid">
                  Por favor seleccione el tipo de ambiente.
                </Form.Control.Feedback>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <Row className="mb-3">
          {vRequired ? (
            <>
              <Col sm={4} xs={5} className="text-left">
                <label className="label forn-label">Relevancia{ast}</label>
              </Col>
              <Col sm={3} xs={5}>
                <select
                  className="form-control"
                  id="relevancia"
                  required={vRequired}
                >
                  <option value={activo.relevancia}>{activo.relevancia}</option>
                  {relevancia.map((campo) =>
                    activo.relevancia !== campo.nombre ? (
                      <option key={campo.id} value={campo.nombre}>
                        {campo.nombre}
                      </option>
                    ) : null
                  )}
                </select>
                <Form.Control.Feedback type="invalid">
                  Por favor seleccione la relevancia.
                </Form.Control.Feedback>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col sm={vRequired ? 2 : 4} xs={4}>
            <label className="label forn-label">Estado</label>
          </Col>
          <Col sm={3} xs={4}>
            <FormControlLabel
              id="switch"
              control={<Switch checked={idState} />}
              label={state}
              onChange={handleChangeState}
              name="Estado"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={0} />
          <Col>
            <div className="texto form-text">* Campos obligatorios</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={1}></Col>
          <Col sm={3} xs={3}>
            {" "}
            <Button type="submit" className="botonPositivo" id="send">
              Guardar
            </Button>
          </Col>
          <Col sm={3} xs={3}>
            {" "}
            <Link to="componente-tecnologico">
              <Button className="botonNegativo">Descartar</Button>
            </Link>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <br />
            <hr />
          </Col>
        </Row>
        {vRequired /*&& codigoActivo == 10*/ ? (
          ""
        ) : (
          <>
            <Row className="mb-3">
              <Col md={10}>
                <h2 className="subtitulo">
                  Añadir Componentes Infraestructura
                </h2>
              </Col>
              <Col md={2}>
                <Button
                  className="botonIngreso"
                  onClick={() => setModalShow(true)}
                >
                  Añadir
                </Button>{" "}
              </Col>
            </Row>
            <Row className="mb-3">
              <TableContainer
                component={Paper}
                className="table table-bordered"
              >
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">ID</StyledTableCell>
                      <StyledTableCell align="left">
                        Dirección IP
                      </StyledTableCell>
                      <StyledTableCell align="left">Nombre</StyledTableCell>
                      <StyledTableCell align="left">
                        Descripción
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Ubicación Logica
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Tipo de Ambiente
                      </StyledTableCell>
                      <StyledTableCell align="left">Relevancia</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsc !== null
                      ? rowsc.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {row.idactivo}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.ip}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.nombre}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.descripcion}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.ubicacion_logica}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.tipo_ambiente}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.relevancia}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Row>
          </>
        )}
        <Row className="mb-3">
          <Col md={10}>
            <h2 className="subtitulo">Procesos asociados al componente ​</h2>
          </Col>
          <Col md={2}>
            <Button
              className="botonIngreso"
              onClick={() => setModalShowProcesos(true)}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID Proceso</StyledTableCell>
                  <StyledTableCell align="left">
                    Nombre del Proceso
                  </StyledTableCell>
                  <StyledTableCell align="left">Macroproceso</StyledTableCell>
                  <StyledTableCell align="left">Subproceso</StyledTableCell>
                  {/* <StyledTableCell align="left">Estado del Proceso</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsP !== null && rowsP !== undefined
                  ? rowsP.map((row, index) =>
                    row.estadoasociacion !== false ? (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {row.idsubproceso}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.proceso}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.macroproceso}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.subproceso}
                        </StyledTableCell>
                        {/* <StyledTableCell align="left"><Checkbox
                                            value="checkedA"
                                            inputProps={{ 'aria-label': 'Checkbox A' }}
                                            checked={row.estado}/>
            </StyledTableCell> */}
                      </StyledTableRow>
                    ) : null
                  )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        {/* <Row className="mb-3">
          <Col md={10}>
              <h3>Añadir a canales de activo</h3>
          </Col>
          <Col md={2}>
            <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
          </Col>
        </Row>
        <Row  className="mb-3">
        <TableContainer component={Paper} className="table table-bordered">
      <Table className={classes.table} aria-label="customized table" >
        <TableHead>
          <TableRow>
            <StyledTableCell>ID Canal</StyledTableCell>
            <StyledTableCell align="right">Nombre del Canal</StyledTableCell>
            <StyledTableCell align="right">Estado del canal</StyledTableCell>
            <StyledTableCell align="right">Campo 1</StyledTableCell>
            <StyledTableCell align="right">Campo 2</StyledTableCell>
            <StyledTableCell align="right">Fecha de acción</StyledTableCell>
            <StyledTableCell align="right">Estado de asociación</StyledTableCell>
            <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows1.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.encabezado1}
              </StyledTableCell>
              <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Row>
        <Row className="mb-3">
          <Col md={10}>
              <h3>Añadir productos al activo</h3>
          </Col>
          <Col md={2}>
            <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
          </Col>
        </Row>
        <Row  className="mb-3">
        <TableContainer component={Paper} className="table table-bordered">
      <Table className={classes.table} aria-label="customized table" >
        <TableHead>
          <TableRow>
            <StyledTableCell>ID Producto</StyledTableCell>
            <StyledTableCell align="right">Nombre del Producto</StyledTableCell>
            <StyledTableCell align="right">Estado del Producto</StyledTableCell>
            <StyledTableCell align="right">Campo 1</StyledTableCell>
            <StyledTableCell align="right">Campo 2</StyledTableCell>
            <StyledTableCell align="right">Fecha de acción</StyledTableCell>
            <StyledTableCell align="right">Estado de asociación</StyledTableCell>
            <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows2.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.encabezado1}
              </StyledTableCell>
              <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Row>
        <Row className="mb-3">
          <Col md={10}>
              <h3>Usuarios interesados</h3>
          </Col>
          <Col md={2}>
            <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
          </Col>
        </Row>
        <Row  className="mb-3">
        <TableContainer component={Paper} className="table table-bordered">
      <Table className={classes.table} aria-label="customized table" >
        <TableHead>
          <TableRow>
            <StyledTableCell>ID Usuario</StyledTableCell>
            <StyledTableCell align="right">Nombre del Usuario</StyledTableCell>
            <StyledTableCell align="right">Tipo Interesado</StyledTableCell>
            <StyledTableCell align="right">Alcance de interes</StyledTableCell>
            <StyledTableCell align="right">Campo 2</StyledTableCell>
            <StyledTableCell align="right">Fecha de acción</StyledTableCell>
            <StyledTableCell align="right">Estado de asociación</StyledTableCell>
            <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows3.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.encabezado1}
              </StyledTableCell>
              <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Row> */}
      </Form>
    </>
  );
};

export default EditarComponente;
