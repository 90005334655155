import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material/styles";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        light: "#ffcd38",
        main: "#ffc107",
        dark: "#b28704",
      },
      secondary: {
        light: "#ffcd38",
        main: "#ffc107",
        dark: "#b28704",
      },
      error: {
        light: "#ffcd38",
        main: "#ffc107",
        dark: "#b28704",
      },
    },
  })
);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
