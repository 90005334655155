class CheckRole {
  constructor(archivo) {
    this.archivo = archivo;
  }

  checkPermits(opc, accion) {
    console.log("Entramos al Check Permits");
    var opcs = opc;
    var archivo = this.archivo;
    var acciones = accion;
    console.log(opcs);
    for (let i = 0; i < opcs.length; i++) {
      if (opcs[i].opcion == archivo) {
        console.log("Tenemos una opcion valida");
        if (acciones == "GET") {
          if (
            opcs[i].permisos.includes("R") ||
            opcs[i].permisos.includes("A")
          ) {
            return true;
          }
        } else if (acciones == "POST") {
          if (opcs[i].permisos.includes("C")) {
            return true;
          }
        } else if (acciones == "PUT") {
          if (
            opcs[i].permisos.includes("U") ||
            opcs[i].permisos.includes("A")
          ) {
            return true;
          }
        }
        return false;
      }
    }
  }
}

export default CheckRole;
