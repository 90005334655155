import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Dropdown, DropdownButton, Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import AADService from "../funcionesAuth.js";
import TablePagination from "@mui/material/TablePagination";
import Select from "react-select";

//Librerias de la tabla
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";

import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";

//Finaliza las librerias de la tabla

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}
//procesos header
const headCellsProcesos = [
  {
    id: "idsubproceso",
    numeric: false,
    disablePadding: true,
    label: "Id subproceso",
  },
  {
    id: "macroproceso",
    numeric: false,
    disablePadding: false,
    label: "Macroproceso",
  },
  { id: "proceso", numeric: false, disablePadding: false, label: "Proceso" },
  {
    id: "subproceso",
    numeric: false,
    disablePadding: false,
    label: "Subproceso",
  },
  { id: "estado", numeric: false, disablePadding: false, label: "Estado" },
];
//Componentes header
const headCells = [
  { id: "ID", numeric: false, disablePadding: true, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },
  {
    id: "descripcion",
    numeric: true,
    disablePadding: false,
    label: "Descripción",
  },
];
//////////////////////Tabla dentro del Modal
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeadProcesos(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCellsProcesos.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHeadProcesos.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
//////////////////////Fin Tabla dentro del Modal
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Evaluaciones
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "40vh",
    minHeight: "40vh",
  },
}));
//////////////////////Fin Tabla dentro del Modal

//Empieza tabla de activos
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
//Finaliza tabla de activos

const stylesSelect = {
  control: (base) => ({
    ...base,
    fontFamily: "Roboto",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "CIBFont Sans Regular",
  }),

  option: (provided, state) => ({
    ...provided,
    //color: state.isFocused ? 'white' : '#495057',
  }),
};

const NuevaTecnologia = () => {
  const classes = useStyles();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState([]);
  const [modalShowProcesos, setModalShowProcesos] = React.useState(false);
  const [companiaDat, setCompaniaDat] = React.useState([]);
  const [usuariosDatNeg, setUsuariosDatNeg] = React.useState([]);
  const [usuariosDatTI, setUsuariosDatTI] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  let history = useHistory();
  const [validated, setValidated] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  //////////////////////////////datos tablas
  const [rowsc, setRowsC] = React.useState([]);
  const [rowsP, setRowsP] = React.useState([]);
  const [procesosSelected, setProcesosSelected] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  //////////////////////Constante Datos Tabla Modal
  const [modalCompo, setModalCompo] = useState([]);

  //////////////////////////////Selects
  const [companiaSelect, setCompaniaSelect] = useState(null);
  const [selectedValueResponsableTI, setSelectedValueResponsableTI] =
    useState(null);
  const [selectedValueResponsableNeg, setSelectedValueResponsableNeg] =
    useState(null);

  const [direccion, setDireccion] = React.useState(false);
  const [datContenidos, setDatContenidos] = React.useState([]);
  //const AzureADService = new AADService();
  //const token = AzureADService.getToken();

  useEffect(() => {
    const obtenerComponentes = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "activosTipo/10/" + localStorage.getItem("idcompania") + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datComponentes = await result.json();
      datComponentes = datComponentes.filter(
        (dato) => dato.componente_principal === true
      );

      setModalCompo(datComponentes);
    };

    const obtenerProceso = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/procesosxCompania/" + localStorage.getItem("idcompania") + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datProcesos = await result.json();
      setModalInfo(datProcesos);
    };
    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      let selectcompania = datCompania.map(
        ({ idcompania: value, compania: label }) => ({
          value,
          label,
        })
      );
      setCompaniaDat(selectcompania);
    };
    const obtenerUsuariosNeg = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/3/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      let usuariosNeg = datUsuarios.map(
        ({ idposicion: value, nombre: label }) => ({
          value,
          label,
        })
      );
      setUsuariosDatNeg(usuariosNeg);
    };
    const obtenerUsuariosTI = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/5/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      let usuariosTI = datUsuarios.map(
        ({ idposicion: value, nombre: label }) => ({
          value,
          label,
        })
      );
      setUsuariosDatTI(usuariosTI);
    };
    obtenerComponentes();
    obtenerProceso();
    obtenerUsuariosNeg();
    obtenerUsuariosTI();
    obtenerCompañias();
  }, []);

  const crear_com = (event) => {
    var button = document.getElementById("send");
    //button.form.submit();
    //alert(button)
    // button.click();
    setModalShow(false);
  };

  const removeTag = (i) => {
    const newTags = [...datContenidos];
    newTags.splice(i, 1);
    setDatContenidos(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      e.preventDefault();
      setValidated(false);
      if (
        datContenidos.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      setDatContenidos([...datContenidos, val]);
      let inputTag = document.getElementById("datosSeparados");
      inputTag.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(datContenidos.length - 1);
    }
  };

  const [state, setState] = useState("Activo");
  const [idState, setIdState] = useState(true);

  const handleChangeState = (event) => {
    if (state == "Activo") {
      setState("Inactivo");
      setIdState(false);
    } else {
      setState("Activo");
      setIdState(true);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangeCompania = (option) => {
    setCompaniaSelect(option.value);
  };

  const handleChangeTI = (option) => {
    setSelectedValueResponsableTI(option.value);
  };
  const handleChangeNeg = (option) => {
    setSelectedValueResponsableNeg(option.value);
  };

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let componentesAsociadoTemp = [];
      rowsc.map((dat) => {
        componentesAsociadoTemp.push({
          idcomponenteasociado: 0,
          idactivo: 0,
          idactivocomponente: dat.idactivo,
          fechacreacion: today.toISOString(),
          estadoasociacion: true,
          idusuariocreacion: localStorage.getItem("idusuario"),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
        });
      });
      let procesosAsociadosTemp = [];
      rowsP.map((dat) => {
        procesosAsociadosTemp.push({
          idprocesoasociado: 0,
          idsubproceso: dat.idsubproceso,
          idactivo: 0,
          fechacreacion: today.toISOString(),
          estadoasociacion: true,
          idusuariocreacion: localStorage.getItem("idusuario"),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
        });
      });

      var data = JSON.stringify({
        idactivo: 0,
        idcompania: parseInt(companiaSelect),
        idtipo_activo: 16,
        nombre: document.getElementById("NombreActivo").value,
        idposicionresponsableti: parseInt(selectedValueResponsableTI),
        idposicionresponsablenegocio: parseInt(selectedValueResponsableNeg),
        descripcion: document.getElementById("Descripcion").value,
        datos_en_el_activo: document.getElementById("datosSeparados").value,
        estado: idState,
        componente_principal: true,
        clasificacion_confidencialidad: null,
        clasificacion_integridad: null,
        clasificacion_disponibilidad: null,
        nivel_criticidad: null,
        fechacreacion: today.toISOString(),
        idusuariocreacion: localStorage.getItem("idusuario"),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        ComponentesAsociados: componentesAsociadoTemp,
        ProductoServiciosAsociados: "",
        ProveedoresAsociados: "",
        ProcesosAsociados: procesosAsociadosTemp,
        ComponentesInfraestructura: "",
      });

      fetch(process.env.REACT_APP_API_URL + "activoInformacion/0/", {
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          //Authorization: "Bearer " + token,
        },
      })
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
              localStorage.setItem("idactivo", response.idactivo);
              history.push("/EditarTecnologia");
            } else if (data.status >= 500) {
              setEstadoPost(5);
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                setEstadoPost(6);
              }
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }

          })
        )
        .catch(function (err) {
        });
    }
    setValidated(true);
  };

  //Procesos
  function MyVerticallyCenteredModalProcesos(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [procesosTemp, setProcesosTemp] = React.useState(props.procesos);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalInfo.map((n) => n.idsubproceso);
        setProcesosTemp(newSelecteds);
        return;
      }
      setProcesosTemp([]);
    };
    const handleClick = (event, idsubproceso) => {
      const selectedIndex = procesosTemp.indexOf(idsubproceso);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(procesosTemp, idsubproceso);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(procesosTemp.slice(1));
      } else if (selectedIndex === procesosTemp.length - 1) {
        newSelected = newSelected.concat(procesosTemp.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          procesosTemp.slice(0, selectedIndex),
          procesosTemp.slice(selectedIndex + 1)
        );
      }
      setProcesosTemp(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (idsubproceso) =>
      procesosTemp.indexOf(idsubproceso) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalInfo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir Procesos asociados a la tecnología
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  <Link to="NuevoComponente">
                    <Button
                      className="botonPositivo"
                      style={{ marginTop: "1%", width: "100%" }}
                    >
                      {" "}
                      Nuevo{" "}
                    </Button>
                  </Link>
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => {
                      completarTabla(procesosTemp, "procesos");
                    }}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHeadProcesos
                    classes={classes}
                    numSelected={procesosTemp.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalInfo.length}
                  />
                  <TableBody>
                    {stableSort(modalInfo, getComparator(order, orderBy))
                      .filter((row) =>
                        row.idsubproceso
                          .toLowerCase()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idsubproceso);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(event, row.idsubproceso)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idsubproceso}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.idsubproceso}
                            </TableCell>
                            <TableCell align="left">
                              {row.macroproceso}
                            </TableCell>
                            <TableCell align="left">{row.proceso}</TableCell>
                            <TableCell align="left">{row.subproceso}</TableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                inputProps={{ "aria-label": "Checkbox A" }}
                                checked={row.estado}
                              />
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={modalInfo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Filas por página"}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  ///////// Componentes
  function MyVerticallyCenteredModal(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);
        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = contTecno.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contTecno, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contTecno.slice(1));
      } else if (selectedIndex === contTecno.length - 1) {
        newSelected = newSelected.concat(contTecno.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contTecno.slice(0, selectedIndex),
          contTecno.slice(selectedIndex + 1)
        );
      }
      setContTecno(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalCompo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir elementos tecnológicos asociados a la tecnología
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  <Link to="NuevoComponente">
                    <Button
                      className="botonPositivo"
                      onClick={(e) => crear_com(e)}
                      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                      style={{ marginTop: "1%", width: "100%" }}
                    >
                      Crear
                    </Button>
                  </Link>
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => {
                      completarTabla(contTecno, "detalles");
                      setDireccion(false);
                    }}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalCompo.length}
                  />
                  <TableBody>
                    {stableSort(modalCompo, getComparator(order, orderBy))
                      .filter((row) =>
                        row.idactivo
                          .toString()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idactivo);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.idactivo,
                                row.idtipo_activo,
                                row
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idactivo}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.idactivo}
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.nombre}
                            </TableCell>
                            <TableCell align="right">
                              {row.descripcion}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={modalCompo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Filas por página"}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  //TODO: completar función para encontrar con ID obeto y llenar tabla
  const completarTabla = (obj, id) => {
    let temp = [];

    switch (id) {
      case "procesos":
        setProcesosSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(
            modalInfo.filter((dato) => dato.idsubproceso === obj[i])[0]
          );
        }
        setRowsP(temp);
        setModalShowProcesos(false);
        break;
      case "detalles":
        setComponentesSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalCompo.filter((dato) => dato.idactivo === obj[i])[0]);
        }
        setRowsC(temp);
        setModalShow(false);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
      <MyVerticallyCenteredModal
        componentes={componentesSelected}
        show={modalShow}
        onHide={() => {
          setDireccion(false);
          setModalShow(false);
        }}
      />
      <MyVerticallyCenteredModalProcesos
        procesos={procesosSelected}
        show={modalShowProcesos}
        onHide={() => setModalShowProcesos(false)}
      />

      <Row>
        <Col>
          {" "}
          <h2 className="subtitulo">Nueva Tecnología Emergente</h2>
        </Col>
      </Row>
      <hr />
      <Form
        id="formData"
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Id Tecnología Emergente</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              disabled
              className="form-control text-center"
              placeholder="ID Automático"
              id="IDactivo"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">
              Nombre Tecnología Emergente*
            </label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nombre de la Tecnología Emergente"
              required
              id="NombreActivo"
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Descripción</label>
          </Col>
          <Col sm={8} xs={12}>
            <textarea
              className="form-control text-center"
              placeholder="Descripción del dominio de información"
              rows="3"
              id="Descripcion"
            ></textarea>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">
              Datos contenidos en el dominio
            </label>
          </Col>
          <Col sm={8} xs={12}>
            <div className="input-tag" placeholder="Datos separados...">
              <ul className="input-tag__tags">
                {datContenidos.map((tag, i) => (
                  <li key={tag}>
                    {tag}
                    <button
                      type="button"
                      onClick={() => {
                        removeTag(i);
                      }}
                    >
                      +
                    </button>
                  </li>
                ))}
                <li className="input-tag__tags__input">
                  <input
                    id="datosSeparados"
                    type="text"
                    onKeyDown={(e) => {
                      inputKeyDown(e);
                    }}
                  />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="mb-3 ">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Compañía*</label>
          </Col>
          <Col sm={8} xs={10}>
            {/* <select className="form-control" required id="Compania">
              <option value="">Seleccione la compañía...</option>
              {companiaDat.map((compa) => (
                <option key={compa.idcompania} value={compa.idcompania}>
                  {compa.compania}
                </option>
              ))}
            </select> */}
            <Select
              placeholder={"Seleccione la compañía..."}
              styles={stylesSelect}
              options={companiaDat}
              onChange={onChangeCompania}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione una compañía.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">
              Responsable en tecnología*
            </label>
          </Col>
          <Col sm={8} xs={10}>
            {/* <select className="form-control" required id="responsableTI">
              <option value="">Seleccione responsable</option>
              {usuariosDatTI.map((usuario) => (
                <option key={usuario.idusuario} value={usuario.idposicion}>
                  {usuario.nombre}
                </option>
              ))}
            </select> */}
            <Select
              onChange={handleChangeTI}
              options={usuariosDatTI}
              placeholder={"Seleccione el responsable..."}
              styles={stylesSelect}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un responsable.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Responsable del negocio*</label>
          </Col>
          <Col sm={8} xs={10}>
            {/* <select className="form-control" required id="ResponsableNeg">
              <option value="">Seleccione responsable</option>
              {usuariosDatNeg.map((usuario) => (
                <option key={usuario.idusuario} value={usuario.idposicion}>
                  {usuario.nombre}
                </option>
              ))}
            </select> */}
            <Select
              onChange={handleChangeNeg}
              options={usuariosDatNeg}
              placeholder={"Seleccione el responsable..."}
              styles={stylesSelect}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un responsable.
            </Form.Control.Feedback>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={4} xs={4}>
            <label className="label forn-label">Estado</label>
          </Col>
          <Col>
            <FormControlLabel
              id="switch"
              control={<Switch checked={idState} />}
              label={state}
              onChange={handleChangeState}
              name="Estado"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={0}></Col>
          <Col>
            <div className="form-text">* Campos obligatorios</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={1}></Col>
          <Col sm={3} xs={3}>
            {" "}
            <Button type="submit" className="botonPositivo" id="send">
              Guardar
            </Button>
          </Col>
          <Col sm={3} xs={3}>
            {" "}
            <Link to="tecnologia-emergente">
              <Button className="botonNegativo">Descartar</Button>
            </Link>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <br />
          </Col>
        </Row>
        <hr />
        <Row className="mb-3">
          <Col md={10}>
            <h3 className="subtitulo">
              Componentes tecnológicos asociados a la tecnología
            </h3>
          </Col>
          <Col md={2}>
            <Button
              className="botonIngreso"
              onClick={() => {
                setModalShow(true);
                setDireccion(true);
              }}
              style={{ width: "100%" }}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">ID</StyledTableCell>
                  <StyledTableCell align="left">Nombre</StyledTableCell>
                  <StyledTableCell align="left">Descripción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsc.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.idactivo}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.nombre}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.descripcion}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        <Row className="mb-3">
          <Col md={10}>
            <h3 className="subtitulo">Procesos asociados a la tecnología ​</h3>
          </Col>
          <Col md={2}>
            <Button
              className="botonIngreso"
              onClick={() => setModalShowProcesos(true)}
              style={{ width: "100%" }}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID Proceso</StyledTableCell>
                  <StyledTableCell align="left">
                    Nombre del Proceso
                  </StyledTableCell>
                  <StyledTableCell align="left">Macroproceso</StyledTableCell>
                  <StyledTableCell align="left">Subproceso</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsP.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.idsubproceso}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.proceso}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.macroproceso}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.subproceso}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        {/* <Row className="mb-3">
          <Col md={10}>
              <h3>Canales Asociados al activo</h3>
          </Col>
          <Col md={2}>
            <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
          </Col>
        </Row>
        <Row  className="mb-3">
        <TableContainer component={Paper} className="table table-bordered">
      <Table className={classes.table} aria-label="customized table" >
        <TableHead>
          <TableRow>
            <StyledTableCell>ID Canal</StyledTableCell>
            <StyledTableCell align="right">Nombre del Canal</StyledTableCell>
            <StyledTableCell align="right">Estado del canal</StyledTableCell>
            <StyledTableCell align="right">Campo 1</StyledTableCell>
            <StyledTableCell align="right">Campo 2</StyledTableCell>
            <StyledTableCell align="right">Fecha de acción</StyledTableCell>
            <StyledTableCell align="right">Estado de asociación</StyledTableCell>
            <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows1.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.encabezado1}
              </StyledTableCell>
              <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Row>
        <Row className="mb-3">
          <Col md={10}>
              <h3>Productos Asociados al activo</h3>
          </Col>
          <Col md={2}>
            <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
          </Col>
        </Row>
        <Row  className="mb-3">
        <TableContainer component={Paper} className="table table-bordered">
      <Table className={classes.table} aria-label="customized table" >
        <TableHead>
          <TableRow>
            <StyledTableCell>ID Producto</StyledTableCell>
            <StyledTableCell align="right">Nombre del Producto</StyledTableCell>
            <StyledTableCell align="right">Estado del Producto</StyledTableCell>
            <StyledTableCell align="right">Campo 1</StyledTableCell>
            <StyledTableCell align="right">Campo 2</StyledTableCell>
            <StyledTableCell align="right">Fecha de acción</StyledTableCell>
            <StyledTableCell align="right">Estado de asociación</StyledTableCell>
            <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows2.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.encabezado1}
              </StyledTableCell>
              <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Row>
        <Row className="mb-3">
          <Col md={10}>
              <h3>Usuarios interesados</h3>
          </Col>
          <Col md={2}>
            <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
          </Col>
        </Row>
        <Row  className="mb-3">
        <TableContainer component={Paper} className="table table-bordered">
      <Table className={classes.table} aria-label="customized table" >
        <TableHead>
          <TableRow>
            <StyledTableCell>ID Usuario</StyledTableCell>
            <StyledTableCell align="right">Nombre del Usuario</StyledTableCell>
            <StyledTableCell align="right">Tipo Interesado</StyledTableCell>
            <StyledTableCell align="right">Alcance de interes</StyledTableCell>
            <StyledTableCell align="right">Campo 2</StyledTableCell>
            <StyledTableCell align="right">Fecha de acción</StyledTableCell>
            <StyledTableCell align="right">Estado de asociación</StyledTableCell>
            <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows3.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.encabezado1}
              </StyledTableCell>
              <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
              <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Row>      */}
      </Form>
    </>
  );
};

export default NuevaTecnologia;
