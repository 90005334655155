import React from 'react'

const HomePage = () => (
    <div>
        <h1>Bienvenidos</h1>
    </div>
    
);


export default HomePage;