import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, Row, Col, Form } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "../index.css";
import AADService from "../funcionesAuth.js";
import CheckRole from "../CheckRole";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "idPlanAccion",
    numeric: false,
    disablePadding: true,
    label: "Id Plan Acción",
  },
  {
    id: "nombre",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "estado",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "responsable",
    numeric: false,
    disablePadding: false,
    label: "Responsable",
  },
  {
    id: "analistaRiesgo",
    numeric: false,
    disablePadding: false,
    label: "Analista de Riesgo",
  },
  {
    id: "fechaCompromisoActual",
    numeric: false,
    disablePadding: false,
    label: "Fecha Compromiso Actual",
  },
  {
    id: "ultimoSeguimiento",
    numeric: false,
    disablePadding: false,
    label: "Ultimo Seguimiento",
  },
  {
    id: "porcentajeAvance",
    numeric: false,
    disablePadding: false,
    label: "Porcentaje(%) Avance",
  },
  {
    id: "comentarios",
    numeric: false,
    disablePadding: false,
    label: "Comentarios",
  },
];

/*Encabezado de tabla*/
function EnhancedTableHead(props) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="texto"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

/*Número de tablas seleccionadas y eliminar (parte de arriba de la tabla)*/
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return <Toolbar></Toolbar>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "55vh",
    minHeight: "55vh",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
/*Mapeado de la tabla completa */
export default function EnhancedTable({ params }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [queryText, setqueryText] = React.useState("");
  const [planaccion, setPlanAccion] = React.useState([]);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [nameCompa, setNameCompa] = React.useState("");
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  //const AzureADService = new AADService();
  //const token = AzureADService.getToken();
  const check = new CheckRole("/planes-de-accion");

  useEffect(() => {
    const fetchdata_PA = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "planesdeAccion/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setPlanAccion(data);
    };

    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      setNameCompa(datCompania);
    };
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "usuarios/menu/" + localStorage.getItem("usuario") + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            /*Authorization: "Bearer " + token,*/
          },
        }
      );
      let usuario = await result.json();
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      // console.log("Entramos en el Check: ", opciones);
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        fetchdata_PA();
        obtenerCompañias();
      }
    };
    fetchUsuario();
  }, []);

  function bCompania(id) {
    for (const clave in nameCompa) {
      if (id == nameCompa[clave]["idcompania"]) {
        return nameCompa[clave]["compania"];
      }
    }
    return "No Existe";
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id, idcompania) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const editar = (event) => {
    localStorage.setItem("idplanaccion", selected);
  };
  function add_pa() {
    localStorage.setItem("idplanaccion", 0);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, planaccion.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Row>
        <Col>
          <h2 className="titulo">Planes de Acción</h2>
        </Col>
      </Row>
      <Row style={{ marginTop: "1%", marginBottom: "0.5%" }}>
        <Col sm={3} xs={12}>
          <Form className="buscar">
            <Form.Control
              className="text-center "
              type="text"
              placeholder="Buscar"
              onChange={(e) => setqueryText(e.target.value)}
              style={{ align: "center" }}
            />
          </Form>
        </Col>
        <Col style={{ paddingTop: "0.3%" }} sm={3} xs={6}>
          <Link to="NuevoPlanAccion">
            {check.checkPermits(opcionesGenerales, "POST") ? (
              <Button className="botonPositivo" onClick={() => add_pa()}>
                {" "}
                Nuevo{" "}
              </Button>
            ) : (
              ""
            )}
          </Link>
        </Col>
        <Col style={{ paddingTop: "0.3%" }} sm={3} xs={6}>
          <Link to="EditarPlanAccion">
            {check.checkPermits(opcionesGenerales, "PUT") ? (
              <Button
                variant="warning"
                className="botonGeneral"
                style={{ display: ButtonEdit ? "inline" : "none" }}
                onClick={(event) => editar(event)}
              >
                {" "}
                Editar{" "}
              </Button>
            ) : (
              ""
            )}
          </Link>
        </Col>
      </Row>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} />*/}
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={planaccion.length}
            />
            <TableBody>
              {stableSort(planaccion, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter((row) =>
                  row.nombre.toLowerCase().includes(queryText.toLowerCase())
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row.idplanaccion);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      onClick={(event) => handleClick(event, row.idplanaccion)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.idplanaccion}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.idplanaccion}
                      </TableCell>
                      <TableCell align="left">{row.nombre}</TableCell>
                      <TableCell align="left">{row.estadopa}</TableCell>
                      <TableCell align="left">{row.responsablepa}</TableCell>
                      <TableCell align="left">{row.analistariesgos}</TableCell>
                      <TableCell align="left">
                        {row.fechacompromisoactual}
                      </TableCell>
                      <TableCell align="left">
                        {row.fechamodificacionnovedad}
                      </TableCell>
                      <TableCell align="left">{parseFloat(row.porcentajeavance * 100).toFixed(2)}</TableCell>
                      <TableCell align="left">
                        {row.descripcionnovedad}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Row>
          <Col sm={4} xs={4}>
            <FormControlLabel
              style={{ paddingLeft: "10px" }}
              control={<Switch />}
              label="Mostrar Elementos Inactivos"
            />
          </Col>
          <Col xs={8}>
            {" "}
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={planaccion.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Filas por página"}
            />
          </Col>
        </Row>
      </Paper>
    </div>
  );
}
