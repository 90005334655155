import { Container, Hidden } from "@mui/material";
import React, { useState, useEffect, Component, useContext } from "react";
import Sidebar from "./SideBar";
import { Button, Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  HddStack,
  List,
  Grid3x3Gap,
  HddStackFill,
  HouseDoorFill,
} from "react-bootstrap-icons";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { without } from "lodash";
import NavDropDown from "./NavDropDown";
import HomePage from "./HomePage";
import Estructuras from "./Estructuras";
import NuevosActivos from "./activos/NuevosActivos.js";
import EnhancedTable from "./Evaluaciones/Mtable_Evaluaciones.js";
import Riesgos from "./Evaluaciones/Riesgos";
import Frecuencia from "./Evaluaciones/Frecuencia";
import ActivosInfo from "./activos/Mtable_ActivosInformacion.js";

import Nueva_evaluacion from "./Evaluaciones/Nueva_evaluacion.js";
import Proveedores from "./proveedores/Mtable_Proveedores.js";
import Nuevo_proveedor from "./proveedores/Nuevo_proveedor.js";
import Cuestionario from "./Evaluaciones/Cuestionario.js";
import Vul_no_tec from "./Evaluaciones/Vulnerabilidades/Vul_no_tec.js";
import Controles from "./Evaluaciones/ControlesEva.js";
import componentes from "./componentes/Mtable_Componentes.js";
import nuevoComponente from "./componentes/NuevoComponente.js";
import proyectos from "./proyectos/Proyectos.js";
import nuevo_proy from "./proyectos/Nuevo_proyecto.js";
import tecnologias from "./tecnologia/Mtable_Tecnologias.js";
import nueva_tec from "./tecnologia/Nueva_tecnologia.js";

import "./index.css";
import logo from "./main_icon.png";
import EditarActivo from "./activos/EditarActivo.js";
import EditarComponente from "./componentes/EditarComponente.js";
import EditarProveedor from "./proveedores/EditarProveedor.js";
import EditarTecnologia from "./tecnologia/EditarTecnologia";
import EditarProyecto from "./proyectos/EditarProyecto";
import Vul_tec from "./Evaluaciones/Vulnerabilidades/Vul_tec";
import Descripcion_comp from "./Evaluaciones/descripcion_comp";
////import AADService from "./funcionesAuth.js";
import PlanesAccion from "./planesAccion/PlanAccion";
import NuevoPlanAccion from "./planesAccion/NuevoPlanAccion";
import EditarPlanAccion from "./planesAccion/EditarPlanAccion";
import Desiciones from "./decisiones/Decisiones";
import EditarDecisiones from "./decisiones/EditarDecisiones";
import NuevaDecisiones from "./decisiones/NuevaDecisiones";
import Regulatorio from "./Administracion/Regulatorio.js";
import CheckRole from "./CheckRole";
import { Check } from "@mui/icons-material";

localStorage.setItem("variable_tipo_activo", 0);
localStorage.setItem("idevaluacion", 0);
class Menu extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.state = {
      count: true,
      parametros: [],
      idrol: 0,
      rol: "",
      id_compania_ppal: 0,
      nombre: "",
      nombre_compania_ppal: "",
      companias: [],
      tipo_activo: 0,
      orden_menu: localStorage.getItem("idevaluacion"),
    };
    //this.AzureADService = new AADService();
    //this.token = this.AzureADService.getToken();
  }
  handler() {
    this.setState({
      orden_menu: localStorage.getItem("idevaluacion"),
    });
  }
  componentDidMount() {
    /*
    console.log(this.token);
    if (this.token == null) {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    } else {
      */
    const fetchdata = async () => {
      let correo = this.props.login;
      correo = correo + "@bancolombia.com.co";
      localStorage.setItem("usuario", correo);
      const result = await fetch(
        process.env.REACT_APP_API_URL + "usuarios/menu/" + correo + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            /*Authorization: "Bearer " + this.token,*/
          },
        }
      );

      let param = await result.json();
      var arr = [];
      let nombre_rol = "";
      let id_rol = 0;
      let id_posicion = 0;
      let jeepAutos = [];
      Object.keys(param).forEach(function (key) {
        if (param[key].padre === 0 || param[key].padre === 12)
          jeepAutos.push(param[key]);
      });
      Object.keys(jeepAutos).forEach(function (key) {
        Object.keys(param).forEach(function (llave) {
          if (jeepAutos[key].idopcion === param[llave].padre) {
            arr.push(param[llave]);
          }
        });
        jeepAutos[key].items = arr;
        arr = [];
      });
      id_rol = jeepAutos[0].idrol;
      nombre_rol = jeepAutos[0].nombre_rol;
      id_posicion = jeepAutos[0].idposicion;
      let id_compania = jeepAutos[0].idcompania;
      let nombre = jeepAutos[0].nombre;
      localStorage.setItem("idusuario", jeepAutos[0].idusuario);
      localStorage.setItem("rolusuario", id_rol);
      localStorage.setItem("idposicion", id_posicion);
      this.setState({
        parametros: jeepAutos,
        idrol: id_rol,
        rol: nombre_rol,
        id_compania_ppal: id_compania,
        nombre: nombre,
      });
    };
    fetchdata();
    /*
    const fetchcompanies = async () => {
      await fetchdata();
      const result = await fetch("general/compania/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          //Authorization: "Bearer " + this.token,
        },
      });
      let companias = await result.json();
      
    
      localStorage.setItem("idcompania", this.state.id_compania_ppal); // guarda el id de la copañía que hace parte del usuario
      let self = this;
      Object.keys(companias).forEach(function (key) {
        if (companias[key]["idcompania"] === id_compania) {
          let nombre_compania = companias[key]["compania"];
          let temp = companias;
          temp = without(temp, companias[key]);
          self.setState({
            nombre_compania_ppal: nombre_compania,
            companias: temp,
            //id_compania_ppal

          });
        }
      });
    };
    fetchdata();
    fetchcompanies();
     */
  }

  toggleMenu() {
    this.setState({ count: !this.state.count });
    if (this.state.count === false) {
      document.getElementById("contenedor_sidenav").style.width = "0";
      document.getElementById("contenedor_paginas").style.marginLeft = "0";
    } else {
      document.getElementById("contenedor_sidenav").style.width = "240px";
      document.getElementById("contenedor_paginas").style.marginLeft = "240px";
    }
  }
  componentDidUpdate() {
    /* if (this.state.count === false) {
      document.getElementById("contenedor_sidenav").style.display = "none";
    } else {
      document.getElementById("contenedor_sidenav").style.display = "inline";
    } */
  }
  render() {
    return (
      <div id="principal">
        <Router>
          <Row id="menu_titulo">
            <Col xs={12} style={{ paddingRight: "0px" }}>
              <nav className="navbar navbar-dark" id="nav_header">
                <Button
                  variant="#ffffff"
                  size="lg"
                  onClick={() => this.toggleMenu()}
                >
                  <List />
                </Button>
                <Link to="menu">
                  <Button
                    variant="#787878"
                    size="lg"
                    onClick={() => {
                      this.setState({ orden_menu: 0 });
                      localStorage.setItem("idevaluacion", 0);
                    }}
                  >
                    <HouseDoorFill />
                  </Button>
                </Link>
                <Navbar.Brand></Navbar.Brand>
                <Navbar.Brand className="titulo" href="#home">
                  <img src={logo} className="logonav" />
                  GRC Riesgos tecnológicos y cibernéticos
                </Navbar.Brand>
                <Nav className="mr-auto">
                  <Nav.Link href="#home"></Nav.Link>
                </Nav>
                <label className="texto">{this.state.nombre}</label>
                <label className="texto">{this.state.rol}</label>
                <NavDropDown
                  className="texto"
                  rol={this.state.rol}
                  compania={this.state.nombre_compania_ppal}
                  companias={this.state.companias}
                  id_compania_ppal={this.state.id_compania_ppal}
                />
              </nav>
            </Col>
          </Row>
          <div id="contenedor_principal">
            {/* <Row className="nav-margen" style={{ marginRight: "0px", marginLeft: "0px" }}> */}
            <div id="contenedor_sidenav">
              <Sidebar
                items={this.state.parametros}
                orden_menu={this.state.orden_menu}
              />
            </div>
            <div id="contenedor_paginas">
              <Route path="/" component={HomePage} exact />
              {this.state.idrol == 1 ? (
                <Route path="/regulatorio" component={Regulatorio} exact />
              ) : (
                ""
              )}
              {this.state.idrol == 1 || this.state.idrol == 2 ? (
                <>
                  <Route path="/estructuras" component={Estructuras} exact />
                  <Route
                    path="/dominio-de-informacion"
                    component={ActivosInfo}
                    exact
                  />
                  <Route path="/NuevoActivo" component={NuevosActivos} exact />
                  <Route path="/EditarActivo" component={EditarActivo} exact />
                  <Route
                    path="/componente-tecnologico"
                    component={componentes}
                    exact
                  />
                  <Route
                    path="/NuevoComponente"
                    component={nuevoComponente}
                    exact
                  />
                  <Route
                    path="/EditarComponente"
                    component={EditarComponente}
                    exact
                  />
                  <Route path="/proyecto" component={proyectos} exact />
                  <Route path="/NuevoProyecto" component={nuevo_proy} exact />
                  <Route
                    path="/EditarProyecto"
                    component={EditarProyecto}
                    exact
                  />
                  <Route
                    path="/tecnologia-emergente"
                    component={tecnologias}
                    exact
                  />
                  <Route path="/NuevaTecnologia" component={nueva_tec} exact />
                  <Route
                    path="/EditarTecnologia"
                    component={EditarTecnologia}
                    exact
                  />
                  <Route path="/proveedores" component={Proveedores} exact />
                  <Route path="/nuevo_prov" component={Nuevo_proveedor} exact />
                  <Route
                    path="/EditarProveedor"
                    component={EditarProveedor}
                    exact
                  />
                </>
              ) : (
                ""
              )}

              {this.state.idrol == 1 ||
              this.state.idrol == 2 ||
              this.state.idrol == 5 ||
              this.state.idrol == 3 ? (
                <>
                  <Route
                    path="/evaluaciones"
                    render={(props) => <EnhancedTable action={this.handler} />}
                    exact
                  />
                  <Route
                    path="/nueva_eval"
                    render={(props) => (
                      <Nueva_evaluacion action={this.handler} />
                    )}
                    exact
                  />
                  <Route
                    path="/detalle-evaluacion"
                    render={(props) => (
                      <Nueva_evaluacion action={this.handler} />
                    )}
                    exact
                  />
                  <Route
                    path="/descripcion-de-componentes"
                    component={Descripcion_comp}
                    exact
                  />
                  <Route
                    path="/evaluacion-riesgo"
                    component={Riesgos}
                    items={this.state.parametros}
                    exact
                  />
                  <Route
                    path="/frecuencia-riesgos"
                    component={Frecuencia}
                    exact
                  />
                  <Route
                    path="/evaluacion-cuestionario-de-riesgos"
                    render={(props) => <Cuestionario />}
                    exact
                  />
                  <Route
                    path="/evaluacion-vulnerabilidades-no-tecnicas"
                    component={Vul_no_tec}
                    exact
                  />
                  <Route
                    path="/evaluacion-vulnerabilidades-tecnicas"
                    component={Vul_tec}
                    exact
                  />
                  <Route
                    path="/controles-evaluacion"
                    component={Controles}
                    exact
                  />
                </>
              ) : (
                ""
              )}
              {this.state.idrol == 1 ||
              this.state.idrol == 2 ||
              this.state.idrol == 5 ||
              this.state.idrol == 3 ||
              this.state.idrol == 3 ? (
                <>
                  <Route
                    path="/planes-de-accion"
                    component={PlanesAccion}
                    exact
                  />
                  <Route
                    path="/NuevoPlanAccion"
                    component={NuevoPlanAccion}
                    exact
                  />
                  <Route
                    path="/EditarPlanAccion"
                    component={EditarPlanAccion}
                    exact
                  />
                  <Route path="/decisiones" component={Desiciones} exact />
                  <Route
                    path="/EditarDecisiones"
                    component={EditarDecisiones}
                    exact
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default Menu;
