import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const adalConfig = {
  tenant: "94ff8a7f-9b6f-4a30-b3ff-e2cc8f362139",
  clientId: "17877a95-366a-47cb-9fc0-8df9cb60d7fb",
  endpoints: {    
    api:'8669bee7-9764-488f-a423-aed26ef5a808'  
  },  
  'apiUrl': 'https://backend-ciber-riesgos.com/',
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
