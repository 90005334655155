import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import filter from "lodash";
//import AADService from "./funcionesAuth.js";
//Librerias de la tabla
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, StepButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';

//Finaliza las librerias de la tabla
function AlertDismissibleEditar({ alerta }) {
  if (alerta == false) {
    return null;
  } else if (alerta == true) {
    return (
      <Alert variant="warning">
        Solo puede tener un activo seleccionado para editar.
      </Alert>
    );
  }
}
function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}
//Componentes header
const headCells = [
  { id: "ID", numeric: false, disablePadding: false, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },
  {
    id: "descripcion",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
  },
  {
    id: "tipo_comp",
    numeric: false,
    disablePadding: false,
    label: "Tipo Componente",
  },
];
//headers para la tabla de la descripción de los componentes
const headCellsT = [
  { id: "Id", align: "left", disablePadding: false, label: "Id Componente" },
  {
    id: "contenedor",
    align: "left",
    disablePadding: false,
    label: "Contenedor",
  },
  { id: "nombre", align: "left", disablePadding: false, label: "Nombre" },
  { id: "ip", align: "center", disablePadding: true, label: "IP" },
  {
    id: "descripcion",
    align: "left",
    disablePadding: true,
    label: "Descripción",
  },
  {
    id: "ubicacion_logica",
    align: "left",
    disablePadding: false,
    label: "Ubicación lógica",
  },
  {
    id: "relevancia",
    align: "left",
    disablePadding: false,
    label: "Relevancia",
  },
  {
    id: "tipo_ambiente",
    align: "left",
    disablePadding: true,
    label: "Tipo Ambiente",
  },
];

//////////////////////Tabla dentro del Modal
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "70vh",
    minHeight: "70vh",
  },
  root: {
    width: "100%",
  },
  paper: {
    backgroundColor: "white",
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "35vh",
    minHeight: "35vh",
  },
}));

//Grid que lista los componentes asociados
const Descripcion_comp = (props) => {
  const classes = useStyles();
  const [modalShowComponentes, setModalShowComponentes] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");

  const [componentes, setComponentes] = React.useState([]);
  const [idComponentes, setIdComponentes] = React.useState([]);
  const [componentesTemp, setComponentesTemp] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  const [rowsCompInit, setRowCompInit] = React.useState([]);
  const [activo, setActivo] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  //Constante para la tabla modal
  const [compoPrin, setCompoPrin] = React.useState([]);
  const [compoInf, setCompoInf] = React.useState([]);
  const [modalCompo, setModalCompo] = React.useState([]);
  const [componentesGuardar, setComponentesGuardar] = React.useState([]);
  const [componentesAsociados, setComponentesAsociados] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  
  ////const AzureADService = new AADService();
  ////const token = AzureADService.getToken();

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    const fetchComponentesAsociados = async () => {
      setLoading(true);
      const resultAct = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let param = await resultAct.json();
      let evaTemp = localStorage.getItem("idevaluacion");
      let activo_evaluacion = 0;
      setLoading(false);
      for (let i = 0; i < param.length; i++) {
        if (evaTemp == param[i].idevaluacion) {
          activo_evaluacion = param[i].idactivo;
          break;
        }
      }
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activoInformacion/" +
          activo_evaluacion +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let activoParam = await result.json();
      let componentes_asociados = activoParam.ComponentesInfraestructura;
      let componentesId = [];
      if (activoParam.codigogeneral === "ACI") {
        componentes_asociados = activoParam.componente_principal;
      }
      setComponentes(componentes_asociados);
      setComponentesAsociados(componentes_asociados);
      if (componentes_asociados != null) {
        for (let i = 0; i < componentes_asociados.length; i++) {
          componentesId.push(componentes_asociados[i].idactivo);
        }
      }
      setIdComponentes(componentesId);
      setRowCompInit(componentes_asociados);
      setActivo(activoParam);

      let temp = [];
      let tempSelected = [];
      let element;
      if (componentes_asociados !== null) {
        for (let i = 0; i < componentes_asociados.length; i++) {
          element = componentes_asociados[i];
          if (element.estadoasociacion == true) {
            temp.push(element);
            tempSelected.push(element.idactivo);
          }
        }
      }
      setComponentesTemp(temp);
      setComponentesSelected(tempSelected);

      const resultComponentes = await fetch(
        process.env.REACT_APP_API_URL +
          "activosTipo/10/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datComponentes = await resultComponentes.json();
      let datComponentesPrin = datComponentes.filter(
        (dato) => dato.componente_principal === true
      );

      let datComponentesInf = datComponentes.filter(
        (dato) => dato.componente_principal !== true
      );

      if (activoParam.codigogeneral === "ACI") {
        setModalCompo(datComponentesPrin);
      } else {
        setModalCompo(datComponentesInf);
      }
      // console.log("datComponentesInf", datComponentesInf)
      // if (principal) {
      //   setModalCompo(datComponentes);
      //   console.log("entro por el if de datComponentes ")
      // } else {
      //   setModalCompo(datComponentesInf);
      //   console.log("entro por el if de datComponentesInf ")
      // }
    };
    setLoading(false);
    fetchComponentesAsociados();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sendData = (event) => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    setLoading(true);
    let activoeliminar = [];
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let componentes_asociados_temp = [];
      let nuevos = componentesTemp;
      let idcdc;

      //Validaciones sobre si un componente tiene idcomponentedelcomponente y si tiene estadoasociacion
      if (componentesGuardar !== null) {
        for (let i = 0; i < componentesGuardar.length; i++) {
          if (idComponentes.includes(componentesGuardar[i].idactivo)) {
            for (let j = 0; j < componentes.length; j++) {
              if (componentes[j].idactivo == componentesGuardar[i].idactivo) {
                componentesGuardar[i] = componentes[j];
                break;
              }
            }
          }
        }
        for (let i = 0; i < componentesGuardar.length; i++) {
          if (
            componentesGuardar[i].idcomponentedelcomponente == undefined ||
            componentesGuardar[i].idcomponentedelcomponente == null
          ) {
            idcdc = 0;
          } else {
            idcdc = componentesGuardar[i].idcomponentedelcomponente;
          }
          if (
            componentesGuardar[i].estadoasociacion == null ||
            componentesGuardar[i].estadoasociacion == undefined
          ) {
            componentesGuardar[i].estadoasociacion = true;
          }
          for (let j = 0; j < componentesTemp.length; j++) {
            if (componentesTemp[j].idactivo == componentesGuardar[i].idactivo) {
              componentesGuardar[i].estadoasociacion = true;
            }
          }
          componentesGuardar.map((objtemp, id) => {
            activoeliminar = componentesTemp.filter(function (obj) {
              return obj.idactivo == objtemp.idactivo;
            });
            if (activoeliminar.length == 0) {
              componentesGuardar[id].estadoasociacion = false;
            }
          });

          componentes_asociados_temp.push({
            idcomponentedelcomponente: idcdc,
            idactivo: activo.idactivo,
            idactivocomponente: componentesGuardar[i].idactivo,
            fechacreacion: componentesGuardar[i].fechacreacion,
            estadoasociacion: componentesGuardar[i].estadoasociacion,
            idusuariocreacion: componentesGuardar[i].idusuariocreacion,
            fechamodificacion: componentesGuardar[i].fechamodificacion,
            idusuariomodificacion: componentesGuardar[i].idusuariomodificacion,
            disp_numerico1: 0,
            disp_numerico2: 0,
            disp_varchar1: 0,
            disp_varchar2: 0,
          });
        }
      }
      var data = JSON.stringify({
        codigogeneral: activo.codigogeneral,
        tipo_activo: activo.tipo_activo,
        estadotipoactivo: activo.estadotipoactivo,
        idactivo: activo.idactivo,
        idcompania: activo.idcompania,
        idtipo_activo: activo.idtipo_activo,
        nombre: activo.nombre,
        idposicionresponsableti: activo.idposicionresponsableti,
        UsuarioResponsableTI: activo.UsuarioResponsableTI,
        idposicionresponsablenegocio: activo.idposicionresponsablenegocio,
        UsuarioResponsableNegocio: activo.UsuarioResponsableNegocio,
        descripcion: activo.descripcion,
        datos_en_el_activo: activo.datos_en_el_activo,
        estado: activo.estado,
        bia: activo.bia,
        sox: activo.sox,
        ip: activo.ip,
        dns: activo.dns,
        ubicacion_logica: activo.ubicacion_logica,
        relevancia: activo.relevancia,
        tipo_ambiente: activo.tipo_ambiente,
        elemento_configuracion: activo.elemento_configuracion,
        componente_principal: activo.componente_principal,
        clasificacion_confidencialidad: activo.clasificacion_confidencialidad,
        clasificacion_integridad: activo.clasificacion_integridad,
        clasificacion_disponibilidad: activo.clasificacion_disponibilidad,
        nivel_criticidad: activo.nivel_criticidad,
        pmo: activo.pmo,
        tipo_proyecto: activo.tipo_proyecto,
        fecha_inicio: activo.fecha_inicio,
        fecha_fin: activo.fecha_fin,
        fechacreacion: activo.fechacreacion,
        idusuariocreacion: activo.idusuariocreacion,
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        ComponentesAsociados: activo.ComponentesAsociados
          ? activo.ComponentesAsociados
          : "",
        ProveedoresAsociados: activo.ProveedoresAsociados
          ? activo.ProveedoresAsociados
          : "",
        ProductoServiciosAsociados: activo.ProductoServiciosAsociados
          ? activo.ProductoServiciosAsociados
          : "",
        ProcesosAsociados: activo.ProcesosAsociados
          ? activo.ProcesosAsociados
          : "",
        ComponentesInfraestructura: componentes_asociados_temp,
      });
      fetch(
        process.env.REACT_APP_API_URL +
          "activoInformacion/" +
          activo.idactivo +
          "/",
        {
          method: "PUT",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        }
      )
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
              localStorage.setItem("idactivo", response.idactivo);
            } else if (data.status >= 500) {
              setEstadoPost(5);
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                setEstadoPost(6);
              }
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
            setLoading(false);
          })
        )
        .catch(function (err) {});
    }
  };

  function ModalComponentes(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [contComponentes, setContComponentes] = React.useState(
      props.componentes
    );

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      setComponentesTemp([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = contComponentes.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contComponentes, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contComponentes.slice(1));
      } else if (selectedIndex === contComponentes.length - 1) {
        newSelected = newSelected.concat(contComponentes.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contComponentes.slice(0, selectedIndex),
          contComponentes.slice(selectedIndex + 1)
        );
      }
      setContComponentes(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const isSelected = (id) => contComponentes.indexOf(id) !== -1;

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="label" id="contained-modal-title-vcenter">
            Añadir Componentes asociados
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Row>
              <Col sm={4} xs={2}>
                <Form className="buscar">
                  <Form.Control
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => setqueryText(e.target.value)}
                    style={{ align: "center" }}
                  />
                </Form>
              </Col>
              <Col sm={4} xs={6}></Col>

              <Col sm={4} xs={6}>
                <Button
                  className="botonPositivo"
                  style={{ marginTop: "1%", width: "100%" }}
                  onClick={() => completarTabla(contComponentes, "componentes")}
                >
                  {" "}
                  Guardar{" "}
                </Button>
              </Col>
            </Row>
            <Paper className={classes.paper}>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={componentesTemp.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={modalInfo.length}
                  />
                  <TableBody>
                    {stableSort(modalCompo, getComparator(order, orderBy))
                      .filter((row) =>
                        row.idactivo
                          .toString()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idactivo);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(event, row.idactivo)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idactivo}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.idactivo}
                            </TableCell>
                            <TableCell align="left">{row.nombre}</TableCell>
                            <TableCell align="left">
                              {row.descripcion}
                            </TableCell>
                            <TableCell align="left">
                              {!row.componente_principal
                                ? "Infraestructura"
                                : "Principal"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={modalCompo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  //Funcion que llena la tabla con los nuevos componentes seleccionados
  const completarTabla = (obj, id) => {
    let temp = [];
    let tempReal = [];
    let tempIdInicial = [];
    let tempIdFinal = [];
    let tempTodos = [];
    let tempIdBorrar = [];
    let tempComponentes = componentesTemp;

    if (id == "componentes") {
      for (let i = 0; i < componentesSelected.length; i++) {
        tempIdInicial.push(componentesSelected[i]);
      }

      for (let i = 0; i < modalCompo.length; i++) {
        tempTodos.push(modalCompo[i]);
      }

      setComponentesSelected(obj);

      for (let i = 0; i < obj.length; i++) {
        temp.push(modalCompo.filter((dato) => dato.idactivo === obj[i])[0]);
      }
      for (let i = 0; i < temp.length; i++) {
        tempIdFinal.push(temp[i].idactivo);
      }
      //Ciclo para agregar nuevos componentes
      for (let i = 0; i < tempIdFinal.length; i++) {
        if (!tempIdInicial.includes(tempIdFinal[i])) {
          for (let j = 0; j < temp.length; j++) {
            if (temp[j].idactivo == tempIdFinal[i]) {
              tempReal.push(temp[j]);
            }
          }
        }
      }
      //Ciclo para conocer los componentes por quitar
      for (let i = 0; i < tempIdInicial.length; i++) {
        if (!tempIdFinal.includes(tempIdInicial[i])) {
          for (let j = 0; j < tempTodos.length; j++) {
            if (tempTodos[j].idactivo == tempIdInicial[i]) {
              tempIdBorrar.push(tempTodos[j].idactivo);
            }
          }
        }
      }
      //Ciclos para quitar componentes
      let tempBorrar = tempIdInicial;
      let tempGeneral = [];
      for (let i = 0; i < tempIdBorrar.length; i++) {
        tempBorrar.splice(tempBorrar.indexOf(tempIdBorrar[i]), 1);
      }
      for (let i = 0; i < tempBorrar.length; i++) {
        if (tempIdInicial.includes(tempBorrar[i])) {
          for (let j = 0; j < tempComponentes.length; j++) {
            if (tempComponentes[j].idactivo == tempBorrar[i]) {
              if (
                tempComponentes[j].estadoasociacion == undefined ||
                tempComponentes[j].estadoasociacion == null
              ) {
              } else {
                tempComponentes[j].estadoasociacion = false;
              }
              tempGeneral.push(tempComponentes[j]);
            }
          }
        }
      }
      tempComponentes = tempComponentes.concat(tempReal);
      tempGeneral = tempGeneral.concat(tempReal);
      setComponentesGuardar(tempComponentes);
      setComponentesTemp(tempGeneral);
      setModalShowComponentes(false);
    }
  };

  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
      <ModalComponentes
        componentes={componentesSelected}
        show={modalShowComponentes}
        onHide={() => setModalShowComponentes(false)}
      />
      <Form id="formData" onSubmit={(e) => sendData(e)} noValidate>
        <Row className="mb-3"></Row>
        <Row className="mb-3">
          <Col sm={5}>
            <h2 className="subtitulo">Descripción Componentes</h2>
          </Col>
          <Col sm={1}>
            {loading && <CircularProgressWithLabel value={progress} />}
          </Col>
          <Col sm={3}>
            {localStorage.getItem("rolusuario") == 2 ||
            localStorage.getItem("rolusuario") == 1 ? (
              <Button className="botonPositivo" type="submit" id="send">
                Guardar
              </Button>
            ) : (
              ""
            )}
          </Col>
          <Col sm={3}>
            {localStorage.getItem("rolusuario") == 2 ||
            localStorage.getItem("rolusuario") == 1 ? (
              <Button
                className="botonGeneral"
                onClick={() => setModalShowComponentes(true)}
              >
                Añadir
              </Button>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <hr />
        <Row className="mb-3">
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow
                  style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                >
                  {headCellsT.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align === "center" ? "center" : "left"}
                      style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                    >
                      <label className="label">{headCell.label}</label>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(componentesTemp, getComparator(order, orderBy))
                  .filter((row) => row.nombre.toLowerCase())
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow tabIndex={-1} key={row.idactivo}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="center"
                        >
                          {row.idactivo}
                        </TableCell>
                        <TableCell align="left">{row.componenteppal}</TableCell>
                        <TableCell align="left">{row.nombre}</TableCell>
                        <TableCell align="center">{row.ip}</TableCell>
                        <TableCell align="left">{row.descripcion}</TableCell>
                        <TableCell align="left">
                          {row.ubicacion_logica}
                        </TableCell>
                        <TableCell align="left">{row.relevancia}</TableCell>
                        <TableCell align="left">{row.tipo_ambiente}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={componentesTemp.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Row>
      </Form>
    </>
  );
};

export default Descripcion_comp;
