import React, { useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Accordion,
  Alert,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { uploadFile } from "react-s3";
import Box from "@material-ui/core/Box";
import Loader from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { Checkbox, StepButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import "bootstrap/dist/css/bootstrap.min.css";
import $, { data } from "jquery";
import "../index.css";
import { Input } from "@material-ui/core";
//import AADService from "./funcionesAuth.js";
import * as XLSX from "xlsx";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function AlertDismissibleExample({ alertaPUT }) {
  switch (alertaPUT) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return (
        <Alert variant="warning">
          No Hay Componentes Para Calcular las Frecuencias
        </Alert>
      );
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Error en la categorización de las vulnerabilidades
        </Alert>
      );
      break;
    default:
      return null;
      break;
  }
}
function AlertDismissibleExampleModal({ alertaModal }) {
  switch (alertaModal) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return (
        <Alert variant="success">
          Cargó y Guardó exitosamente los archivos
        </Alert>
      );
      break;
    case 3:
      return (
        <Alert variant="warning">No existe información de Frecuencias.</Alert>
      );
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Error en la categorización de las vulnerabilidades
        </Alert>
      );
      break;
    default:
      return null;
      break;
  }
}

const columns = [
  {
    id: "numEventosActu",
    label: "# Eventos",
    align: "center",
    minWidth: 170,
  },
  {
    id: "numYearsActu",
    label: "# Años",
    align: "center",
    minWidth: 170,
  },
  { id: "numEventosRef", align: "center", label: "# Eventos", minWidth: 170 },
  { id: "numYearsRef", align: "center", label: "# Años", minWidth: 100 },
  {
    id: "numEventosProp",
    label: "# Eventos",
    align: "center",
    minWidth: 170,
  },
  {
    id: "numYearsProp",
    label: "# Años",
    align: "center",
    minWidth: 170,
  },
];

const Frecuencias = () => {
  const [validated, setValidated] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [showModal, setShowModal] = React.useState(false);
  const [frecuencias, setFrecuencias] = React.useState([]);
  const [queryText, setqueryText] = React.useState("");
  const [activoevaluado, setActivoEvaluado] = React.useState([]);
  const [statusRequest, setStatusRequest] = React.useState(0);
  const [tipoCargue, setTipoCargue] = React.useState("Detallado");
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [guardar, setGuardar] = React.useState(true);

  useEffect(() => {
    const fetchFrecuencias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/frecuencia/" +
          localStorage.getItem("idcompania") +
          "/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let frecuencia = "";
      try {
        frecuencia = await result.json();
        console.log("frecuencia", frecuencia);
      } catch {
        frecuencia = [];
        setEstadoPost(3);
        setGuardar(false);
      }
      setFrecuencias(frecuencia);
    };
    const fetchActivo = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activoInformacion/" +
          localStorage.getItem("idactivo") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let activo = await result.json();
      console.log("activo", activo);

      setActivoEvaluado(activo);
    };
    fetchActivo();
    fetchFrecuencias();
  }, []);

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      var temp = {};
      var data = [];
      var frecuSele;
      let eventos;
      let numAnos;
      for (let i = 0; i < frecuencias.length; i++) {
        // console.log(document.getElementById("seleccion" + i));
        frecuSele = document.getElementById("seleccion" + i).value;
        if (frecuSele == "Referencia") {
          numAnos = document.getElementById("anoRef" + i).textContent;
          eventos = document.getElementById("evenRef" + i).textContent;
        } else if (frecuSele == "Propia") {
          numAnos = document.getElementById("anoPro" + i).textContent;
          eventos = document.getElementById("evenPro" + i).textContent;
        } else {
          numAnos = frecuencias[i].numanos;
          eventos = frecuencias[i].numeventos;
        }
        temp = {
          idfrecuenciaevaluacion: frecuencias[i].idfrecuenciaevaluacion,
          idevaluacion: localStorage.getItem("idevaluacion"),
          idtipoincidentecibernetico: frecuencias[i].idtipoincidentecibernetico,
          frecuenciaseleccionada: frecuSele,
          numeventos: eventos,
          numanos: numAnos,
          frecuenciaanual: document.getElementById("frecanual" + i).textContent,
          fechacreacion:
            frecuencias[i].fechacreacion == 0
              ? today.toISOString()
              : frecuencias[i].fechacreacion,
          idusuariocreacion:
            frecuencias[i].idusuariocreacion == 0
              ? parseInt(localStorage.getItem("idusuario"))
              : frecuencias[i].idusuariocreacion,
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: parseInt(localStorage.getItem("idusuario")),
        };
        data.push(temp);
      }
      // console.log(data);
      fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/frecuencia/" +
          localStorage.getItem("idcompania") +
          "/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        }
      ).then((response) =>
        response.json().then((data) => {
          // console.log(response);
          if (response.status >= 200 && response.status < 300) {
            setStatusRequest(2);
            // console.log(data);
          } else if (response.status >= 500) {
            // console.log(response);
            setStatusRequest(5);
          } else if (response.status >= 400 && response.status < 500) {
            setStatusRequest(4);
          }
        })
      );
    }
    setValidated(true);
  };

  const eleFrecuencia = ["Referencia", "Propia"];

  function ModalFrecuencias(props) {
    const [archivo, setArchivo] = React.useState([]);
    const [stateArchivo, setStateArchivo] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [namesN, setNamesN] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [progress, setProgress] = React.useState(false);

    const timeElapsed = Date.now();
    ////const AzureADService = new AADService();
    ////const token = AzureADService.getToken();
    let history = useHistory();
    const today = new Date(timeElapsed);
    let tempArchivos = [];
    let temp = [];
    let temp2 = [];

    const subirArchivo = (e) => {
      setEstadoPost(0);
      setArchivo([]);
      setNames([]);
      setStateArchivo([]);
      setNames([]);
      setNamesN([]);
      let selects = document.getElementsByClassName("selects");
      for (let index = 0; index < selects.length; index++) {
        const element = selects[index];
        element.value = "";
      }

      for (let i = 0; i < e.length; i++) {
        if (
          e[i].name.split(".")[1] == "xlsx" ||
          e[i].name.split(".")[1] == "csv"
        ) {
          temp.push(e[i].name);
          tempArchivos.push(e[i]);
        }
      }
      setArchivo(tempArchivos);
      setNames(temp);
    };
    const renameArchivo = (file) => {
      const formData = new FormData();
      for (let i = 0; i < archivo.length; i++) {
        formData.append("archivo", archivo[i], file[i]);
        formData.append("id", i);
      }
      setArchivo(formData.getAll("archivo"));
    };
    const nuevoN = (i) => {
      let fuente = document.getElementById("fuente" + i).value;
      if (namesN.length !== 0) {
        namesN.map((dat) => temp2.push(dat));
        if (i <= temp2.length) {
          temp2[i] =
            fuente + "_" + today.toISOString() + "." + names[i].split(".")[1];
        } else {
          temp2.push(
            fuente + "_" + today.toISOString() + "." + names[i].split(".")[1]
          );
        }
      } else {
        temp2.push(
          fuente + "_" + today.toISOString() + "." + names[i].split(".")[1]
        );
      }

      setNamesN(temp2);
      renameArchivo(temp2);
    };

    const postArchivo = async (event, file, tipo) => {
      setEstadoPost(0);
      event.preventDefault();
      const form = event.currentTarget;
      let temp = [];
      let archivosKEY = [];
      let hojas = [];
      //let client = new W3CWebSocket('ws://127.0.0.1:8000/ws/' + 'vultec' + '/');
      let reader = new FileReader();
      //let contentBuffer = await readFileAsync(this.state.archivo);
      reader.readAsArrayBuffer(file[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        workbook.SheetNames.forEach(function (sheetName) {
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          // hojas.push({
          //   data: XL_row_object,
          //   sheetName
          // });
          setLoading(true);
          setProgress(true);
          if (tipo == "Detallado") {
            procesarDatosDetallado(namesN[0], XL_row_object);
          } else if (tipo == "Consolidado") {
            procesarDatosConsolidado(namesN[0], XL_row_object);
          }
          // for (let i = 0; i < file.length; i++) {
          //   uploadFile(file[i], config)
          //     .then((data) => {
          //       archivosKEY.push(data["key"]);
          //       setEstadoPost(2);
          //       if (stateArchivo.length !== 0) {
          //         stateArchivo.map((dat) => temp.push(dat));
          //         if (i <= temp.length) {
          //           temp[i] = <FcCheckmark />;
          //         } else {
          //           temp.push(<FcCheckmark />);
          //         }
          //       } else {
          //         temp.push(<FcCheckmark />);
          //       }
          //       // console.log(archivosKEY.length);
          //       if (archivosKEY.length == file.length) {
          //         construirJSON(archivosKEY, temp, tipo);
          //       }
          //     })
          //     .catch((err) => {
          //       console.error(err);
          //       setEstadoPost(4);
          //       if (stateArchivo.length !== 0) {
          //         stateArchivo.map((dat) => temp.push(dat));
          //         if (i <= temp.length) {
          //           temp[i] = <FcCancel />;
          //         } else {
          //           temp.push(<FcCancel />);
          //         }
          //       } else {
          //         temp.push(<FcCancel />);
          //       }
        });
      };
    };
    const construirJSON = async (archivosKEY, temp, tipo) => {
      setStateArchivo(temp);
      setEstadoPost(0);
      if (tipo == "Detallado") {
        procesarDatosDetallado(archivosKEY);
      } else if (tipo == "Consolidado") {
        procesarDatosConsolidado(archivosKEY);
      }
    };

    const procesarDatosDetallado = async (archivosKEY, info) => {
      // console.log("SI VAMOS A GUARDARRRR");
      // console.log(localStorage.getItem("idusuario"));
      // console.log(
      //   localStorage.getItem("idcompania") != null &&
      //     localStorage.getItem("idcompania") != undefined
      //     ? localStorage.getItem("idcompania")
      //     : 1
      // );
      var data = {
        idusuario: localStorage.getItem("idusuario"),
        idcompania:
          localStorage.getItem("idcompania") != null &&
          localStorage.getItem("idcompania") != undefined
            ? localStorage.getItem("idcompania")
            : 1,
        usuario: localStorage.getItem("usuario"),
        key: archivosKEY,
        message: info,
      };
      let datos = JSON.stringify(data);
      fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/frecuencia/detallado/" +
          localStorage.getItem("idcompania") +
          "/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "POST",
          body: datos,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) =>
          response.json().then((data) => {
            // console.log(response);
            if (response.status >= 200 && response.status < 300) {
              setEstadoPost(2);
              //props.onHide();
              const fetchdataNewPost = async () => {
                const result = await fetch(
                  process.env.REACT_APP_API_URL +
                    "evaluacion/frecuencia/" +
                    localStorage.getItem("idcompania") +
                    "/" +
                    localStorage.getItem("idevaluacion") +
                    "/",
                  {
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      //Authorization: "Bearer " + token,
                    },
                  }
                );
                let frecuencia = await result.json();
                setFrecuencias(frecuencia);
              };
              fetchdataNewPost();
            } else if (response.status >= 500) {
              setEstadoPost(6);
              //props.onHide();
            } else if (response.status >= 400 && response.status < 500) {
              setEstadoPost(5);
              //props.onHide();
            }
          })
        )
        .catch((err) => console.log(err));
      setShowModal(false);
      setLoading(false);
    };

    const procesarDatosConsolidado = async (archivosKEY, info) => {
      // console.log("SI VAMOS A GUARDARRRR");
      // console.log(localStorage.getItem("idusuario"));
      // console.log(
      //   localStorage.getItem("idcompania") != null &&
      //     localStorage.getItem("idcompania") != undefined
      //     ? localStorage.getItem("idcompania")
      //     : 1
      // );
      // console.log(localStorage.getItem("usuario"));
      // console.log(localStorage.getItem("idactivo"));
      // console.log(archivosKEY);
      var data = {
        idusuario: localStorage.getItem("idusuario"),
        idcompania:
          localStorage.getItem("idcompania") != null &&
          localStorage.getItem("idcompania") != undefined
            ? localStorage.getItem("idcompania")
            : 1,
        usuario: localStorage.getItem("usuario"),
        idactivo: localStorage.getItem("idactivo"),
        key: archivosKEY,
        message: info,
      };
      let datos = JSON.stringify(data);
      fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/frecuencia/consolidado/" +
          localStorage.getItem("idcompania") +
          "/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "POST",
          body: datos,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        }
      ).then((response) =>
        response.json().then((data) => {
          // console.log(data);
          if (response.status >= 200 && response.status < 300) {
            setEstadoPost(2);
            const fetchdataNewPost = async () => {
              const result = await fetch(
                process.env.REACT_APP_API_URL +
                  "evaluacion/frecuencia/" +
                  localStorage.getItem("idcompania") +
                  "/" +
                  localStorage.getItem("idevaluacion") +
                  "/",
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    //Authorization: "Bearer " + token,
                  },
                }
              );
              let frecuencia = await result.json();
              setFrecuencias(frecuencia);
            };
            fetchdataNewPost();
            //props.onHide();
          } else if (response.status >= 500) {
            setEstadoPost(6);
            //props.onHide();
          } else if (response.status >= 400 && response.status < 500) {
            setEstadoPost(5);
            //props.onHide();
          }
        })
      );
      setShowModal(false);
      setLoading(false);
    };

    if (loading) {
      return (
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          data-keyboard="false"
        >
          <Form onSubmit={(e) => postArchivo(e, archivo, tipoCargue)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Cargar archivos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AlertDismissibleExampleModal alertaModal={estadoPOST} />
              {/* <Loader type="Circles" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/>
            <Loader type="TailSpin" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/> */}
              <Loader
                type="Oval"
                color="#FFBF00"
                style={{ textAlign: "center", position: "static" }}
              />
              {/* 
            <Loader type="Puff" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/>
            <Loader type="Audio" color="#FFBF00" style ={{textAlign:"center" , position: "static"}}/> */}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Form>
        </Modal>
      );
    } else if (!loading) {
      return (
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Form onSubmit={(e) => postArchivo(e, archivo, tipoCargue)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Cargar {tipoCargue} de Eventos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AlertDismissibleExampleModal alertaModal={estadoPOST} />
              <Row className="mb-3">
                <Col>
                  <input
                    type="file"
                    name="files"
                    accept=".xlsx,.csv"
                    multiple
                    onChange={(e) => subirArchivo(e.target.files)}
                  ></input>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <Row>
                        <Col sm={4}>
                          <h5>Nombre del archivo</h5>
                        </Col>
                        <Col sm={3}>
                          <h5>Activo Asociado</h5>
                        </Col>
                        <Col sm={4}>
                          <h5>Nombre en el servidor</h5>
                        </Col>
                        <Col sm={1}>
                          <h5>Estado</h5>
                        </Col>
                      </Row>
                    </li>
                    {names.map((item, index) => (
                      <li className="list-group-item" key={index}>
                        <Row>
                          <Col sm={4}>{item}</Col>
                          <Col sm={3}>
                            <select
                              className="form-control selects"
                              required
                              id={"fuente" + index}
                              onChange={() => nuevoN(index)}
                            >
                              <option value="">
                                Seleccione Activo Asociado
                              </option>
                              <option
                                key={activoevaluado.idactivo}
                                value={activoevaluado.nombre}
                              >
                                {activoevaluado.nombre}
                              </option>
                            </select>
                          </Col>
                          <Col sm={4}>{namesN[index]}</Col>
                          <Col sm={1}>{stateArchivo[index]}</Col>
                        </Row>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {progress && <CircularProgress />}\
              <Button
                type="submit"
                className="botonPositivo"
                style={{ minWidth: "20%" }}
              >
                Cargar
              </Button>
              <Button onClick={props.onHide} className="botonNegativo">
                Cancelar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      );
    }
  }
  const calcFrecuencia = (index) => {
    // console.log(document.getElementById("seleccion" + index).value);
    let seleccion = document.getElementById("seleccion" + index).value;
    let numAnos;
    let numEventos;
    let frecuencia;
    if (seleccion == "Referencia") {
      numAnos = parseFloat(
        document.getElementById("anoRef" + index).textContent
      );
      numEventos = parseFloat(
        document.getElementById("evenRef" + index).textContent
      );
      frecuencia = numEventos / numAnos;
      if (isNaN(frecuencia)) {
        frecuencia = 0.0;
      }
      document.getElementById("frecanual" + index).textContent =
        frecuencia.toFixed(2);
    } else if (seleccion == "Propia") {
      numAnos = parseFloat(
        document.getElementById("anoPro" + index).textContent
      );
      numEventos = parseFloat(
        document.getElementById("evenPro" + index).textContent
      );
      frecuencia = numEventos / numAnos;
      if (isNaN(frecuencia)) {
        frecuencia = 0.0;
      }
      document.getElementById("frecanual" + index).textContent =
        frecuencia.toFixed(2);
    } else {
      document.getElementById("frecanual" + index).textContent = "0.0";
    }
  };
  return (
    <>
      <AlertDismissibleExample alertaPUT={statusRequest} />
      <AlertDismissibleExampleModal alertaModal={estadoPOST} />
      <ModalFrecuencias show={showModal} onHide={() => setShowModal(false)} />
      <Row>
        <Col>
          <h2 className="titulo">Frecuencias de Eventos</h2>
        </Col>
      </Row>
      <Form
        id="formData"
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row style={{ marginTop: "1%", marginBottom: "0.5%" }}>
          <Col sm={3} xs={12}></Col>
          <Col style={{ paddingTop: "0.3%" }} sm={3} xs={6}>
            {localStorage.getItem("rolusuario") == 2 ||
            localStorage.getItem("rolusuario") == 1 ? (
              <Button
                className="botonPositivo"
                onClick={() => {
                  setShowModal(true);
                  setTipoCargue("Detallado");
                }}
              >
                {" "}
                Ingreso Detallado de Eventos{" "}
              </Button>
            ) : (
              ""
            )}
          </Col>
          <Col style={{ paddingTop: "0.3%" }} sm={3} xs={6}>
            {localStorage.getItem("rolusuario") == 2 ||
            localStorage.getItem("rolusuario") == 1 ? (
              <Button
                className="botonGeneral"
                onClick={() => {
                  setShowModal(true);
                  setTipoCargue("Consolidado");
                }}
              >
                Ingreso Consolidado de Eventos
              </Button>
            ) : (
              ""
            )}
          </Col>
          <Col sm={1}></Col>
          {guardar ? (
            <>
              {localStorage.getItem("rolusuario") == 2 ||
              localStorage.getItem("rolusuario") == 1 ? (
                <Col sm={2} xs={6}>
                  <Button className="botonIngreso" type="submit">
                    Guardar{" "}
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Row>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    rowSpan={2}
                    colSpan={1}
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    colSpan={8}
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                  >
                    <label className="label" style={{ margin: "0" }}>
                      Frecuencia
                    </label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                  >
                    <label className="label" style={{ margin: "0" }}>
                      Actual
                    </label>
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                  >
                    <label className="label" style={{ margin: "0" }}>
                      Referencia
                    </label>
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                  >
                    <label className="label" style={{ margin: "0" }}>
                      Propia
                    </label>
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    colSpan={1}
                    align="center"
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                  >
                    <label className="label" style={{ margin: "0" }}>
                      Frecuencia Anualizada
                    </label>
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    colSpan={1}
                    align="center"
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                  >
                    <label className="label" style={{ margin: "0" }}>
                      Seleccionado
                    </label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      minWidth: 170,
                      backgroundColor: "#2c2a29",
                      color: "#ffffff",
                    }}
                  >
                    <label className="label" style={{ margin: "0" }}>
                      Evento
                    </label>
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        top: 57,
                        minWidth: column.minWidth,
                        backgroundColor: "#2c2a29",
                        color: "#ffffff",
                      }}
                    >
                      <label className="texto" style={{ margin: "0" }}>
                        {column.label}
                      </label>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {frecuencias.length > 0 ? (
                  <>
                    {stableSort(frecuencias, getComparator(order, orderBy)).map(
                      (row, index) => {
                        let seleccion = row.frecuenciaseleccionada;
                        let frecuencia;
                        if (seleccion == "Referencia") {
                          frecuencia = (
                            row.numeventosponderado / row.numanos_Ref
                          ).toFixed(2);
                        } else if (seleccion == "Propia") {
                          frecuencia = (
                            row.numeventos_pro / row.numanos_pro
                          ).toFixed(2);
                        } else {
                          frecuencia = 0.0;
                        }
                        return (
                          <TableRow key={index}>
                            <TableCell align={"left"}>{row.nombre}</TableCell>
                            <TableCell align={"center"}>
                              {parseFloat(row.numeventos_Eval).toFixed(2)}
                            </TableCell>
                            <TableCell align={"center"}>
                              {parseFloat(row.numanos_Eval).toFixed(2)}
                            </TableCell>
                            <TableCell id={"evenRef" + index} align={"center"}>
                              {parseFloat(row.numeventosponderado).toFixed(2)}
                            </TableCell>
                            <TableCell id={"anoRef" + index} align={"center"}>
                              {parseFloat(row.numanos_Ref).toFixed(2)}
                            </TableCell>
                            <TableCell id={"evenPro" + index} align={"center"}>
                              {parseFloat(row.numeventos_pro).toFixed(2)}
                            </TableCell>
                            <TableCell id={"anoPro" + index} align={"center"}>
                              {parseFloat(row.numanos_pro).toFixed(2)}
                            </TableCell>
                            {}
                            <TableCell
                              id={"frecanual" + index}
                              align={"center"}
                            >
                              {isNaN(frecuencia)
                                ? 0.0
                                : parseFloat(frecuencia).toFixed(2)}
                            </TableCell>
                            <TableCell>
                              <select
                                className="form-control selects"
                                id={"seleccion" + index}
                                defaultValue={
                                  row.frecuenciaseleccionada != ""
                                    ? row.frecuenciaseleccionada
                                    : ""
                                }
                                onChange={() => {
                                  calcFrecuencia(index);
                                }}
                              >
                                {eleFrecuencia.includes(
                                  row.frecuenciaseleccionada
                                ) ? (
                                  row.frecuenciaseleccionada == "Referencia" ? (
                                    <>
                                      <option value="Referencia">
                                        Referencia
                                      </option>
                                      <option value="Propia">Propia</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="Propia">Propia</option>
                                      <option value="Referencia">
                                        Referencia
                                      </option>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <option value="">
                                      Seleccione Frecuencia
                                    </option>
                                    <option value="Referencia">
                                      Referencia
                                    </option>
                                    <option value="Propia">Propia</option>
                                  </>
                                )}
                              </select>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Form>
    </>
  );
};

export default Frecuencias;
