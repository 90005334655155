import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import CheckRole from "../CheckRole";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

export default function Regulatorio() {
  const [checkFecha, setCheckFecha] = React.useState(false);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const check = new CheckRole("/regulatorio");

  useEffect(() => {
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let usuario = await result.json();
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }

      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
      }
    };
    fetchUsuario();
  }, []);

  const enviarPrisma = () => {
    let usuario = localStorage.getItem("usuario").split("@")[0]; //Esto se debe remover con el directorio activo
    let dato = {
      idusuario: localStorage.getItem("idusuario"),
      usuario: usuario,
      idcompania: localStorage.getItem("idcompania"),
      fechaescaneo: document.getElementById("fechaRegulatorio").value,
      key: [usuario + "/Regulatorio_InformeEscaneo.sql"],
    };
    // console.log(dato);
    fetch(
      process.env.REACT_APP_API_URL +
        "vulnerabilidad/Tecnica/PreparacionPrisma/",
      {
        method: "POST",
        body: JSON.stringify(dato),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          //Authorization: "Bearer " + token,
        },
      }
    )
      .then((data) =>
        data.json().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            setEstadoPost(2);
            document.getElementById("respuestaPrisma").value =
              JSON.stringify(response);
          } else if (data.status >= 500) {
            setEstadoPost(5);
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPost(4);
          }
        })
      )
      .catch(function (err) {});
  };

  const enviarGeneral = () => {
    let usuario = localStorage.getItem("usuario").split("@")[0]; //Esto se debe remover con el directorio activo
    let dato = {
      idusuario: localStorage.getItem("idusuario"),
      usuario: usuario,
      idcompania: localStorage.getItem("idcompania"),
      fechaescaneo: document.getElementById("fechaRegulatorio").value,
      key: [usuario + "/Regulatorio_InformeEscaneo.sql"],
    };
    // console.log(dato);
    fetch(process.env.REACT_APP_API_URL + "vulnerabilidad/Tecnica/", {
      method: "POST",
      body: JSON.stringify(dato),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        //Authorization: "Bearer " + token,
      },
    })
      .then((data) =>
        data.json().then((response) => {
          // console.log(JSON.stringify(response));
          if (data.status >= 200 && data.status < 300) {
            setEstadoPost(2);
            document.getElementById("respuestaGeneral").value =
              JSON.stringify(response);
          } else if (data.status >= 500) {
            setEstadoPost(5);
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPost(4);
          }
        })
      )
      .catch(function (err) {});
  };

  const enviarReclasificacion = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        "reclasificacionRegulatorio/" +
        localStorage.getItem("idcompania") +
        "/",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json;",
          //Authorization: "Bearer " + token,
        },
      }
    )
      .then((data) =>
        data.json().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            setEstadoPost(2);
            document.getElementById("respuestaReclasificacion").value =
              JSON.stringify(response);
          } else if (data.status >= 500) {
            setEstadoPost(5);
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPost(4);
          }
        })
      )
      .catch(function (err) {});
  };

  return (
    <div>
      <AlertDismissibleExample alerta={estadoPOST} />
      <Row>
        <Col>
          <h2 className="titulo">Regulatorio</h2>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={3}>
          <label className="label"> Fecha Regulatorio* </label>
        </Col>
        <Col md={3}>
          <input
            type="date"
            className="form-control text-center"
            onChange={() => {
              setCheckFecha(true);
            }}
            id={"fechaRegulatorio"}
          ></input>
        </Col>
        <Col md={6} />
      </Row>
      <Row style={{ marginTop: "5%" }}>
        <Col md={4}>
          <Row>
            <Button
              disabled={!checkFecha}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="botonPositivo"
              onClick={() => enviarPrisma()}
            >
              Ejecutar Alistamiento Prisma Cloud
            </Button>
          </Row>
          <Row style={{ marginTop: "5%" }}>
            <Col md={1} />
            <Col md={5}>
              <label className="texto">Resultado Ejecución: </label>
            </Col>
            <Col md={6}>
              <textarea cols={40} rows={8} id={"respuestaPrisma"}></textarea>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Button
              disabled={!checkFecha}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="botonPositivo"
              onClick={() => enviarGeneral()}
            >
              Ejecutar Alistamiento General
            </Button>
          </Row>

          <Row style={{ marginTop: "5%" }}>
            <Col md={1} />
            <Col md={5}>
              <label className="texto">Resultado Ejecución: </label>
            </Col>
            <Col md={6}>
              <textarea cols={40} rows={8} id={"respuestaGeneral"}></textarea>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Button
              disabled={!checkFecha}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="botonPositivo"
              onClick={() => enviarReclasificacion()}
            >
              Reclasificar Vulnerabilidades
            </Button>
          </Row>
          <Row style={{ marginTop: "5%" }}>
            <Col md={1} />
            <Col md={3}>
              <label className="texto">Resultado Ejecución: </label>
            </Col>
            <Col md={8}>
              <textarea
                cols={40}
                rows={8}
                id={"respuestaReclasificacion"}
              ></textarea>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
