import { Button, Row, Col, Modal, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import TextField from "@material-ui/core/TextField";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Divider from "@mui/material/Divider";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";


//import AADService from "./funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    default:
      return <p></p>;
      break;
  }
}
function AlertDismissibleExampleDecision({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente la Decisión</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return (
        <Alert variant="warning">
          Error al enviar la información de la Decisión
        </Alert>
      );
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    default:
      return <p></p>;
      break;
  }
}

const headCells = [
  {
    id: "evento",
    num: "0",
    align: "left",
    disablePadding: false,
    label: "Evento",
  },
  {
    id: "descripcion",
    num: "1",
    align: "left",
    col: 3,
    disablePadding: false,
    label: "Descripción‏‏‎ Evento‎ Riesgo‏‏",
  },
  {
    id: "riesgo_inherente",
    num: "2",
    align: "center",
    disablePadding: true,
    label: "Riesgo Inherente",
  },
  {
    id: "efectividad_res",
    num: "7",
    align: "center",
    disablePadding: true,
    label: "Efectividad Control",
  },
  {
    id: "riesgo_residual",
    num: "3",
    align: "center",
    disablePadding: true,
    label: "Riesgo Residual",
  },
  {
    id: "nivel_riesgo",
    num: "8",
    align: "center",
    disablePadding: true,
    label: "Nivel de Riesgo",
  },
  {
    id: "p50Inherente",
    num: "4",
    align: "center",
    disablePadding: true,
    label: "P50 Inherente",
  },
  {
    id: "p99Inherente",
    num: "5",
    align: "center",
    disablePadding: true,
    label: "P99 Inherente",
  },
  {
    id: "estado_riesgo",
    num: "6",
    align: "center",
    disablePadding: true,
    label: "Estado del Riesgo",
  },
  {
    id: "justificacion",
    num: "7",
    align: "left",
    col: 3,
    disablePadding: false,
    label: "Justificación Estado Riesgo‏‏",
  },
  {
    id: "decision",
    num: "9",
    align: "center",
    disablePadding: true,
    label: "Decisión",
  },
];

const headCellsDatosExcel = [
  {
    id: "fuente",
    align: "center",
    col: 1,
    disablePadding: true,
    label: "Fuente",
  },
  {
    id: "evento_amenaza",
    align: "center",
    col: 2,
    disablePadding: true,
    label: "Evento De Amenaza",
  },
  {
    id: "factor_exp",
    align: "center",
    col: 1,
    disablePadding: true,
    label: "Factor de Exposición",
  },
  {
    id: "vector_imp",
    align: "left",
    col: 1,
    disablePadding: true,
    label: "Vector de Impacto",
  },
  {
    id: "frecuencia",
    align: "center",
    col: 1,
    disablePadding: true,
    label: "Frecuencia",
  },
  {
    id: "frecuenciaModelo",
    align: "center",
    col: 1,
    disablePadding: true,
    label: "Frecuencia de Modelo",
  },
  {
    id: "efectividad_control",
    align: "center",
    col: 1,
    disablePadding: false,
    label: "Efectividad Control",
  },
];

const headCellsDatosValoracion = [
  {
    id: "idefecto",
    align: "center",
    disablePadding: true,
    label: "Id Efecto",
  },
  {
    id: "nombreefecto",
    align: "center",
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "tipoefecto",
    align: "center",
    disablePadding: true,
    label: "Tipo Efecto",
  },
  { id: "resultado_p50", align: "center", disablePadding: true, label: "P50" },
  { id: "resultado_p95", align: "center", disablePadding: true, label: "P95" },
  { id: "resultado_p99", align: "center", disablePadding: true, label: "P99" },
  {
    id: "metodovaloracion",
    align: "center",
    disablePadding: true,
    label: "Método",
  },
  { id: "analista", align: "center", disablePadding: true, label: "Analista" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stylesSelect = {
  control: (base) => ({
    ...base,
    fontFamily: "Roboto",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "CIBFont Sans Regular",
  }),

  option: (provided, state) => ({
    ...provided,
    //color: state.isFocused ? 'white' : '#495057',
  }),
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/*Encabezado de tabla*/
function EnhancedTableHead(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ backgroundColor: "#2c2a29", color: "#ffffff" }} />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
            colSpan={headCell.col ? headCell.col : 1}
          >
            <label className="label" style={{ marginRight: "20%" }}>
              {headCell.label}
            </label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

function EnhancedTableHeadDatosExcel(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellsDatosExcel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
            colSpan={headCell.col}
          >
            <label>{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadDatosExcel.propTypes = {
  classes: PropTypes.object.isRequired,
};

function EnhancedTableHeadValoracion(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellsDatosValoracion.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <label className="texto">{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadValoracion.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  columdetalles: {
    width: "33.33%",
  },
  paper: {
    backgroundColor: "white",
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
///Estilos predeterminados para el Modal
const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

function popitup(url) {
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - 600) / 2 / systemZoom;
  const top = (height - 600) / 2 / systemZoom;
  var newwindow = window.open(
    url,
    "name",
    `scrollbars=yes,resizable=no,top=${top},left=${left},width=600,height=600`
  );

  if (window.focus) {
    newwindow.focus();
  }
  return false;
}
function Riesgos() {
  const [validated, setValidated] = React.useState(false);
  const [cambioEstado, setCambioEstado] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [dataT, setDataT] = React.useState([]);
  const [detalladoRiesgos, setDetalladoRiesgos] = React.useState([]);
  const [decision, setDecision] = React.useState([]);
  const [decisionRiesgo, setDecisionRiesgo] = React.useState([]);
  const [usuarioRol, setUsuarioRol] = React.useState([]);
  const [usuarioRoti, setUsuarioRoti] = React.useState([]);
  const [usuariosDecisiones, setUsuariosDecisiones] = React.useState([]);
  const [efectosIniciales, setEfectosIniciales] = React.useState([]);
  const [idEfectosIniciales, setIdEfectosIniciales] = React.useState([]);
  const [efectosFinales, setEfectosFinales] = React.useState([]);
  const [valoresPAR, setValoresPAR] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);
  // const [progress, setProgress] = React.useState(10);
  // const timer = setInterval(() => {
  //   setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  // }, 800);

  // function CircularProgressWithLabel(props) {
  //   return (
  //     <Box sx={{ position: 'relative', display: 'inline-flex' }}>
  //       <CircularProgress variant="determinate" {...props} />
  //       <Box
  //         sx={{
  //           top: 0,
  //           left: 0,
  //           bottom: 0,
  //           right: 0,
  //           position: 'absolute',
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //         }}
  //       >
  //         <Typography variant="caption" component="div" color="text.secondary">
  //           {`${Math.round(props.value)}%`}
  //         </Typography>
  //       </Box>
  //     </Box>
  //   );
  // }
  const [validRole, setValidRole] = React.useState(
    localStorage.getItem("rolusuario") == 2 ||
      localStorage.getItem("rolusuario") == 1
      ? true
      : false
  );

  useEffect(() => {
    const fetchdata = async () => {

      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/riesgos/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let dataGuardar = await result.json();
      setDataT(dataGuardar);
      const resultEfectos = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/riesgos/efectos/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let efectosInicialesTemp = await resultEfectos.json();

      setIdEfectosIniciales(efectosInicialesTemp);
      if (efectosInicialesTemp.length >= 1) {
        let idsEfecto = [];
        let efectosTemp = [];
        for (let i = 0; i < efectosInicialesTemp.length; i++) {
          if (efectosInicialesTemp[i].estadoasociacion == 1) {
            idsEfecto.push(efectosInicialesTemp[i].idefecto);
            efectosTemp.push(efectosInicialesTemp[i]);
          }
        }
        let datos = JSON.stringify({ id: idsEfecto });
        fetch("https://servicios-de-valoracion.apps.bancolombia.com/efectos/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //Authorization: "Bearer " + token,
          },
          body: datos,
        })
          .then((data) =>
            data.json().then((response) => {
              if (data.status >= 200 && data.status < 300) {
                let efectosEnviar = [];
                for (let i = 0; i < efectosTemp.length; i++) {
                  let efectos = [];
                  for (let j = 0; j < response.length; j++) {
                    if (efectosTemp[i].idefecto == response[j].idefecto) {
                      for (let k = 0; k < dataGuardar.length; k++) {
                        if (
                          efectosTemp[i].idriesgo == dataGuardar[k].idriesgo
                        ) {
                          efectos.push(efectosTemp[i].idriesgo, response[j]);
                          break;
                        }
                      }
                      efectosEnviar.push(efectos);
                      break;
                    }
                  }
                }
                let dictEfectos = [];
                let tempDictEfectos = [];

                for (let i = 0; i < efectosEnviar.length; i++) {
                  if (!tempDictEfectos.includes(efectosEnviar[i][0])) {
                    tempDictEfectos.push(efectosEnviar[i][0]);
                  }
                }
                for (let i = 0; i < tempDictEfectos.length; i++) {
                  let dict = { idriesgo: tempDictEfectos[i] };
                  let arrayTemp = [];
                  for (let j = 0; j < efectosEnviar.length; j++) {
                    if (tempDictEfectos[i] === efectosEnviar[j][0]) {
                      arrayTemp.push(efectosEnviar[j][1]);
                    }
                  }
                  dict["efectos"] = arrayTemp;
                  dictEfectos.push(dict);
                }
                let efectos_finales = [];

                for (let i = 0; i < dictEfectos.length; i++) {
                  let id_efectos_finales = [];

                  for (let j = 0; j < dictEfectos[i].efectos.length; j++) {
                    id_efectos_finales.push(dictEfectos[i].efectos[j].idefecto);
                  }
                  efectos_finales.push({
                    idriesgo: dictEfectos[i].idriesgo,
                    efectos: id_efectos_finales,
                  });
                }
                setEfectosIniciales(dictEfectos);
                setEfectosFinales(efectos_finales);
              } else if (data.status >= 500) {
              } else if (data.status >= 400 && data.status < 500) {
              }
            })
          )
          .catch(function (err) {});
      }
    };

    const fetchDecision = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "decisiones/0/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            // Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();

      let idriesgo = [];
      let datadecision = [];

      data.map((dec) => {
        idriesgo.push(dec.idriesgo);
        datadecision.push(dec);
      });

      setDecision(datadecision);
      setDecisionRiesgo(idriesgo);
    };

    const obtenerUsuariosDecision = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuariosroldecisiones/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      let selectcompania = datUsuarios.map(
        ({
          idposicion: value,
          nombre: label,
          nombreposicion: nombreposicion,
        }) => ({
          value,
          label,
          nombreposicion,
        })
      );

      setUsuariosDecisiones(selectcompania);
    };

    const fetchdataUsuariorol = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuariosrol/" +
          localStorage.getItem("idcompania") +
          "/2/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + this.token,
          },
        }
      );
      let param = await result.json();
      let selectrespon = param.map(
        ({
          idposicion: value,
          nombre: label,
          nombreposicion: nombreposicion,
        }) => ({
          value,
          label,
          nombreposicion,
        })
      );
      setUsuarioRol(selectrespon);
    };
    const fetchdataUsuarioRoti = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "usuariosroti/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + this.token,
          },
        }
      );
      let param = await result.json();
      let selectrespon = param.map(
        ({
          idposicion: value,
          nombre: label,
          nombreposicion: nombreposicion,
        }) => ({
          value,
          label,
          nombreposicion,
        })
      );
      setUsuarioRoti(selectrespon);
    };

    const fetchdataDetalles = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/riesgos/detalle/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let riesgosTemp = [];
      let riesgosDetail = [];
      let riesgosDetailTemp = [];
      let riesgosFinal = [];
      let amenazaxActor = [];
      let actores = [];
      let actoresFinal = [];
      let data = await result.json();
      for (let i = 0; i < data.length; i++) {
        if (!riesgosTemp.includes(data[i].idriesgo)) {
          riesgosTemp.push(data[i].idriesgo);
        }
      }
      for (let i = 0; i < riesgosTemp.length; i++) {
        actores = [];
        for (let j = 0; j < data.length; j++) {
          if (riesgosTemp[i] == data[j].idriesgo) {
            if (!actores.includes(data[j].idactor)) {
              actores.push(data[j].idactor);
            }
          }
        }
        actoresFinal.push(actores);
      }
      for (let i = 0; i < riesgosTemp.length; i++) {
        for (let j = 0; j < actoresFinal[i].length; j++) {
          amenazaxActor = [];
          for (let k = 0; k < data.length; k++) {
            if (riesgosTemp[i] == data[k].idriesgo) {
              if (actoresFinal[i][j] == data[k].idactor) {
                amenazaxActor.push(data[k]);
              }
            }
          }
          actoresFinal[i][j] = amenazaxActor;
        }
      }
      setDetalladoRiesgos(actoresFinal);
    };
    const fetchDataGeneral = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/parametrosGeneralesAll/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + this.token,
          },
        }
      );
      let par = await result.json();
      let par_enviar = [];
      for (let i = 0; i < par.length; i++) {
        if (par[i].grupo == "PAR") {
          par_enviar.push(par[i]);
        }
      }
      setValoresPAR(par_enviar);
    };

    fetchdata();
    fetchdataDetalles();
    obtenerUsuariosDecision();
    fetchdataUsuariorol();
    fetchDecision();
    fetchDataGeneral();
    fetchdataUsuarioRoti();
  }, []);

  const sendEfectoRiesgo = (efectos, iniciales) => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    let efectosGuardar = [];
    if (iniciales.length >= 1) {
      let efectos_iniciales = [];
      let id_riesgos_iniciales = [];
      for (let i = 0; i < iniciales.length; i++) {
        if (!id_riesgos_iniciales.includes(iniciales[i].idriesgo)) {
          id_riesgos_iniciales.push(iniciales[i].idriesgo);
        }
      }
      for (let i = 0; i < id_riesgos_iniciales.length; i++) {
        let id_efectos_iniciales = [];
        for (let j = 0; j < iniciales.length; j++) {
          if (id_riesgos_iniciales[i] === iniciales[j].idriesgo) {
            id_efectos_iniciales.push(iniciales[j].idefecto);
          }
        }
        efectos_iniciales.push({
          idriesgo: id_riesgos_iniciales[i],
          efectos: id_efectos_iniciales,
        });
      }
      //Ciclos para ver los nuevos efectos
      let nuevos_temp = [];
      for (let i = 0; i < efectos.length; i++) {
        for (let j = 0; j < efectos_iniciales.length; j++) {
          if (efectos[i].idriesgo == efectos_iniciales[j].idriesgo) {
            var diferencia_temp = efectos[i].efectos.filter(
              (x) => efectos_iniciales[j].efectos.indexOf(x) === -1
            );
            nuevos_temp.push({
              idriesgo: efectos[i].idriesgo,
              efectos: diferencia_temp,
            });
            break;
          }
        }
      }
      //Ciclos para ver los efectos que deben pasar otra vez a true
      let viejos_true_temp = [];
      for (let i = 0; i < efectos_iniciales.length; i++) {
        for (let j = 0; j < efectos.length; j++) {
          if (efectos_iniciales[i].idriesgo == efectos[j].idriesgo) {
            var diferencia_temp = efectos_iniciales[i].efectos.filter(
              (x) => efectos[j].efectos.indexOf(x) !== -1
            );
            viejos_true_temp.push({
              idriesgo: efectos[i].idriesgo,
              efectos: diferencia_temp,
            });
            break;
          }
        }
      }
      //Ciclos para ver los efectos que se deben pasar a false
      let false_temp = [];
      for (let i = 0; i < efectos_iniciales.length; i++) {
        for (let j = 0; j < efectos.length; j++) {
          if (efectos_iniciales[i].idriesgo == efectos[j].idriesgo) {
            var diferencia_temp = efectos_iniciales[i].efectos.filter(
              (x) => efectos[j].efectos.indexOf(x) === -1
            );
            false_temp.push({
              idriesgo: efectos_iniciales[i].idriesgo,
              efectos: diferencia_temp,
            });
            break;
          }
        }
      }
      //Ciclos para ver los efectos que se deben mantener en True
      let true_temp = [];
      for (let i = 0; i < efectos.length; i++) {
        for (let j = 0; j < nuevos_temp.length; j++) {
          if (efectos[i].idriesgo == nuevos_temp[j].idriesgo) {
            var diferencia_temp = efectos[i].efectos.filter(
              (x) => nuevos_temp[j].efectos.indexOf(x) === -1
            );
            true_temp.push({
              idriesgo: efectos[i].idriesgo,
              efectos: diferencia_temp,
            });
            break;
          }
        }
      }
      //Ciclo para agregar al guardado general un efecto nuevo
      for (let i = 0; i < nuevos_temp.length; i++) {
        for (let j = 0; j < nuevos_temp[i].efectos.length; j++) {
          efectosGuardar.push({
            idefectoporriesgo: 0,
            idriesgo: nuevos_temp[i].idriesgo,
            idefecto: nuevos_temp[i].efectos[j],
            fechacreacion: today.toISOString(),
            idusuariocreacion: localStorage.getItem("idusuario"),
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
            estadoasociacion: 1,
          });
        }
      }
      //Ciclo para agregar al guardado general un efecto que vuelve a true
      for (let i = 0; i < viejos_true_temp.length; i++) {
        for (let j = 0; j < viejos_true_temp[i].efectos.length; j++) {
          for (let k = 0; k < iniciales.length; k++) {
            if (
              viejos_true_temp[i].idriesgo == iniciales[k].idriesgo &&
              viejos_true_temp[i].efectos[j] == iniciales[k].idefecto
            ) {
              efectosGuardar.push({
                idefectoporriesgo: iniciales[k].idefectoporriesgo,
                idriesgo: iniciales[k].idriesgo,
                idefecto: iniciales[k].idefecto,
                fechacreacion: iniciales[k].fechacreacion,
                idusuariocreacion: iniciales[k].idusuariocreacion,
                fechamodificacion: today.toISOString(),
                idusuariomodificacion: localStorage.getItem("idusuario"),
                estadoasociacion: 1,
              });
              break;
            }
          }
        }
      }
      //Ciclo para agregar al guardado los de estado de asociacion False
      for (let i = 0; i < false_temp.length; i++) {
        for (let j = 0; j < false_temp[i].efectos.length; j++) {
          for (let k = 0; k < iniciales.length; k++) {
            if (
              false_temp[i].idriesgo == iniciales[k].idriesgo &&
              false_temp[i].efectos[j] == iniciales[k].idefecto
            ) {
              if (!iniciales[k].estadoasociacion == 0) {
                efectosGuardar.push({
                  idefectoporriesgo: iniciales[k].idefectoporriesgo,
                  idriesgo: iniciales[k].idriesgo,
                  idefecto: iniciales[k].idefecto,
                  fechacreacion: iniciales[k].fechacreacion,
                  idusuariocreacion: iniciales[k].idusuariocreacion,
                  fechamodificacion: today.toISOString(),
                  idusuariomodificacion: localStorage.getItem("idusuario"),
                  estadoasociacion: 0,
                });
              }
              break;
            }
          }
        }
      }

      //Ciclo para agregar al guardado los de estado de asociacion True
      for (let i = 0; i < true_temp.length; i++) {
        for (let j = 0; j < true_temp[i].efectos.length; j++) {
          for (let k = 0; k < iniciales.length; k++) {
            if (
              true_temp[i].idriesgo == iniciales[k].idriesgo &&
              true_temp[i].efectos[j] == iniciales[k].idefecto
            ) {
              efectosGuardar.push({
                idefectoporriesgo: iniciales[k].idefectoporriesgo,
                idriesgo: iniciales[k].idriesgo,
                idefecto: iniciales[k].idefecto,
                fechacreacion: iniciales[k].fechacreacion,
                idusuariocreacion: iniciales[k].idusuariocreacion,
                fechamodificacion: today.toISOString(),
                idusuariomodificacion: localStorage.getItem("idusuario"),
                estadoasociacion: 1,
              });
              break;
            }
          }
        }
      }
    } else {
      for (let i = 0; i < efectos.length; i++) {
        for (let j = 0; j < efectos[i].efectos.length; j++) {
          efectosGuardar.push({
            idefectoporriesgo: 0,
            idriesgo: efectos[i].idriesgo,
            idefecto: efectos[i].efectos[j],
            fechacreacion: today.toISOString(),
            idusuariocreacion: localStorage.getItem("idusuario"),
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
            estadoasociacion: 1,
          });
        }
      }
    }

    let dataPUT = JSON.stringify(efectosGuardar);

    // const fetchdatarefresh = async () => {
    //   const result = await fetch(
    //     process.env.REACT_APP_API_URL +
    //     "evaluacion/riesgos/" +
    //     localStorage.getItem("idevaluacion") +
    //     "/",
    //     {
    //       method: "GET",
    //       headers: {
    //         Accept: "application/json",
    //         //Authorization: "Bearer " + token,
    //       },
    //     }
    //   );
    //   let dataGuardar = await result.json();
    //   setDataT(dataGuardar);
    // }

    fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/riesgos/efectos/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          //Authorization: "Bearer " + token,
        },
        body: dataPUT,
      }
    )
      .then((data) =>
        data.json().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            setEstadoPost(2);
            const fetchdataRefresh = async () => {
              const result = await fetch(
                process.env.REACT_APP_API_URL +
                  "evaluacion/riesgos/" +
                  localStorage.getItem("idevaluacion") +
                  "/",
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    //Authorization: "Bearer " + token,
                  },
                }
              );
              let dataGuardar = await result.json();
              setDataT(dataGuardar);
              const resultEfectos = await fetch(
                process.env.REACT_APP_API_URL +
                  "evaluacion/riesgos/efectos/" +
                  localStorage.getItem("idevaluacion") +
                  "/",
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    //Authorization: "Bearer " + token,
                  },
                }
              );
              let efectosInicialesTemp = await resultEfectos.json();

              setIdEfectosIniciales(efectosInicialesTemp);
              if (efectosInicialesTemp.length >= 1) {
                let idsEfecto = [];
                let efectosTemp = [];
                for (let i = 0; i < efectosInicialesTemp.length; i++) {
                  if (efectosInicialesTemp[i].estadoasociacion == 1) {
                    idsEfecto.push(efectosInicialesTemp[i].idefecto);
                    efectosTemp.push(efectosInicialesTemp[i]);
                  }
                }
                let datos = JSON.stringify({ id: idsEfecto });
                fetch(
                  "https://servicios-de-valoracion.apps.bancolombia.com/efectos/",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      //Authorization: "Bearer " + token,
                    },
                    body: datos,
                  }
                )
                  .then((data) =>
                    data.json().then((response) => {
                      if (data.status >= 200 && data.status < 300) {
                        let efectosEnviar = [];
                        for (let i = 0; i < efectosTemp.length; i++) {
                          let efectos = [];
                          for (let j = 0; j < response.length; j++) {
                            if (
                              efectosTemp[i].idefecto == response[j].idefecto
                            ) {
                              for (let k = 0; k < dataGuardar.length; k++) {
                                if (
                                  efectosTemp[i].idriesgo ==
                                  dataGuardar[k].idriesgo
                                ) {
                                  efectos.push(
                                    efectosTemp[i].idriesgo,
                                    response[j]
                                  );
                                  break;
                                }
                              }
                              efectosEnviar.push(efectos);
                              break;
                            }
                          }
                        }
                        let dictEfectos = [];
                        let tempDictEfectos = [];

                        for (let i = 0; i < efectosEnviar.length; i++) {
                          if (!tempDictEfectos.includes(efectosEnviar[i][0])) {
                            tempDictEfectos.push(efectosEnviar[i][0]);
                          }
                        }
                        for (let i = 0; i < tempDictEfectos.length; i++) {
                          let dict = { idriesgo: tempDictEfectos[i] };
                          let arrayTemp = [];
                          for (let j = 0; j < efectosEnviar.length; j++) {
                            if (tempDictEfectos[i] === efectosEnviar[j][0]) {
                              arrayTemp.push(efectosEnviar[j][1]);
                            }
                          }
                          dict["efectos"] = arrayTemp;
                          dictEfectos.push(dict);
                        }
                        let efectos_finales = [];

                        for (let i = 0; i < dictEfectos.length; i++) {
                          let id_efectos_finales = [];

                          for (
                            let j = 0;
                            j < dictEfectos[i].efectos.length;
                            j++
                          ) {
                            id_efectos_finales.push(
                              dictEfectos[i].efectos[j].idefecto
                            );
                          }
                          efectos_finales.push({
                            idriesgo: dictEfectos[i].idriesgo,
                            efectos: id_efectos_finales,
                          });
                        }

                        setEfectosIniciales(dictEfectos);
                        setEfectosFinales(efectos_finales);
                      } else if (data.status >= 500) {
                      } else if (data.status >= 400 && data.status < 500) {
                      }
                    })
                  )
                  .catch(function (err) {});
              }
            };
            fetchdataRefresh();
          } else if (data.status >= 500) {
            setEstadoPost(5);
            const fetchdataRefresh = async () => {
              const result = await fetch(
                process.env.REACT_APP_API_URL +
                  "evaluacion/riesgos/" +
                  localStorage.getItem("idevaluacion") +
                  "/",
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    //Authorization: "Bearer " + token,
                  },
                }
              );
              let dataGuardar = await result.json();
              setDataT(dataGuardar);
            };
            fetchdataRefresh();
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPost(4);
            const fetchdataRefresh = async () => {
              const result = await fetch(
                process.env.REACT_APP_API_URL +
                  "evaluacion/riesgos/" +
                  localStorage.getItem("idevaluacion") +
                  "/",
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    //Authorization: "Bearer " + token,
                  },
                }
              );
              let dataGuardar = await result.json();
              setDataT(dataGuardar);
            };
            fetchdataRefresh();
          }
        })
      )
      .catch(function (err) {});
  };

  const sendData = (event) => {
    event.preventDefault();
    // setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let datosEnviar = [];
      for (let i = 0; i < dataT.length; i++) {
        let dato = {
          idriesgo: dataT[i].idriesgo,
          idevaluacion: dataT[i].idevaluacion,
          idevento_riesgo: dataT[i].idevento_riesgo,
          efectividadcontrol: dataT[i].efectividadcontrol,
          evaluado: dataT[i].evaluado,
          exposicioninherentep50: dataT[i].exposicioninherentep50,
          exposicioninherentep95: dataT[i].exposicioninherentep95,
          exposicioninherentep99: dataT[i].exposicioninherentep99,
          exposicionresidual: dataT[i].exposicionresidual,
          fechacreacion: dataT[i].fechacreacion,
          fechamodificacion: today.toISOString(),
          frecuenciaevento: dataT[i].frecuenciaevento,
          idusuariocreacion: dataT[i].idusuariocreacion,
          idusuariomodificacion: localStorage.getItem("idusuario"),
          nivelriesgo:
            dataT[i].nivelriesgo == null || dataT[i].nivelriesgo == ""
              ? "Tolerable"
              : dataT[i].nivelriesgo,
          probabilidadrango00: dataT[i].probabilidadrango00,
          vectorimpacto: dataT[i].vectorimpacto,
          descripcionriesgo: document.getElementById("descripcion" + i).value
            ? document.getElementById("descripcion" + i).value
            : null,
          disp_varchar1: document.getElementById("justificacion" + i).value
            ? document.getElementById("justificacion" + i).value
            : null,
          estadoeventoriesgo:
            document.getElementById("estado_riesgo" + i).value != null ||
            document.getElementById("estado_riesgo" + i).value != undefined
              ? document.getElementById("estado_riesgo" + i).value !=
                dataT[i].estadoeventoriesgo
                ? document.getElementById("estado_riesgo" + i).value
                : dataT[i].estadoeventoriesgo
              : true,
        };
        datosEnviar.push(dato);
      }
      let data = JSON.stringify(datosEnviar);
      fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/riesgos/" +
          dataT[0].idevaluacion +
          "/",
        {
          method: "PUT",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        }
      )
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              if (efectosFinales.length >= 1) {
                sendEfectoRiesgo(efectosFinales, idEfectosIniciales);
              } else {
                const fetchdataRefresh = async () => {
                  const result = await fetch(
                    process.env.REACT_APP_API_URL +
                      "evaluacion/riesgos/" +
                      localStorage.getItem("idevaluacion") +
                      "/",
                    {
                      method: "GET",
                      headers: {
                        Accept: "application/json",
                        //Authorization: "Bearer " + token,
                      },
                    }
                  );
                  let dataGuardar = await result.json();
                  setDataT(dataGuardar);
                };
                fetchdataRefresh();
                setEstadoPost(2);
              }
            } else if (data.status >= 500) {
              setEstadoPost(5);
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
            // setLoading(false);
          })
        )
        .catch(function (err) {});
    }
    setValidated(true);
  };

  function ContenidoRiesgos(props) {
    const classes = useStyles();
    const [modalShow, setModalShow] = React.useState(false);
    const [openPrimer, setOpenPrimer] = React.useState(false);
    const [openTercer, setOpenTercer] = React.useState(false);
    const [modalDecisionShow, setModalDecisionShow] = React.useState(false);
    const [dataTable, setDataTable] = React.useState([]);
    const [idRiesgoModal, setidRiesgoModal] = React.useState(0);
    const { dataRiesgo, index, inicialesContenido } = props;
    const [justiRequire, setJustiRequire] = React.useState(false);
    const classesRow = useRowStyles();
    const [efecto, setEfecto] = React.useState(props.inicialesContenido);
    const [p50, setP50] = React.useState(dataRiesgo.exposicioninherentep50);
    const [p95, setP95] = React.useState(dataRiesgo.exposicioninherentep95);
    const [p99, setP99] = React.useState(dataRiesgo.exposicioninherentep99);
    const [expoResidual, setExpoResidual] = React.useState(
      dataRiesgo.exposicionresidual
    );
    const [nivelRiesgo, setNivelRiesgo] = React.useState(
      dataRiesgo.nivelriesgo
    );

    let opciones = [true, false];

    let list_est = [
      <option value={dataRiesgo.estadoeventoriesgo} selected>
        {dataRiesgo.estadoeventoriesgo ? "Habilitado" : "Deshabilitado"}
      </option>,
    ].concat(
      opciones.map((opc) => {
        if (opc !== dataRiesgo.estadoeventoriesgo) {
          return (
            <option value={opc}>{opc ? "Habilitado" : "Deshabilitado"}</option>
          );
        }
      })
    );

    const opci = list_est;

    function calcularNuevasVariables(efectos, riesgo, dataGeneral) {
      //Calculamos p50
      let p50_temp = 0;
      for (let i = 0; i < efectos.length; i++) {
        p50_temp = p50_temp + parseInt(efectos[i].resultado_p50);
      }
      //Calculamos p95
      let p95_temp = 0;
      for (let i = 0; i < efectos.length; i++) {
        p95_temp = p95_temp + parseInt(efectos[i].resultado_p95);
      }
      //Calculamos p99
      let p99_temp = 0;
      for (let i = 0; i < efectos.length; i++) {
        p99_temp = p99_temp + parseInt(efectos[i].resultado_p99);
      }
      //Calculamos Riesgo Residual
      let riesgoResidual_temp = (
        p95_temp *
        (1 - parseFloat(riesgo.efectividadcontrol).toFixed(4))
      ).toFixed(2);

      //Calculamos el Nivel de Riesgo
      let valores_temp = [];
      let nivelRiesgo_temp = "";
      var banco = "";

      switch (localStorage.getItem("idcompania")) {
        case "1":
          banco = "Bancolombia";
          break;
        case "2":
          banco = "Nequi";
          break;
        case "3":
          banco = "BAM";
          break;
        case "4":
          banco = "Banco Agrícola";
          break;
        case "5":
          banco = "Banistmo";
          break;
        case "6":
          banco = "Panamá Offshore";
          break;
        case "7":
          banco = "Corporativo";
          break;
        case "8":
          banco = "Fiduciaria Bancolombia";
          break;
        case "9":
          banco = "Tuya";
          break;
        case "10":
          banco = "Renting Bancolombia";
          break;
        case "11":
          banco = "Banca de Inversión";
          break;
        case "12":
          banco = "Puerto Rico";
          break;
        case "13":
          banco = "Panamá";
          break;
        case "14":
          banco = "Valores Bancolombia";
          break;
        case "15":
          banco = "Valores Banagrícola";
          break;
        case "16":
          banco = "Cayman";
          break;
        case "17":
          banco = "Gestora de fondos de";
          break;
        case "18":
          banco = "Sucursal Panamá";
          break;
        case "19":
          banco = "Valores Banistmo";
          break;
        case "20":
          banco = "Wompi";
          break;
        case "21":
          banco = "Financomer";
          break;
        default:
          break;
      }
      for (let i = 0; i < valoresPAR.length; i++) {
        if (valoresPAR[i].parametro == banco) {
          valores_temp.push(valoresPAR[i].vlrnumericometrica1);
        }
      }

      if (
        parseInt(riesgoResidual_temp).toFixed(2) <
        parseInt(valores_temp[0]).toFixed(2)
      ) {
        nivelRiesgo_temp = "Tolerable";
      } else if (
        parseInt(valores_temp[0]).toFixed(2) <=
        parseInt(riesgoResidual_temp).toFixed(2) <
        parseInt(valores_temp[1]).toFixed(2)
      ) {
        nivelRiesgo_temp = "Moderado";
      } else if (
        parseInt(valores_temp[1]).toFixed(2) <=
        parseInt(riesgoResidual_temp).toFixed(2) <
        parseInt(valores_temp[2]).toFixed(2)
      ) {
        nivelRiesgo_temp = "Crítico";
      } else if (
        parseInt(riesgoResidual_temp).toFixed(2) >=
        parseInt(valores_temp[3]).toFixed(2)
      ) {
        nivelRiesgo_temp = "Muy Crítico";
      }

      setP50(p50_temp);
      setP95(p95_temp);
      setP99(p99_temp);
      setExpoResidual(riesgoResidual_temp);
      setNivelRiesgo(nivelRiesgo_temp);
      for (let i = 0; i < dataGeneral.length; i++) {
        if (riesgo.idriesgo == dataGeneral[i].idriesgo) {
          dataGeneral[i].exposicioninherentep50 = p50_temp;
          dataGeneral[i].exposicioninherentep95 = p95_temp;
          dataGeneral[i].exposicioninherentep99 = p99_temp;
          dataGeneral[i].exposicionresidual = riesgoResidual_temp;
          dataGeneral[i].nivelriesgo = nivelRiesgo_temp;
          break;
        }
      }
      setDataT(dataGeneral);
    }
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function manageChange(index, idevento) {
      var cambioTemp = cambioEstado;
      if (cambioTemp.includes(idevento)) {
        cambioTemp[cambioTemp.indexOf(idevento)] = null;
        setJustiRequire(false);
      } else {
        cambioTemp.push(idevento);
        setJustiRequire(true);
      }
      setCambioEstado(cambioTemp);
    }

    function MyVerticallyCenteredModal(props) {
      const classes = useStylesModal();
      //const [idsEfectosPut, setidsEfectosPut] = React.useState([]);
      const { effect } = props;

      var resultEf = [];
      effect.map((efc) => {
        resultEf.push({ id: uuidv4(), Id_Efecto: efc.idefecto });
      });
      resultEf.push({ id: uuidv4(), Id_Efecto: "" });
      const [inputFields, setInputFields] = React.useState(resultEf);

      const handleSubmit = (e) => {
        e.preventDefault();
        let idsEfectos = inputFields
          .filter((a) => a.Id_Efecto !== "" && a.Id_Efecto !== 0)
          .map((a) => parseInt(a.Id_Efecto));
        SendDataEfecto(idsEfectos);
        setModalShow(false);
      };

      const handleChangeInput = (id, event) => {
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i[event.target.name] = event.target.value;
          }
          return i;
        });

        setInputFields(newInputFields);
      };

      const handleAddFields = () => {
        setInputFields([...inputFields, { id: uuidv4(), Id_Efecto: "" }]);
      };

      const handleRemoveFields = (id) => {
        const values = [...inputFields];
        values.splice(
          values.findIndex((value) => value.id === id),
          1
        );
        setInputFields(values);
      };

      function Item(props) {
        const { sx, ...other } = props;
        return (
          <Box
            sx={{
              textAlign: "center",
              m: 2,
              ...sx,
            }}
            {...other}
          />
        );
      }

      Item.propTypes = {
        sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      };

      const SendDataEfecto = (idsEfecto) => {
        let datos = JSON.stringify({ id: idsEfecto });
        fetch("https://servicios-de-valoracion.apps.bancolombia.com/efectos/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //Authorization: "Bearer " + token,
          },
          body: datos,
        })
          .then((data) =>
            data.json().then((response) => {
              if (data.status >= 200 && data.status < 300) {
                if (response.length == undefined) {
                  setEfecto([]);
                } else {
                  setEfecto(response);
                }
                let efectosFinalesTemp = efectosFinales;
                if (efectosFinalesTemp.length >= 1) {
                  for (let i = 0; i < efectosFinalesTemp.length; i++) {
                    if (efectosFinalesTemp[i].idriesgo == dataRiesgo.idriesgo) {
                      efectosFinalesTemp[i].efectos = idsEfecto;
                    }
                  }
                } else {
                  efectosFinalesTemp.push({
                    idriesgo: dataRiesgo.idriesgo,
                    efectos: idsEfecto,
                  });
                }
                setEfectosFinales(efectosFinalesTemp);
                calcularNuevasVariables(response, dataRiesgo, dataT);
              } else if (data.status >= 500) {
              } else if (data.status >= 400 && data.status < 500) {
              }
            })
          )
          .catch(function (err) {});
      };

      return (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Guardar efectos asociados al riesgo
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h5>
              Puede guardar los efectos asociados al riesgo, ingresando el id
              del efecto a continuación:
            </h5>
            <Box>
              <Item>
                <form className={classes.root} onSubmit={handleSubmit}>
                  {inputFields.map((inputField) => (
                    <div key={inputField.id} justifyContent={"flex-center"}>
                      <TextField
                        name="Id_Efecto"
                        label="Id Efecto"
                        type="number"
                        value={inputField.Id_Efecto}
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                      <IconButton
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <IconButton onClick={handleAddFields}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  ))}
                  <div style={{ paddingTop: "30px" }}>
                    <Button
                      className="botonPositivo3"
                      variant="contained"
                      type="submit"
                      onClick={function (event) {
                        handleSubmit(event);
                        setOpenTercer(true);
                      }}
                    >
                      Asociar
                    </Button>
                  </div>
                </form>
              </Item>
            </Box>
          </Modal.Body>
        </Modal>
      );
    }

    function Item(props) {
      const { sx, ...other } = props;
      return (
        <Box
          sx={{
            textAlign: "center",
            m: 2,
            ...sx,
          }}
          {...other}
        />
      );
    }

    Item.propTypes = {
      sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    };

    const filtrarResponsable = () => {
      const valueresponsable = document.getElementById(
        "valueoptionresponsable"
      ).value;
    };

    function MyVerticallyCenteredModalDecision(props) {
      const [estadoPOSTDecision, setEstadoPostDecision] = React.useState(0);
      const [ocultarBtn, setOcultarBtn] = React.useState(false);
      const [responsabledecision, setResponsableDecision] =
        React.useState(null);
      const [decisiontomador, setDecisionTomador] = React.useState(null);
      const [tomadorDecisones, setTomadorDecisones] = React.useState([]);
      const [valueTomadorDecision, setValueTomadorDecision] =
        React.useState(null);

      const opcionesTomadorDecision = [
        { value: "Comité de Riesgos", label: "Comité de Riesgos" },
        { value: "Vicepresidente", label: "Vicepresidente" },
        { value: "Director", label: "Director" },
        { value: "Gerente", label: "Gerente" },
      ];

      const filtrarTomadorDecision = (option) => {
        setDecisionTomador(option.label);
        let temp = [];
        // localStorage.getItem("idcompania")
        console.log("usuarioRoti", usuarioRoti);
        if (option.label == "Comité de Riesgos") {
          if (localStorage.getItem("idcompania") == 1) {
            usuarioRoti.map((datausuario) => {
              if (datausuario.value == 61559) {
                temp.push(datausuario);
                console.log("temp", temp);
              }
            });
          } else {
            usuarioRoti.map((datausuario) => {
              if (datausuario.value == 87525) {
                temp.push(datausuario);
                console.log("temp", temp);
              }
            });
          }
        } else {
          usuariosDecisiones.map((datausuario) => {
            temp.push(datausuario);
          });
        }
        // if (option.label == "Vicepresidente") {
        //   usuariosDecisiones.map((datausuario) => {
        //     temp.push(datausuario);
        //   });

        //   usuariosDecisiones.map((datausuario) => {
        //     if (datausuario.nombreposicion.includes("LIDER TI")) {
        //       temp.push(datausuario);
        //     }
        //   });
        // }
        // if (option.label == "Director") {
        //   usuariosDecisiones.map((datausuario) => {
        //     temp.push(datausuario);
        //   });
        //   usuariosDecisiones.map((datausuario) => {
        //     if (
        //       datausuario.nombreposicion.includes("LIDER TI") ||
        //       datausuario.nombreposicion.includes("DIRECTOR/A")
        //     ) {
        //       temp.push(datausuario);
        //     }
        //   });
        // }
        // if (option.label == "Gerente") {
        //   usuariosDecisiones.map((datausuario) => {
        //     temp.push(datausuario);
        //   });
        //   usuariosDecisiones.map((datausuario) => {
        //     if (
        //       datausuario.nombreposicion.includes("LIDER TI") ||
        //       datausuario.nombreposicion.includes("GERENTE")
        //     ) {
        //       temp.push(datausuario);
        //     }
        //   });
        // }
        setTomadorDecisones(temp);
        setValueTomadorDecision(option.value);
      };

      const handleSubmitDecisiones = (e) => {
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        let iddecision = 0;

        var data = JSON.stringify({
          idriesgo: props.idriesgo.idriesgo,
          // tomadordecision: document.getElementById("valueoption").value,
          tomadordecision: valueTomadorDecision,
          idresponsabledecision: parseInt(responsabledecision),
          // idresponsabledecision: document.getElementById(
          //   "valueoptionresponsable"
          // ).value,
          fecha_decision: today.toISOString(),
          observaciones: null,
          idusuario_decision: props.idriesgo.idusuariocreacion,
          fechacreacion: today.toISOString(),
          idusuariocreacion: props.idriesgo.idusuariocreacion,
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: props.idriesgo.idusuariomodificacion,
          disp_numerico1: 0.0,
          disp_numerico2: 0.0,
          disp_varchar1: null,
          disp_varchar2: null,
          decision: "Pendiente",
        });

        if (decisionRiesgo.includes(props.idriesgo.idriesgo)) {
          for (let i = 0; i < decision.length; i++) {
            if (decision[i].idriesgo == props.idriesgo.idriesgo) {
              iddecision = decision[i].iddecision;
            }
          }
        }
        SendDataDecision(data, iddecision);
      };

      const SendDataDecision = (data, iddecision) => {
        if (iddecision !== 0) {
          fetch(
            process.env.REACT_APP_API_URL +
              "EditarDecisiones/" +
              iddecision +
              "/",
            {
              method: "PUT",
              body: data,
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                //Authorization: "Bearer " + token,
              },
            }
          )
            .then((data) =>
              data.json().then((response) => {
                if (data.status >= 200 && data.status < 300) {
                  setEstadoPostDecision(2);
                } else if (data.status >= 500) {
                  setEstadoPostDecision(5);
                } else if (data.status >= 400 && data.status < 500) {
                  setEstadoPostDecision(4);
                }
              })
            )
            .catch(function (err) {});
        } else {
          fetch(process.env.REACT_APP_API_URL + "EditarDecisiones/0/", {
            method: "POST",
            body: data,
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              //Authorization: "Bearer " + token,
            },
          })
            .then((data) =>
              data.json().then((response) => {
                if (data.status >= 200 && data.status < 300) {
                  setEstadoPostDecision(2);
                  const fetchDecisionRefresh = async () => {
                    const result = await fetch(
                      process.env.REACT_APP_API_URL +
                        "decisiones/" +
                        response.iddecision +
                        "/",
                      {
                        method: "GET",
                        headers: {
                          Accept: "application/json",
                          // Authorization: "Bearer " + token,
                        },
                      }
                    );
                    let data = await result.json();
                    let datatemp = dataT;
                    let responsetemp = response;

                    for (let i = 0; i < datatemp.length; i++) {
                      if (datatemp[i].idriesgo == responsetemp.idriesgo) {
                        datatemp[i].decision = responsetemp.decision;
                        break;
                      }
                    }
                    setDataT(datatemp);
                  };
                  setOcultarBtn(true);
                  fetchDecisionRefresh();
                } else if (data.status >= 500) {
                  setEstadoPostDecision(5);
                } else if (data.status >= 400 && data.status < 500) {
                  setEstadoPostDecision(4);
                }
              })
            )
            .catch(function (err) {});
        }
      };

      function Item(props) {
        const { sx, ...other } = props;
        return (
          <Box
            sx={{
              textAlign: "center",
              m: 2,
              ...sx,
            }}
            {...other}
          />
        );
      }

      Item.propTypes = {
        sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      };

      return (
        <Modal
          size="lg"
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <AlertDismissibleExampleDecision alerta={estadoPOSTDecision} />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Solicitud de Decisiones
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row className="mb-3">
              <Col
                sm={6}
                xs={12}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <label className="label forn-label">
                  Tomador de la decisión
                </label>
              </Col>
              <Col sm={6} xs={10}>
                <Select
                  id="valueoption"
                  placeholder={"Seleccione un Tomador de decisión..."}
                  styles={stylesSelect}
                  options={opcionesTomadorDecision}
                  onChange={filtrarTomadorDecision}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col
                sm={6}
                xs={12}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <label className="label forn-label">Responsable decisión</label>
              </Col>
              <Col sm={6} xs={10}>
                <Select
                  id="valueoptionresponsable"
                  placeholder={"Seleccione un Responsable decisión..."}
                  styles={stylesSelect}
                  options={tomadorDecisones}
                  onChange={(option) => {
                    setResponsableDecision(option.value);
                  }}
                />
              </Col>
            </Row>
            <Box>
              <Item>
                <div style={{ paddingTop: "30px" }}>
                  <Button
                    id="btn-save-dec"
                    style={{ background: "black" }}
                    className="botonPositivo3"
                    variant="contained"
                    type="submit"
                    onClick={function (e) {
                      handleSubmitDecisiones(e);
                    }}
                    hidden={ocultarBtn}
                  >
                    Guardar
                  </Button>
                </div>
              </Item>
            </Box>
          </Modal.Body>
        </Modal>
      );
    }
    return (
      <React.Fragment>
        <MyVerticallyCenteredModalDecision
          show={modalDecisionShow}
          onHide={() => setModalDecisionShow(false)}
          idriesgo={idRiesgoModal}
        />
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          effect={efecto}
        />
        <TableRow className={classesRow.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpenPrimer(!openPrimer);
                if (openPrimer) {
                  setOpenTercer(false);
                } else {
                  if (efecto.length >= 1) {
                    setOpenTercer(true);
                  } else {
                    setOpenTercer(false);
                  }
                }
              }}
            >
              {openPrimer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            <label className="texto" id={"evento" + index}>
              {dataRiesgo.evento}
            </label>
          </TableCell>
          <TableCell align="left" className="innerBox" colSpan={3}>
            <textarea
              placeholder="Escribe la Descripción del Riesgo..."
              class="form-control"
              id={"descripcion" + index}
              rows="3"
              defaultValue={dataRiesgo.descripcionriesgo}
            ></textarea>
          </TableCell>
          <TableCell align="center">
            <label className="texto" id={"exposicioninherentep95" + index}>
              {numberWithCommas(parseInt(p95).toFixed(2))}
            </label>
          </TableCell>
          <TableCell align="center">
            <label className="texto" id={"efectividadcontrol" + index}>
              {parseFloat(dataRiesgo.efectividadcontrol).toFixed(2)}
            </label>
          </TableCell>
          <TableCell align="center">
            <label className="texto" id={"exposicionresidual" + index}>
              {numberWithCommas(parseInt(expoResidual).toFixed(2))}
            </label>
          </TableCell>
          <TableCell align="center">
            <label className="texto" id={"nivelriesgo" + index}>
              {nivelRiesgo}
            </label>
          </TableCell>
          <TableCell align="center">
            <label className="texto" id={"exposicioninherentep50" + index}>
              {numberWithCommas(parseInt(p50).toFixed(2))}
            </label>
          </TableCell>
          <TableCell align="center">
            <label className="texto" id={"exposicioninherentep99" + index}>
              {numberWithCommas(parseInt(p99).toFixed(2))}
            </label>
          </TableCell>
          <TableCell align="center" className="texto">
            <select
              className="form-control"
              title="Estado Riesgo"
              id={"estado_riesgo" + index}
              onChange={() => {
                manageChange(index, dataRiesgo.idevento_riesgo);
              }}
            >
              {opci}
            </select>
          </TableCell>
          <TableCell align="left" className="innerBox" colSpan={3}>
            <textarea
              placeholder="Escribe la Justificacion del Cambio del Estado..."
              class="form-control"
              id={"justificacion" + index}
              rows="3"
              defaultValue={dataRiesgo.justificacioncestado}
              required={justiRequire}
            ></textarea>
          </TableCell>
          <TableCell align="center">
            {dataRiesgo.decision == null ||
            dataRiesgo.decision == "Sin decisión" ? (
              <div>
                <label class="texto">Sin decisión</label>
                {validRole ? (
                  <Button
                    className="botonHipervinculo"
                    onClick={() => {
                      setModalDecisionShow(true);
                      setidRiesgoModal(dataRiesgo);
                    }}
                  >
                    Solicitar Decisión
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <label className="texto">{dataRiesgo.decision}</label>
            )}
          </TableCell>
        </TableRow>
        <TableRow style={{ width: "100%" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
            <Divider />
            {/*Tabla cuando se escoge info propia*/}
            <Collapse
              in={openPrimer}
              timeout="auto"
              style={{ marginLeft: "6%", marginRight: "6%", marginTop: "1%" }}
              unmountOnExit
            >
              <h3 className="label">Resumen evento de Amenaza</h3>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced tableExcel"
                >
                  <EnhancedTableHeadDatosExcel />
                  <TableBody>
                    {detalladoRiesgos.map((evento) => {
                      return (
                        <>
                          {dataRiesgo == undefined ? (
                            " "
                          ) : (
                            <>
                              {evento.map((actor, index) => {
                                return (
                                  <>
                                    {actor[0].idriesgo ==
                                    dataRiesgo.idriesgo ? (
                                      <>
                                        <TableRow
                                          key={actor[0].idriesgo}
                                          className={classesRow.root}
                                        >
                                          <TableCell component="th" scope="row">
                                            {actor[0].fuente}/{actor[0].actor}
                                          </TableCell>
                                          <TableCell colSpan={2}>
                                            {actor.map((detalle) => (
                                              <>
                                                <TableRow>
                                                  <TableCell>
                                                    {detalle.evento_amenaza}
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            ))}
                                          </TableCell>
                                          <TableCell>
                                            {actor.map((detalle) => (
                                              <>
                                                <TableRow>
                                                  <TableCell>
                                                    {parseFloat(
                                                      detalle.factorexposicion
                                                    ).toFixed(2)}
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            ))}
                                          </TableCell>
                                          <TableCell>
                                            {actor.map((detalle) => (
                                              <>
                                                <TableRow>
                                                  <TableCell>
                                                    {parseFloat(
                                                      detalle.vectorimpacto
                                                    ).toFixed(2)}
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            ))}
                                          </TableCell>
                                          <TableCell>
                                            {actor.map((detalle) => (
                                              <>
                                                <TableRow>
                                                  <TableCell>
                                                    {parseFloat(
                                                      detalle.frecuenciasoc
                                                    ).toFixed(2)}
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            ))}
                                          </TableCell>
                                          <TableCell>
                                            {actor.map((detalle) => (
                                              <>
                                                <TableRow>
                                                  <TableCell>
                                                    {parseFloat(
                                                      detalle.frecuenciamodelo
                                                    ).toFixed(2)}
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            ))}
                                          </TableCell>
                                          <TableCell>
                                            {actor.map((detalle) => (
                                              <>
                                                <TableRow>
                                                  <TableCell>
                                                    {parseFloat(
                                                      detalle.efectividadcontroles
                                                    ).toFixed(2)}
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            ))}
                                          </TableCell>
                                        </TableRow>
                                        <hr></hr>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </>
                          )}
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box justifyContent={"flex-center"}>
                <Item>
                  <hr />
                  <h3 className="subtitulo">Consolidado del Evento</h3>
                </Item>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Item>
                  <h5 className="label">
                    Frecuencia del Evento:{" "}
                    {parseFloat(dataRiesgo.frecuenciaevento).toFixed(2)}
                  </h5>
                </Item>
                <Item>
                  <h5 className="label">
                    Vector de Impacto:{" "}
                    {parseFloat(dataRiesgo.vectorimpacto).toFixed(2)}
                  </h5>
                </Item>
                <Item>
                  <h5 className="label">
                    Probabilidad del Rango 00:{" "}
                    {parseFloat(dataRiesgo.probabilidadrango00).toFixed(2) *
                      100}
                    %
                  </h5>
                </Item>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Item>
                  {validRole ? (
                    <Button
                      className="botonIngreso"
                      onClick={() => {
                        popitup(
                          "https://servicios-de-valoracion.apps.bancolombia.com/"
                        );
                      }}
                    >
                      Valorar
                    </Button>
                  ) : (
                    ""
                  )}
                </Item>
                <Item>
                  {validRole ? (
                    <Button
                      className="botonPositivo3"
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      Asociar efectos
                    </Button>
                  ) : (
                    ""
                  )}
                </Item>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <TableRow style={{ width: "100%" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
            <Collapse
              in={openTercer}
              timeout="auto"
              style={{ marginLeft: "6%", marginRight: "6%", marginTop: "1%" }}
              unmountOnExit
            >
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced tableExcel"
                >
                  <EnhancedTableHeadValoracion classes={classes} />
                  <TableBody>
                    {efecto.map((row, index) => (
                      <TableRow key={row.idefecto} className={classesRow.root}>
                        <TableCell component="th" scope="row">
                          {row.idefecto}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.nombreefecto}
                        </TableCell>
                        <TableCell align="center">{row.tipoefecto}</TableCell>
                        <TableCell align="center" id={"resultadoP50" + index}>
                          {row.resultado_p50}
                        </TableCell>
                        <TableCell align="center" id={"resultadoP95" + index}>
                          {row.resultado_p95}
                        </TableCell>
                        <TableCell align="center" id={"resultadoP99" + index}>
                          {row.resultado_p99}
                        </TableCell>
                        <TableCell align="center">
                          {row.metodovaloracion}
                        </TableCell>
                        <TableCell align="center">{row.analista}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <hr></hr>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function ContenedorRiesgos(props) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [dense, setDense] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { data, iniciales } = props;
    const classes = useStyles();
    ////const AzureADService = new AADService();
    ////const token = AzureADService.getToken();

    return (
      <>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).map(
                  (row, index) => {
                    var iniEnviar = [];
                    for (let i = 0; i < iniciales.length; i++) {
                      if (row.idriesgo == iniciales[i].idriesgo) {
                        iniEnviar = iniciales[i].efectos;
                      }
                    }
                    return (
                      <ContenidoRiesgos
                        key={row.idevento_riesgo}
                        dataRiesgo={row}
                        index={index}
                        inicialesContenido={iniEnviar}
                      />
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
      <Form id="formData" onSubmit={sendData} noValidate validated={validated}>
        <Row className="mb-3">
          <Col md={7}>
            <h2 className="subtitulo">Eventos de riesgos</h2>
          </Col>
          {/* <Col sm={1}>
            {loading && <CircularProgressWithLabel value={progress} />}
          </Col> */}
          <Col>
            {validRole ? (
              <Button type="submit" id="send" className="botonPositivo">
                Guardar
              </Button>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <ContenedorRiesgos data={dataT} iniciales={efectosIniciales} />
      </Form>
    </>
  );
}
export default Riesgos;
